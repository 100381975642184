import { DoctIcon, DoctTypography } from 'doct-core';
import React from 'react';
import dayjs from 'dayjs';

function EventDynamicCard({ eventData }) {
  const { attendeesCount, draftEvents, draftModifiedDate, activeEventSummaryDto } = eventData || {};

  const { type, endDate, name, startDate, status, viewCounts } = activeEventSummaryDto[0] || [];
  return (
    <>
      {activeEventSummaryDto?.length > 0 && (
        <div className="background-event-pink pb-3 border-radius px-2">
          <div className="d-flex justify-content-between align-items-center border-radius">
            <DoctTypography variant="subtitle3" className="text-grey-600">
              {type?.toUpperCase()}
            </DoctTypography>
            <DoctTypography variant="textLabel2" className="text-white m-0 p-1 bg-success mr-2">
              {status?.toUpperCase()}
            </DoctTypography>
          </div>
          <DoctTypography
            variant="textLabel2"
            fontWeight="medium"
            className="text-grey-800 m-0 p-0 mb-2 text-truncate"
          >
            {name}
          </DoctTypography>
          <div className="d-flex align-items-center mt-sm-n1">
            <DoctIcon name="date" width="16" height="16" fill="#717171" className="mr-1" />
            <DoctTypography
              variant="caption2"
              fontWeight="medium"
              className="text-grey-600 mt-1 text-normal"
            >
              {dayjs(startDate).format('DD MMM YYYY')} - {dayjs(endDate).format('DD MMM YYYY')}
            </DoctTypography>
          </div>
          <div className="d-flex align-items-center justify-content-center px-5 mt-3">
            <div className="d-flex bg-pink-shade border-radius-6px">
              <DoctTypography variant="textLabel2" className="text-grey-800 m-0 p-1 px-3">
                Attendees
              </DoctTypography>
              <span className="text-grey-800 m-0 p-1 text-label-2 mr-2">{attendeesCount}</span>
            </div>
            <div className="d-flex bg-pink-shade border-radius-6px ml-1">
              <DoctTypography variant="textLabel2" className="text-grey-800 m-0 p-1 px-3">
                Views
              </DoctTypography>
              <span className="text-grey-800 m-0 p-1 text-label-2 mr-2">{viewCounts}</span>
            </div>
          </div>
        </div>
      )}
      <div className="bg-light-blue p-3 border-radius mt-1">
        <DoctTypography variant="subtitle3" fontWeight="medium" className="text-grey-600 mt-1">
          NEW
        </DoctTypography>
        <DoctTypography variant="h6" fontWeight="medium" className="text-grey-800 mt-1 text-center">
          {draftEvents} Draft
        </DoctTypography>
        {draftModifiedDate ? (
          <DoctTypography
            variant="subtitle3"
            fontWeight="medium"
            className="text-grey-500 text-center"
          >
            Last updated on: {dayjs(draftModifiedDate).format('DD MMM YYYY')}
          </DoctTypography>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

export default EventDynamicCard;
