import { DoctIcon, DoctTypography } from 'doct-core';
import React from 'react';

export default function AssignDashboardCard({ iconName, name, className, disabled, onClick }) {
  return (
    <div
      className={`assign-dashboard-card d-flex align-items-center mt-1 ${className} ${
        disabled && 'disable-sidebar-link-item'
      }`}
    >
      <DoctIcon width="28" height="28" name={iconName} />
      <DoctTypography variant="textLabel1" className="text-grey-800 m-0 ml-2">
        {name}
      </DoctTypography>
      <div onClick={onClick} className="ml-auto cursor-pointer">
        <DoctIcon width="24" height="24" name="right" fill="#ABABAB" />
      </div>
    </div>
  );
}
