import { DoctIcon, DoctTypography } from 'doct-core';
import React, { useRef } from 'react';
import { useOnClickOutside } from '../../../../shared/ui/Dropdown/Dropdown';

const ShareComponent = ({ copyLinkHandler, setShowShareMenu, url }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => {
    if (setShowShareMenu) {
      setShowShareMenu(false);
    }
  });

  return (
    <div className="box-shadow share_options_menu position-absolute border-radius" ref={ref}>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${url}`}
        target={'_blank'}
        rel="noreferrer"
        className="d-flex align-items-center p-3 cursor-pointer"
      >
        <DoctIcon name="facebook" width="25" className="m-0 p-0" />
        <DoctTypography variant="body2" fontWeight="bold" className="mx-3 p-0 my-0">
          Facebook
        </DoctTypography>
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${url}`}
        target={'_blank'}
        rel="noreferrer"
        className="d-flex align-items-center p-3 cursor-pointer"
      >
        <DoctIcon name="twitter" width="25" className="m-0 p-0" />
        <DoctTypography variant="body2" fontWeight="bold" className="mx-3 p-0 my-0">
          Twitter
        </DoctTypography>
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`}
        target={'_blank'}
        rel="noreferrer"
        className="d-flex align-items-center p-3 cursor-pointer"
      >
        <DoctIcon name="linkedColour" width="25" className="m-0 p-0" />
        <DoctTypography variant="body2" fontWeight="bold" className="mx-3 p-0 my-0">
          Linkedin
        </DoctTypography>
      </a>
      <div className="share_menu_sepration_line w-100" />
      <div onClick={copyLinkHandler} className="d-flex align-items-center p-3 cursor-pointer">
        <DoctIcon name="link" width="25" className="m-0 p-0" />
        <DoctTypography variant="body2" fontWeight="bold" className="mx-3 p-0 my-0">
          Copy Link
        </DoctTypography>
      </div>
    </div>
  );
};

export default ShareComponent;
