import React, { useEffect } from 'react';
import { DoctAnimationLoading } from '@docthub.frontend/app';
import { DoctCol } from 'doct-core';
import { useDispatch } from 'react-redux';
import { AddEditFormHeaderFooterMainArea } from '../../../../../shared';
import { setOptionsOrganizingOptions } from '../../slice/activityLog.slice';

export function FormGrid({ children }) {
  return (
    <DoctCol md={6} className={'mx-auto'}>
      {children}
    </DoctCol>
  );
}

export function AddEditActivityLogLoader({ title }) {
  return (
    <AddEditFormHeaderFooterMainArea
      title={title}
      footerProps={{
        btnText: 'Save Activity',
        loading: true,
      }}
    >
      <div className="mt-4 border-radius overflow-hidden page-loading-width-auto">
        <DoctAnimationLoading />
      </div>
    </AddEditFormHeaderFooterMainArea>
  );
}

export function useOrganizingTeamOptionEffect({ data, isLoading }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isLoading) return;
    dispatch(
      setOptionsOrganizingOptions(
        data.map(({ fullName, id }) => {
          return { title: fullName, id };
        }),
      ),
    );
  }, [isLoading]);
}
