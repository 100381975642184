import React from 'react';
import { DoctActionMenu } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import DefaultUserPicture from '../../../../../../../assets/icons/DefaultUserPicture.svg';
const options = [
  {
    title: 'Edit',
  },
  {
    title: 'Delete',
  },
];

export default function OrganiserCard({
  name,
  designation,
  image,
  setIsModalOpen,
  additionalFun,
  deleteRecordHandler,
  index,
}) {
  return (
    <div className="organiser_card_body bg-white box-shadow border-radius px-3 py-2">
      <div className="d-flex justify-content-around py-2">
        <img
          src={image ? image : DefaultUserPicture}
          className="img-organiser mr-auto"
          alt="organizer-image"
        />
        <DoctActionMenu
          btnType="inverse"
          className="more_action_menu_organizer mt-sm-n2 mr-sm-n3"
          options={options}
          onClick={(item) => {
            if (item.title == 'Edit') {
              setIsModalOpen(true);
              additionalFun();
            }
            if (item.title == 'Delete') {
              deleteRecordHandler(index);
            }
          }}
        />
      </div>
      <DoctTypography
        variant="textLabel1"
        className="text-grey-800 mt-1 organiser_card_name text-truncate"
      >
        {name}
      </DoctTypography>
      <DoctTypography
        variant="textLabel2"
        className="text-grey-400 mt-sm-n3 organiser_card_designation text-truncate-two-lines"
      >
        {designation}
      </DoctTypography>
    </div>
  );
}
