import { DoctModal } from '@docthub.frontend/app';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { descriptionContent } from '../helper/helperFunction';

const ProtectedRoute = ({ roles }) => {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state?.app?.user?.tenant?.type);
  const [openModal, setOpenModal] = useState(false);
  const currentUrl = location.pathname.split('/');

  useEffect(() => {
    if (!roles.includes(userRole)) {
      setOpenModal(true);
    }
  }, [roles, userRole]);

  const handleCloseModal = () => {
    setOpenModal(false);
    navigate(-1);
  };

  if (!roles.includes(userRole)) {
    return (
      <>
        <DoctModal
          width={345}
          title="Attention"
          className="disabled_modal_outside_click"
          showFooter={false}
          open={openModal}
          handleClose={handleCloseModal}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: descriptionContent(currentUrl, userRole),
            }}
          />
        </DoctModal>
      </>
    );
  }

  return <Outlet />;
};

export default ProtectedRoute;
