import React from 'react';
import { DoctButton, DoctTypography } from 'doct-core';
import { useDispatch } from 'react-redux';

import { setModalTitle, toggleDesignationModal } from '../../slice/committee.slice';

import AddDesignationImg from '../../../../../assets/images/committee/add-designation.svg';

const AddDesignation = () => {
  const dispatch = useDispatch();

  return (
    <div className="text-center">
      <img src={AddDesignationImg} alt="Add Designation" />
      <DoctTypography variant="subtitle1" fontWeight="medium" className="text-grey-600">
        Add Designation
      </DoctTypography>
      <DoctTypography variant="body2" fontWeight="regular" className="text-grey-600">
        Add Presidents, Vice presidents, Secretary, Members, Managers
      </DoctTypography>
      <div className="d-flex justify-content-center pt-3">
        <DoctButton
          text="Add"
          icon="plus"
          iconPosition="left"
          onButtonClickHandler={() => {
            dispatch(setModalTitle('Add Designation'));
            dispatch(toggleDesignationModal());
          }}
        />
      </div>
    </div>
  );
};

export default AddDesignation;
