import { DoctForm, DoctModal, DoctTextField } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React from 'react';
import './../CourseStatusStyle.scss';

function DeleteInstituteModal({ open, handleClose, onDeleteButtonClicked = () => {} }) {
  const { control, errors, handleSubmit, touched } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const handleFormSubmit = handleSubmit((values) => {
    onDeleteButtonClicked(values.deleteReason);
    handleClose();
  });

  return (
    <>
      <DoctModal
        open={open}
        handleClose={handleClose}
        primaryBtnText="Delete"
        secondaryBtnText="Cancel"
        iconName=""
        className="delete_institute_modal"
        width={328}
        handlePrimaryButtonClick={handleFormSubmit}
      >
        <DoctTypography variant="h6">Are you sure you want to delete?</DoctTypography>

        <DoctTypography variant="subtitle3" className="text-grey-600">
          It won&#39;t be possible to recover the data once it has been deleted.
        </DoctTypography>
        <form onSubmit={handleFormSubmit}>
          <DoctTypography variant="h6" className="text-grey-800">
            Reason
          </DoctTypography>
          <DoctTextField
            showStar={false}
            className="mt-2 mb-3"
            name="deleteReason"
            label="Delete Reason"
            id="deleteReason"
            control={control}
            isErrors={errors}
            defaultValue=""
            validationRules={{
              required: "It's Required Field",
            }}
            touched={touched}
          />
        </form>
      </DoctModal>
    </>
  );
}

export default DeleteInstituteModal;
