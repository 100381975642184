import React from 'react';
import { DoctTypography } from 'doct-core';

import style from './ProfileDataBox.module.scss';

export function ProfileDataBoxContentTitleDescription({ title, description }) {
  return (
    <span className="py-1 px-1 mt-0 mb-0 d-flex">
      <DoctTypography
        variant="textLabel1"
        className={`${style['profile-data-box-content-title']} text-grey-600 my-0`}
      >
        {title}
      </DoctTypography>
      <DoctTypography variant="textLabel1" className={`my-0`}>
        {description}
      </DoctTypography>
    </span>
  );
}

export default function ProfileDataBox({ heading, children, actionMenu }) {
  return (
    <div className={`${style['profile-data-box']} box-shadow border-radius overflow-hidden`}>
      <div className="d-flex align-items-center bg-grey-100 px-3 panel-height-4x border-radius-tl border-radius-tr">
        <DoctTypography variant="body2" className="my-0 text-grey-600" fontWeight="medium">
          {heading}
        </DoctTypography>
        <span className="ml-auto d-inline-flex">{actionMenu}</span>
      </div>
      <div className="bg-white p-3">{children}</div>
    </div>
  );
}
