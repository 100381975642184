import { DoctAnimationLoading, DoctTextField } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React from 'react';
import WorkExperience from './components/WorkExperience';
import BasicInfoSalaryComponent from './components/BasicInfoSalaryComponent';
import JobOverviewAdditionalInfo from './components/JobOverviewAdditionalInfo';
import { useEffect } from 'react';
import { LocationField } from '../../../../../shared';
import { useDispatch } from 'react-redux';
import { setShowToasterWithParams } from '../../../../featuresSlice';
import { TOST_TOP_RIGHT } from '../../../../../constants/toasterPosition.constants';

const JobBasicInfo = ({
  control,
  errors,
  watch,
  setValue,
  register,
  setQualificationArray,
  qualificationArray,
  jobType,
  setJobType,
  isLoading,
  touched,
  locationValue,
  setWorkExperienceValidation,
  workExperienceValidation,
  setEducationValidation,
  educationValidation,
}) => {
  const dispatch = useDispatch();
  function ToasterTextMessage() {
    if (workExperienceValidation) {
      return 'must select work experience';
    }
    if (educationValidation) {
      return 'must select education';
    }
  }

  if (isLoading) {
    return <DoctAnimationLoading />;
  }
  useEffect(() => {
    if (workExperienceValidation || educationValidation) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_TOP_RIGHT,
          message: ToasterTextMessage(),
        }),
      );
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [workExperienceValidation, educationValidation]);

  const onTostCloseHandler = () => {
    setWorkExperienceValidation(false);
    setEducationValidation(false);
  };

  useEffect(() => {
    if (workExperienceValidation || educationValidation) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_TOP_RIGHT,
          message: ToasterTextMessage(),
        }),
      );
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [workExperienceValidation, educationValidation]);

  return (
    <>
      <div className="input-column">
        <DoctTypography variant="subtitle2">Job Title</DoctTypography>
        <DoctTextField
          name="title"
          id="title"
          label="Enter Job Title here"
          control={control}
          isErrors={errors}
          validationRules={{
            required: 'Job Title is required field',
            minLength: {
              value: 3,
              message: 'This title should be of more than 3 Characters to appear on Docthub.com',
            },
            maxLength: {
              value: 80,
              message: 'This title might be too long for the job to appear on Docthub.com',
            },
          }}
          showStar={false}
        />
      </div>

      <div className="input-column">
        <DoctTypography variant="subtitle2">Job Location</DoctTypography>
        <LocationField
          savedValue={locationValue}
          control={control}
          errors={errors}
          touched={touched}
          watch={watch}
          setValue={setValue}
          inputProps={{
            country: {
              disabled: true,
            },
            showStar: {
              showStar: false,
            },
          }}
          showinGrid
          columnLayout={{ country: 4, city: 4, state: 4 }}
          className={'form_el form_el_gap_bottom'}
        />
      </div>

      <div className="input-column">
        <WorkExperience
          control={control}
          errors={errors}
          watch={watch}
          setJobType={setJobType}
          jobType={jobType}
          setValue={setValue}
        />
      </div>

      <div className="input-column">
        <BasicInfoSalaryComponent
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
        />
      </div>

      <JobOverviewAdditionalInfo
        qualificationArray={qualificationArray}
        setQualificationArray={setQualificationArray}
        control={control}
        errors={errors}
        watch={watch}
        setValue={setValue}
        register={register}
      />
    </>
  );
};

export default JobBasicInfo;
