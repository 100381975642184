import React, { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { DoctChip, DoctModal, DoctTabContent, DoctTabWrapper } from '@docthub.frontend/app';
import { DoctButton, DoctIcon, DoctIconButton } from 'doct-core';
import PropyTypes from 'prop-types';
import useQueryHooks from '../../../hooks/useQueryHooks';
import NoDataFound from '../NoDataFound';
import { CommonFilterEl, FilterDropdown, Search, TableHead } from './components';
import { transformStatus } from './constants.customTable';
import CustomTableWithTabWrapper from './components/CustomTableWithTabWrapper';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import qs from 'qs';
import './CustomTable.scss';

export const getChipsTitle = (title) => {
  if (transformStatus[title]) {
    return transformStatus[title];
  }

  return title;
};

export function Chips({
  handleChipsRemove,
  title,
  keyName,
  removableIdTitle,
  showCloseIcon = true,
}) {
  return (
    <span className="mr-2 my-1">
      <DoctChip
        title={getChipsTitle(title)}
        onCloseHandler={() => {
          handleChipsRemove(removableIdTitle, keyName);
        }}
        showCloseIcon={showCloseIcon}
      />
    </span>
  );
}

export function ChipsListComponent({ filterChipsObj, handleChipsRemove, transformDisplayChips }) {
  function findTitle(id, key) {
    return transformDisplayChips?.[key]?.find((el) => {
      return el.id == id;
    })?.[transformDisplayChips['valueAccessBy']];
  }
  return Object.entries(filterChipsObj).map((key) => {
    const changeDisplayName = transformDisplayChips?.[key];
    if (Array.isArray(filterChipsObj[key])) {
      return filterChipsObj[key].map((nestedItem) => {
        return (
          <Chips
            handleChipsRemove={handleChipsRemove}
            title={changeDisplayName ? findTitle(nestedItem, key) : nestedItem}
            keyName={key}
            key={nestedItem}
            removableIdTitle={nestedItem}
          />
        );
      });
    }
    if (changeDisplayName) {
      return (
        <Chips
          handleChipsRemove={handleChipsRemove}
          title={changeDisplayName ? findTitle(filterChipsObj[key], key) : filterChipsObj[key]}
          // title={filterChipsObj[key]}
          keyName={key}
          key={filterChipsObj[key]}
          removableIdTitle={filterChipsObj[key]}
        />
      );
    }
  });
}
function CustomTable({
  column,
  tableRowData,
  tableCollapsible,
  tableCollapsibleData,
  activeRowIndex,
  setActiveRowIndex,
  handleRowClickCallback,
  contentLoading,
  searchPlaceholder = 'search',
  resultCount = 0,
  searchOptions = [],
  dropdownMenuItems = [],
  dropdownMenuDefaultSelected,
  actionMenu,
  actionButton,
  setSelectedItemId,
  setTriggredDownload,
  countHeading = 'RESULTS',
  filterData,
  onNoDataFound = <NoDataFound />,
  transformDisplayChips,
  downloadDetailComponent,
  additionalElement,
  showFilterIcon = true,
  onExportButtonClickHandler = () => {},
  excelClicked,
  excelBlur,
  tabOptionsArray = [],
  handleTabChange = () => {},
  currentTabValue = 0,
  setIsOpen,
  dropdownMenuItemInStatusWithButton = [],
  dropdownStatusButtonClassesHandler = () => {},
  handleButtonStatusChange = () => {},
  setStatus,
}) {
  let navigate = useNavigate();
  const query = useQueryHooks();

  const location = useLocation();
  const { pathname } = location;

  const [showFilter, setShowFilter] = useState(false);
  const [filterChipsObj, setFilterChipsObj] = useState({});
  const [filterCount, setFilterCount] = useState(0);
  useEffect(() => {
    let chipsQuery = { ...query };

    if (query.fromDate && query.toDate) {
      chipsQuery['dateRange'] = `${dayjs(query.fromDate).format('D MMMM YYYY')} to ${dayjs(
        query.toDate,
      ).format('D MMMM YYYY')}`;
    }

    delete chipsQuery.pageNumber;
    delete chipsQuery.fromDate;
    delete chipsQuery.toDate;
    delete chipsQuery.id;
    delete chipsQuery.searchText;
    delete chipsQuery.code;
    delete chipsQuery.memberships; //NOTE: to fix issue of bug no. #4879 uncommented this line
    delete chipsQuery.memberId;

    setFilterChipsObj(chipsQuery);

    let number = 0;

    Object.keys(chipsQuery).forEach((el) => {
      if (el == 'searchText') return;
      if (Array.isArray(chipsQuery[el])) {
        number += chipsQuery[el].length;
      } else {
        number += 1;
      }
    });
    setFilterCount(number);
  }, [qs.stringify(query)]);

  const handleChipsRemove = (nestedItem, key) => {
    let queryForHandleChange = { ...query };

    if (Array.isArray(query[key])) {
      queryForHandleChange = {
        ...queryForHandleChange,
        // [key]: query[key].filter((el) => el != nestedItem.toLowerCase()),
        [key]: query[key].filter((el) => el != nestedItem),
      };

      navigate(`?${qs.stringify(queryForHandleChange, { indices: false })}`);
      return;
    }

    if (key == 'dateRange') {
      delete queryForHandleChange['fromDate'];
      delete queryForHandleChange['toDate'];
      navigate(`?${qs.stringify(queryForHandleChange, { indices: false })}`);
      return;
    }
    delete queryForHandleChange[key];

    navigate(`?${qs.stringify(queryForHandleChange, { indices: false })}`);
  };

  const preventToShowButton = (array) => {
    return !array.includes(pathname.split('/')[pathname.split('/').length - 1]);
  };

  return (
    <>
      <div
        className={`d-flex align-items-center ${
          currentTabValue
            ? `justify-content-between`
            : downloadDetailComponent
            ? `justify-content-between`
            : dropdownMenuDefaultSelected
            ? `justify-content-between`
            : `justify-content-end`
        }`}
      >
        {downloadDetailComponent && <div>{downloadDetailComponent}</div>}
        {dropdownMenuDefaultSelected && (
          <FilterDropdown
            dropdownMenuItems={dropdownMenuItems}
            dropdownMenuDefaultSelected={dropdownMenuDefaultSelected}
            setActiveRowIndex={setActiveRowIndex}
          />
        )}
        <div className="d-flex align-items-center mr-sm-n3">
          {preventToShowButton(['manage-attendees', 'manage-directory']) && (
            <>
              <DoctButton
                text="Excel"
                icon="downloadOne"
                type="inverse"
                size="medium"
                variant="text"
                className="ml-sm-n4 mr-2 ml-lg-auto p-0"
                onButtonClickHandler={onExportButtonClickHandler}
                disabled={
                  (tableRowData?.length > 0 && [...(tableRowData || [])].length === 0) ||
                  excelClicked ||
                  excelBlur
                }
              />
              {excelClicked && (
                <CircularProgress size={20} color="inherit" className="mr-2 text-grey-400" />
              )}
            </>
          )}
          <span className="d-sm-none ml-auto">
            <DoctIcon width="20" height="20" name="search" className="text-grey-500" />
          </span>
          <div className="applicants-search-bar">
            <Search searchPlaceholder={searchPlaceholder} searchOptions={searchOptions} />
          </div>
          <span className="ml-3 position-relative" onClick={() => setShowFilter(true)}>
            {filterCount > 0 && (
              <span className="custom-table-result-filter-counter position-absolute d-flex align-items-center justify-content-center">
                {filterCount}
              </span>
            )}

            {showFilterIcon && (
              <DoctIconButton variant="text" type="secondary" icon="filter" size="medium" />
            )}
          </span>
        </div>
      </div>

      {tabOptionsArray?.length > 0 ? (
        <>
          <DoctTabWrapper
            value={currentTabValue}
            handleChange={handleTabChange}
            tabOptions={tabOptionsArray}
          />
          <DoctTabContent value={currentTabValue} index={currentTabValue}>
            <CustomTableWithTabWrapper
              resultCount={resultCount}
              countHeading={countHeading}
              filterChipsObj={filterChipsObj}
              handleChipsRemove={handleChipsRemove}
              transformDisplayChips={transformDisplayChips}
              filterCount={filterCount}
              query={query}
              pathname={pathname}
              column={column}
              contentLoading={contentLoading}
              tableRowData={tableRowData}
              onNoDataFound={onNoDataFound}
              tableCollapsible={tableCollapsible}
              tableCollapsibleData={tableCollapsibleData}
              setSelectedItemId={setSelectedItemId}
              actionMenu={actionMenu}
              actionButton={actionButton}
              setTriggredDownload={setTriggredDownload}
              navigate={navigate}
              setIsOpen={setIsOpen}
              activeRowIndex={activeRowIndex}
              setActiveRowIndex={setActiveRowIndex}
              handleRowClickCallback={handleRowClickCallback}
              dropdownMenuItemInStatusWithButton={dropdownMenuItemInStatusWithButton}
              dropdownStatusButtonClassesHandler={dropdownStatusButtonClassesHandler}
              handleButtonStatusChange={handleButtonStatusChange}
              setStatus={setStatus}
            />
          </DoctTabContent>
        </>
      ) : (
        <CustomTableWithTabWrapper
          resultCount={resultCount}
          countHeading={countHeading}
          filterChipsObj={filterChipsObj}
          handleChipsRemove={handleChipsRemove}
          transformDisplayChips={transformDisplayChips}
          filterCount={filterCount}
          query={query}
          pathname={pathname}
          column={column}
          contentLoading={contentLoading}
          tableRowData={tableRowData}
          onNoDataFound={onNoDataFound}
          tableCollapsible={tableCollapsible}
          tableCollapsibleData={tableCollapsibleData}
          setSelectedItemId={setSelectedItemId}
          actionMenu={actionMenu}
          actionButton={actionButton}
          setTriggredDownload={setTriggredDownload}
          navigate={navigate}
          activeRowIndex={activeRowIndex}
          setActiveRowIndex={setActiveRowIndex}
          handleRowClickCallback={handleRowClickCallback}
          setStatus={setStatus}
        />
      )}

      <DoctModal
        iconName={''}
        primaryBtnText="Show Results"
        open={showFilter}
        handlePrimaryButtonClick={() => {
          setShowFilter(false);
        }}
        handleClose={() => setShowFilter(false)}
        title={'Filter'}
        handleIconButtonClick={() => {}}
        width={360}
        className="disabled_modal_outside_click modal_hide_secondary_button"
      >
        {filterData ? filterData : <CommonFilterEl defaultValue={query} />}
      </DoctModal>
      {additionalElement}
    </>
  );
}

CustomTable.propTypes = {
  column: PropyTypes.object,
  tableRowData: PropyTypes.object,
  tableCollapsible: PropyTypes.any,
  searchPlaceholder: PropyTypes.any,
  resultCount: PropyTypes.any,
  searchOptions: PropyTypes.any,
  onSearchChangeHandler: PropyTypes.any,
  onSearchInputChangeHandler: PropyTypes.any,
  setSelectedItemId: PropyTypes.any,
};

TableHead.propTypes = {
  column: PropyTypes.object,
};

export default memo(CustomTable);
