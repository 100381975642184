import { DoctForm } from '@docthub.frontend/app';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEventById,
  saveApiData,
  savePaymentAndInvoice,
  saveSaveAsDraft,
  selectCreateEventResponse,
  selectCurrentStep,
  selectPaymentAndInvoice,
  selectResponseData,
  selectSaveAsDraftClicked,
  selectTicketsDetails,
  selectUpdateEventLoader,
  setCurrentStep,
  setDefaultState,
  setStepsFormData,
} from '../../../createEvent.slice';
import stepsName from '../stepsName';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { updateCreateEvent } from '../../../services/CreateEventServices';

const defaultValue = {
  ['payoutCountry']: {
    label: 'India',
    value: 'India',
  },
  ['currency']: {
    label: 'INR',
    value: 'INR',
  },
};

const formNameWithDefaultProps = (control, errors) => {
  return {
    payoutCountry: {
      name: 'payoutCountry',
      label: 'Payout Country',
      id: 'payoutCountry',
      control,
      errors,
      isErrors: errors,
    },
    currency: {
      name: 'currency',
      label: 'Currency',
      id: 'currency',
      control,
      errors,
      isErrors: errors,
    },
    bankName: {
      name: 'bankName',
      label: 'Bank name',
      id: 'bankName',
      control,
      errors,
      isErrors: errors,
    },
    accountHolderName: {
      name: 'accountHolderName',
      label: 'Account holder name',
      id: 'accountHolderName',
      control,
      errors,
      isErrors: errors,
    },
    accountNumber: {
      name: 'accountNumber',
      label: 'Account number',
      id: 'accountNumber',
      control,
      errors,
      isErrors: errors,
    },
    accountType: {
      name: 'accountType',
      label: 'Select account type',
      id: 'accountType',
      control,
      errors,
      isErrors: errors,
    },
    ifscCode: {
      name: 'ifscCode',
      label: 'IFSC code',
      id: 'ifscCode',
      control,
      errors,
      isErrors: errors,
    },
    branchName: {
      name: 'branchName',
      label: 'Branch name',
      id: 'branchName',
      control,
      errors,
      isErrors: errors,
    },
    gstIn: {
      name: 'gstin',
      label: 'GSTIN',
      id: 'gstIn',
      control,
      errors,
      isErrors: errors,
    },
    registeredCompanyName: {
      name: 'registrationName',
      label: 'Registered name, Company/ Organizer',
      id: 'registrationName',
      control,
      errors,
      isErrors: errors,
    },
    registeredCompanyAddress: {
      name: 'RegistrationCompanyAddress',
      label: 'Registered company address',
      id: 'registeredCompanyAddress',
      control,
      errors,
      isErrors: errors,
    },
    contactNumber: {
      name: 'contactNumber',
      label: 'Contact number',
      id: 'contactNumber',
      control,
      errors,
      isErrors: errors,
    },
    emailAddress: {
      name: 'emailAddress',
      label: 'Email address',
      id: 'emailAddress',
      control,
      errors,
      isErrors: errors,
    },
    panCardNumber: {
      name: 'panNumber',
      label: 'PAN number',
      id: 'panCardNumber',
      control,
      errors,
      isErrors: errors,
    },
  };
};

export default function useFormPaymentsInvoice() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const paymentsAndInvoice = useSelector(selectPaymentAndInvoice);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const createdEventResponse = useSelector(selectResponseData);
  const loading = useSelector(selectUpdateEventLoader);
  const draftBtnClicked = useSelector(selectSaveAsDraftClicked);
  const navigate = useNavigate();
  const gstRegistrationFromTicket = useSelector(selectTicketsDetails);

  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const [uploadRegistration, setUploadRegistration] = useState([]);
  const [uploadSignature, setUploadSignature] = useState([]);
  const [defaultStateValue, setDefaultStateValue] = useState();
  const [successfullHandleSubmit, setSuccessfullHandleSubmit] = useState(false);
  const [resetGSTRegistrationUploadUrl, setResetGSTRegistrationUploadUrl] = useState();
  const [deleteGSTRegistrationUploadUrl, setDeleteGSTRegistrationUploadUrl] = useState(false);
  const [resetSignatureUrl, setResetSignatureUrl] = useState();
  const [deleteSignatureUrl, setDeleteSignatureUrl] = useState(false);

  const [isSignatureFileError, setIsSignatureFileError] = useState(false);
  const [isGSTFileError, setIsGSTFileError] = useState(false);

  useEffect(() => {
    if (draftBtnClicked == true) {
      if (successfullHandleSubmit) {
        if (!loading) {
          dispatch(saveSaveAsDraft(false));
          dispatch(setDefaultState());
          navigate(-1);
        }
      } else {
        handleFormSubmit();
      }
    }
  }, [draftBtnClicked, successfullHandleSubmit, loading]);

  useMemo(() => {
    if (Object.keys(errors)?.length) {
      dispatch(saveSaveAsDraft(false));
    }
  }, [Object.keys(errors)?.length]);

  useEffect(() => {
    if (uploadRegistration === null) {
      setIsGSTFileError(true);
    } else {
      setIsGSTFileError(false);
    }

    if (uploadSignature?.length > 0 || resetSignatureUrl) {
      setIsSignatureFileError(false);
    }
  }, [uploadSignature, uploadRegistration]);

  useEffect(() => {
    if (Object.keys(paymentsAndInvoice).length) {
      const values = { ...paymentsAndInvoice };

      if (values.paymentSettlement?.PaymentCountry) {
        values['payoutCountry'] = {
          label: values.paymentSettlement?.PaymentCountry,
          value: values.paymentSettlement?.PaymentCountry,
        };
      }
      if (values.paymentSettlement?.CurrencyMode) {
        values['currency'] = {
          label: values.paymentSettlement?.CurrencyMode,
          value: values.paymentSettlement?.CurrencyMode,
        };
      }
      if (values.paymentSettlement?.CurrencyMode) {
        values['accountType'] = {
          label: values.paymentSettlement?.AccountType,
          value: values.paymentSettlement?.AccountType,
        };
      }
      if (values.invoiceDetail?.state) {
        values['state'] = {
          label: values.invoiceDetail?.state,
          value: values.invoiceDetail?.state,
        };
        setDefaultStateValue(values.invoiceDetail?.state);
      }
      if (values.invoiceDetail?.city) {
        values['city'] = {
          label: values.invoiceDetail?.city,
          value: values.invoiceDetail?.city,
        };
      }
      if (values.invoiceDetail) {
        values['gstin'] = values.invoiceDetail?.gstin;
        values['panNumber'] = values.invoiceDetail?.panNumber;
        values['RegistrationCompanyAddress'] = values.invoiceDetail?.registrationCompanyAddress;
        values['emailAddress'] = values.invoiceDetail?.emailAddress;
        values['registrationName'] = values.invoiceDetail?.registrationName;
        values['contactNumber'] = values.invoiceDetail?.contactNumber;
      }
      if (values.paymentSettlement) {
        values['bankName'] = values.paymentSettlement?.bankName;
        values['accountNumber'] = values.paymentSettlement?.accountNumber;
        values['accountHolderName'] = values.paymentSettlement?.accountHolderName;
        values['branchName'] = values.paymentSettlement?.branchName;
        values['ifscCode'] = values.paymentSettlement?.ifscCode;
      }
      if (values.invoiceDetail?.GSTRegistrationUploadFile) {
        setUploadRegistration([values.invoiceDetail?.GSTRegistrationUploadFile]);
      } else if (
        apiResponseData?.invoiceDetail?.gstRegistrationUploadUrl ||
        apiResponseData?.invoiceDetail?.gstRegistrationUploadUrl === null
      ) {
        setResetGSTRegistrationUploadUrl(apiResponseData?.invoiceDetail?.gstRegistrationUploadUrl);
      }
      if (values.invoiceDetail?.SignatureFile) {
        setUploadSignature([values.invoiceDetail?.SignatureFile]);
      } else if (
        apiResponseData?.invoiceDetail?.signatureUrl ||
        apiResponseData?.invoiceDetail?.signatureUrl === null
      ) {
        setResetSignatureUrl(apiResponseData?.invoiceDetail?.signatureUrl);
      }
      reset({ ...values, ...defaultValue });
    } else if (Object.keys(apiResponseData).length) {
      if (id == undefined) return;
      const values = { ...apiResponseData };
      if (values.paymentSettlement) {
        values['bankName'] = values.paymentSettlement?.bankName;
        values['accountNumber'] = values.paymentSettlement?.accountNumber;
        values['accountHolderName'] = values.paymentSettlement?.accountHolderName;
        values['branchName'] = values.paymentSettlement?.branchName;
        values['ifscCode'] = values.paymentSettlement?.ifscCode;
        if (values.paymentSettlement?.accountType) {
          values['accountType'] = {
            label: values.paymentSettlement?.accountType,
            value: values.paymentSettlement?.accountType,
          };
        }
      }
      if (values.invoiceDetail) {
        values['gstin'] = values.invoiceDetail?.gstin;
        values['panNumber'] = values.invoiceDetail?.panNumber;
        values['RegistrationCompanyAddress'] = values.invoiceDetail?.registrationCompanyAddress;
        values['emailAddress'] = values.invoiceDetail?.emailAddress;
        values['registrationName'] = values.invoiceDetail?.registrationName;
        values['contactNumber'] = values.invoiceDetail?.contactNumber;
        if (values.invoiceDetail?.state) {
          values['state'] = {
            label: values.invoiceDetail?.state,
            value: values.invoiceDetail?.state,
          };
          setDefaultStateValue(values.invoiceDetail?.state);
        }
        if (values.invoiceDetail?.city) {
          values['city'] = {
            label: values.invoiceDetail?.city,
            value: values.invoiceDetail?.city,
          };
        }

        if (values.invoiceDetail?.gstRegistrationUploadUrl) {
          setResetGSTRegistrationUploadUrl(values.invoiceDetail?.gstRegistrationUploadUrl);
        }

        if (values.invoiceDetail?.signatureUrl) {
          setResetSignatureUrl(values.invoiceDetail?.signatureUrl);
        }
      }

      reset({ ...values, ...defaultValue });
    } else {
      reset(defaultValue);
    }
  }, [paymentsAndInvoice, apiResponseData]);

  useEffect(() => {
    if (id == undefined) return;
    dispatch(fetchEventById(id));
  }, [id]);

  const handleFormSubmit = handleSubmit((values) => {
    if (isGSTFileError) return;
    if (uploadSignature?.length == 0 && resetSignatureUrl == undefined) {
      setIsSignatureFileError(true);
    } else if (
      gstRegistrationFromTicket?.invoiceDetail?.isGSTRegistration === 'true' &&
      uploadRegistration?.length == 0 &&
      resetGSTRegistrationUploadUrl == undefined
    ) {
      setIsGSTFileError(true);
    } else {
      values.paymentSettlement = {};
      values.paymentSettlement.PaymentCountry = values.payoutCountry?.label;
      values.paymentSettlement.CurrencyMode = values.currency?.label;
      values.paymentSettlement.AccountType = values.accountType?.value;
      values.paymentSettlement.bankName = values.bankName;
      values.paymentSettlement.accountHolderName = values.accountHolderName;
      values.paymentSettlement.accountNumber = values.accountNumber;
      values.paymentSettlement.ifscCode = values.ifscCode;
      values.paymentSettlement.branchName = values.branchName;

      if (Object.keys(apiResponseData)?.length) {
        values.invoiceDetail = { ...apiResponseData?.invoiceDetail };
      } else {
        values.invoiceDetail = { ...gstRegistrationFromTicket?.invoiceDetail };
      }

      if (gstRegistrationFromTicket?.invoiceDetail?.isGSTRegistration === 'false') {
        values.invoiceDetail.gstin = '';
        values.invoiceDetail.GSTRegistrationUploadFile = null;
      } else {
        values.invoiceDetail.gstin = values.gstin;
        values.invoiceDetail.GSTRegistrationUploadFile = uploadRegistration[0];
      }
      values.invoiceDetail.panNumber = values.panNumber;
      values.invoiceDetail.emailAddress = values.emailAddress;
      values.invoiceDetail.registrationCompanyAddress = values.RegistrationCompanyAddress;
      values.invoiceDetail.registrationName = values.registrationName;
      values.invoiceDetail.contactNumber = values.contactNumber;
      values.invoiceDetail.country = values.payoutCountry?.label;
      values.invoiceDetail.state = values.state?.label;
      values.invoiceDetail.city = values.city?.label;
      values.invoiceDetail.SignatureFile = uploadSignature ? uploadSignature[0] : null;

      if (resetGSTRegistrationUploadUrl) {
        values.invoiceDetail.gstRegistrationUploadUrl = resetGSTRegistrationUploadUrl;
      }
      if (resetSignatureUrl) {
        values.invoiceDetail.signatureUrl = resetSignatureUrl;
      }
      if (
        deleteGSTRegistrationUploadUrl == true &&
        apiResponseData?.invoiceDetail?.gstRegistrationUploadUrl
      ) {
        values.invoiceDetail.deleteGSTRegistrationUploadUrl = true;
      }

      if (deleteSignatureUrl == true && apiResponseData.invoiceDetail?.signatureUrl) {
        values.invoiceDetail.deleteSignatureUrl = true;
      }

      delete values?.state;
      delete values?.city;
      delete values?.RegistrationCompanyAddress;
      delete values?.accountHolderName;
      delete values?.accountNumber;
      delete values?.accountType;
      delete values?.bankName;
      delete values?.branchName;
      delete values?.contactNumber;
      delete values?.currency;
      delete values?.emailAddress;
      delete values?.gstin;
      delete values?.ifscCode;
      delete values?.panNumber;
      delete values?.payoutCountry;
      delete values?.registrationName;

      if (createdEventResponse?.id) {
        values.Id = createdEventResponse.id;
      }
      if (id) {
        values.Id = id;
        dispatch(saveApiData({ ...apiResponseData, ...values }));
      }
      dispatch(savePaymentAndInvoice(values));
      dispatch(updateCreateEvent(values.Id)); // PUT API Call
      setSuccessfullHandleSubmit(true);
      if (draftBtnClicked == false) {
        if (loading == false) {
          dispatch(setCurrentStep(currentStep + 1));
          dispatch(setStepsFormData({ [stepsName.paymentsAndInvoice.name]: values }));
        }
      }
    }
  });

  return {
    formName: formNameWithDefaultProps(control, errors),
    handleFormSubmit,
    control,
    errors,
    formState,
    reset,
    touched,
    watch,
    setValue,
    setUploadRegistration,
    uploadRegistration,
    setUploadSignature,
    uploadSignature,
    defaultStateValue,
    resetGSTRegistrationUploadUrl,
    setResetGSTRegistrationUploadUrl,
    setDeleteGSTRegistrationUploadUrl,
    resetSignatureUrl,
    setResetSignatureUrl,
    setDeleteSignatureUrl,
    isSignatureFileError,
    isGSTFileError,
  };
}
