import { DoctAutoComplete } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import { useEffect } from 'react';
import { Controller } from 'react-hook-form';

const WorkExperience = ({ control, errors, watch, setValue }) => {
  const watchStartYear = watch('startYear');
  const watchExperienceType = watch('experience');

  const startYearsExperince = [...Array(30).keys()].map((i) => {
    return { label: `${i}`, value: i };
  });

  const endYearsExperince = [...Array(30).keys()].map((i) => {
    return { label: `${i + 1}`, value: i + 1 };
  });

  const [defaultExperienceVal, setDefaultExperienceVal] = useState(null);

  useEffect(() => {
    if (!watchStartYear?.value) return;
    if (defaultExperienceVal) {
      setValue('endYear', null);
      return;
    }
    setDefaultExperienceVal(watchStartYear.value);
  }, [watchStartYear]);

  return (
    <>
      <DoctTypography variant="subtitle2">Work Experience</DoctTypography>
      <DoctTypography variant="body2" className="text-grey-600">
        Specify required experience range between minimum to maximum in years.
      </DoctTypography>
      <div className="input-column">
        <Controller
          control={control}
          name="experience"
          render={(field) => {
            return (
              <RadioGroup
                {...field}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  setValue('startYear', null);
                  setValue('endYear', null);
                }}
              >
                <div className="d-inline-flex ml-2">
                  <FormControlLabel
                    value="Experienced"
                    control={<Radio />}
                    label="Experienced"
                    className="text-grey-800 bg-white col-6"
                  />
                  <FormControlLabel
                    value="Freshers"
                    control={<Radio />}
                    label="Fresher/ Trainee"
                    className="text-grey-800 bg-white col-6 ml-0"
                  />
                </div>
              </RadioGroup>
            );
          }}
        ></Controller>

        {/* {jobType == 'Experienced' && ( */}
        <div className="input-column mt-2 d-flex ml-1">
          <DoctAutoComplete
            disabled={watchExperienceType !== 'Experienced'}
            control={control}
            className="ml-sm-n2 max-min-year-box"
            isErrors={errors}
            name={'startYear'}
            value={watch('startYear') || null}
            id={'startYear'}
            label={'Min Years'}
            validationRules={
              watchExperienceType !== 'Experienced' ? {} : { required: 'this field is required' }
            }
            options={startYearsExperince}
          />

          <DoctTypography variant="textLabel1" className="text-grey-600 text-center col">
            To
          </DoctTypography>

          <div className="col">
            <DoctAutoComplete
              disabled={watchExperienceType !== 'Experienced'}
              control={control}
              isErrors={errors}
              name={'endYear'}
              id={'endYear'}
              className="max-min-year-box ml-sm-n2"
              value={watch('endYear') || null}
              label={'Max Years'}
              validationRules={
                watchExperienceType !== 'Experienced' ? {} : { required: 'this field is required' }
              }
              options={endYearsExperince}
              getOptionDisabled={(option) => option?.value <= watchStartYear?.value}
            />
          </div>
        </div>
        {/* )} */}
      </div>
    </>
  );
};

export default WorkExperience;
