import React, { useEffect, useState } from 'react';
import { DoctModal, DoctForm, DoctTextField } from '@docthub.frontend/app';
import { ActionRowLayout } from '../../ExpandableArea';
import { ActionButton, TitleDescription } from './CommonComponent';
import { useGetUserQuery, useUpdateProfileMutation } from '../../../../../../components';
import { InputColumn, InputColumnTiny } from '../../../../../../shared';
import fieldProfileSetting from '../../../../Profile/components/field.profileSettings';
import { useNotifySuccessFailed } from '../../../../../../hooks';
import { excludeUnusedProfileData } from '../../../../Profile/components/AdminProfile/helperFunction.AdminProfile';
import { DoctTypography } from 'doct-core';

function formDefaultValue(telephoneNumber) {
  return {
    [fieldProfileSetting.telephoneNumberCode.name]: telephoneNumber?.split('-')[0]?.split('+')[1],
    [fieldProfileSetting.telephoneNumber.name]: telephoneNumber?.split('-')[1],
  };
}

function Form({ control, errors, touched, reset, defaultValues }) {
  useEffect(() => {
    reset(defaultValues);
  }, []);

  return (
    <>
      <InputColumn className="d-flex">
        <InputColumnTiny>
          <DoctTextField
            name={fieldProfileSetting.telephoneNumberCode.name}
            label={fieldProfileSetting.telephoneNumberCode.label}
            id={fieldProfileSetting.telephoneNumberCode.id}
            validationRules={fieldProfileSetting.telephoneNumberCode.validationRules}
            control={control}
            isErrors={errors}
            touched={touched}
            showStar={false}
          />
        </InputColumnTiny>
        <DoctTextField
          name={fieldProfileSetting.telephoneNumber.name}
          label={fieldProfileSetting.telephoneNumber.label}
          id={fieldProfileSetting.telephoneNumber.id}
          validationRules={fieldProfileSetting.telephoneNumber.validationRules}
          control={control}
          isErrors={errors}
          touched={touched}
          showStar={false}
        />
      </InputColumn>
      <DoctTypography variant="textLabel2" className="my-3 mb-0 text-grey-600">
        This number will be used for your external communication with Docthub regarding enterprise
        account.
      </DoctTypography>
    </>
  );
}

export default function ChangeTelephone() {
  const { data: userData } = useGetUserQuery('');
  const { telephoneNumber } = userData || {};

  const [showModal, setShowModal] = useState(false);

  const [useUpdateProfile, { isLoading, isSuccess, isError }] = useUpdateProfileMutation();

  const { handleSubmit, control, errors, reset, touched } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const hookFormProps = { control, errors, touched, reset };

  const initApiCall = handleSubmit((data) => {
    const {
      [fieldProfileSetting.telephoneNumberCode.name]: code,
      [fieldProfileSetting.telephoneNumber.name]: telephone,
    } = data || {};

    useUpdateProfile(
      excludeUnusedProfileData({
        ...userData,
        [fieldProfileSetting.telephoneNumber.name]: `+${code}-${telephone}`,
        ImageFileName: 'image',
        ResumeFileName: 'resume',
      }),
    );
  });

  useNotifySuccessFailed({ isSuccess, isError, onSuccessHandler: () => setShowModal(false) });

  return (
    <>
      <ActionRowLayout
        onClick={() => {}}
        showCursor={false}
        title={
          <TitleDescription
            title={'Telephone:'}
            description={telephoneNumber?.replace('-', ' ') || '-'}
          />
        }
        rightArea={
          <ActionButton
            btnText={telephoneNumber ? 'CHANGE' : 'ADD'}
            onClick={() => {
              setShowModal(true);
            }}
          />
        }
      />
      {showModal && (
        <DoctModal
          isPrimaryBtnDisable={isLoading}
          iconName={''}
          primaryBtnText={'Submit'}
          open
          className={'disable_modal_outside_click'}
          handlePrimaryButtonClick={initApiCall}
          handleClose={() => {
            setShowModal(false);
          }}
          title={telephoneNumber ? 'Edit Telephone Number' : 'Add New Telephone Number'}
          width={360}
        >
          <Form {...hookFormProps} defaultValues={formDefaultValue(telephoneNumber)} />
        </DoctModal>
      )}
    </>
  );
}
