import React from 'react';
import { DoctIcon, DoctTypography } from 'doct-core';

import * as ROUTE from '../../../../routes/constant';
import SkeletonCardView2 from '../../../../shared/ui/SkeletonCard/SkeletonCardView2';

const UserAppCard = ({
  status,
  totalRecords,
  firstSubHeadingCount,
  secondSubHeadingCount,
  thirdSubHeadingCount,
  newApplicantCount,
  newApplicants,
  navigate,
  handleNextData,
  handlePreviewData,
  loadRecord,
  isLoading,
}) => {
  const applicantsClassName = `${
    status == 'Courses' ? 'courses-description' : status == 'Jobs' ? 'jobs-description' : ''
  }`;
  const cardBackgroundColor = `${
    status == 'Courses' ? 'bg-green-shade' : status == 'Jobs' ? 'bg-light-shade-blue' : ''
  }`;
  const statusDescription = `${
    status == 'Courses'
      ? 'course-status-description'
      : status == 'Jobs'
      ? 'jobs-status-description'
      : ''
  }`;

  function navigation(status) {
    if (status == 'Courses') {
      navigate(`/${ROUTE.DASHBOARD}/${ROUTE.INSTITIUTE}/${ROUTE.INSTITUTE_ADD_COURSE}`);
    } else if (status == 'Jobs') {
      navigate(`/${ROUTE.DASHBOARD}/${ROUTE.RECRUITER}/${ROUTE.POST_A_JOB}`);
    }
  }

  if (isLoading) {
    return <SkeletonCardView2 />;
  }

  return (
    <div className="user-select-none">
      <span
        onClick={() => {
          navigation(status);
        }}
        className="cursor-pointer"
      >
        <DoctIcon
          name="plus"
          width="24"
          height="24"
          fill="#00A0C0"
          className="mr-4 position-absolute right-0 negative-margin-for-btn"
        />
      </span>
      <div className={`p-5 ${cardBackgroundColor} pb-lg-4 border-radius px-2`}>
        <div className="d-flex align-items-center justify-content-center">
          <div className="text-center mx-2 col-5">
            <DoctTypography variant="h3" className="text-grey-800 font-weight-medium">
              {totalRecords}
            </DoctTypography>
            <DoctTypography
              variant="textLabel2"
              className={`text-grey-400 m-0 mt-1 ${
                status == 'Courses' && 'course_count_width ml-sm-n2'
              }`}
            >
              {status == 'Jobs' ? 'Total Jobs' : status == 'Courses' ? 'Listed Courses' : ''}
            </DoctTypography>
          </div>
          <div className={`col-11 ${status == 'Jobs' ? '' : 'mt-sm-n4'}`}>
            <div
              className={`${statusDescription} d-flex border-radius-6px mb-1 justify-content-between px-2`}
            >
              <DoctTypography variant="textLabel2" className="text-grey-800 m-0 p-1">
                {status == 'Jobs' ? 'Active Jobs' : status == 'Courses' ? 'Open for Apply' : ''}
              </DoctTypography>
              <span className="text-grey-800 m-0 p-1 text-label-2">{firstSubHeadingCount}</span>
            </div>
            {status == 'Courses' && (
              <div
                className={`${statusDescription} d-flex border-radius-6px mb-1 justify-content-between px-2`}
              >
                <DoctTypography variant="textLabel2" className="text-grey-800 m-0 p-1">
                  Apply to Website
                </DoctTypography>
                <span className="text-grey-800 m-0 p-1 text-label-2">{secondSubHeadingCount}</span>
              </div>
            )}
            <div className="dashboard-status-description d-flex border-radius-6px justify-content-between px-2">
              <DoctTypography variant="textLabel2" className="text-grey-600 m-0 p-1">
                {status == 'Jobs' ? 'Closed' : status == 'Courses' ? 'Draft' : ''}
              </DoctTypography>
              <span className="text-grey-600 m-0 p-1 text-label-2 mr-1">
                {thirdSubHeadingCount}
              </span>
            </div>
          </div>
        </div>

        <div
          className={`d-flex justify-content-between align-items-center new-applicants-counts border-radius mt-4 px-2 ${applicantsClassName} `}
        >
          <DoctTypography variant="subtitle3" className="text-white m-0 p-1">
            New Applicants
          </DoctTypography>
          <DoctTypography variant="subtitle3" className="text-white m-0 p-1">
            {newApplicantCount}
          </DoctTypography>
        </div>
      </div>

      {newApplicants?.length > 0 && (
        <div className="bg-light-shade-grey border-radius px-20px d-flex align-items-center justify-content-between mt-1 py-12px">
          {newApplicants
            ?.slice(loadRecord == 1 ? 0 : loadRecord - 1, loadRecord)
            ?.map(({ value, count }, index) => {
              return (
                <div key={index}>
                  <DoctTypography
                    variant="textLabel2"
                    fontWeight="regular"
                    className="text-grey-800 text-truncate"
                  >
                    {value}
                  </DoctTypography>
                  <DoctTypography
                    variant="body3"
                    fontWeight="medium"
                    className="text-success mt-sm-n2 mb-1"
                  >
                    {count} new applicants
                  </DoctTypography>
                </div>
              );
            })}
          <div className="d-flex align-items-center p-0 m-0 mt-1 ml-1">
            <div
              onClick={handlePreviewData}
              className={`cursor-pointer ${loadRecord == 1 && 'cursor-pointer-none'}`}
            >
              <DoctIcon name="left" width="24" height="24" fill="#AAAAAA" />
            </div>
            <div
              onClick={handleNextData}
              className={`cursor-pointer ${
                newApplicants?.length == loadRecord && 'cursor-pointer-none'
              }`}
            >
              <DoctIcon name="right" width="24" height="24" fill="#AAAAAA" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserAppCard;
