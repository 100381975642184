import React from 'react';
import { DoctPasswordInput } from '@docthub.frontend/app';
import {
  MIN_PASSWORD_LENGTH,
  PASSWORD_VALIDATION,
  PASSWORD_VALIDATION_TEXT,
} from '../../constants/constants';

/**
 * Created password input component so it can be used in common places
 * @param control this will be passed from the react hook form
 * @param errors this will be passed from the react hook form
 * @param overrideValidation it's an optional validation rules
 * @param name name to differentiate the input
 * @param label this will be passed to see label in input
 */

const PasswordInput = ({ control, errors, overrideValidation, name, label, showStar }) => {
  const validationRules = {
    required: "It's Required Field",
    pattern: {
      value: PASSWORD_VALIDATION,
      message: PASSWORD_VALIDATION_TEXT,
    },
    minLength: {
      value: MIN_PASSWORD_LENGTH,
      message: `Minimum ${MIN_PASSWORD_LENGTH} characters required!`,
    },
  };
  return (
    <DoctPasswordInput
      name={name}
      label={label}
      id={name}
      control={control}
      showStar={showStar}
      isErrors={errors}
      validationRules={overrideValidation ? overrideValidation : validationRules}
    />
  );
};

export default PasswordInput;
