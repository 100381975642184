import { DoctAutoCompleteWithChipsFreeSolo } from '@docthub.frontend/app';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormElWithHeading } from '../../../../../shared';
import {
  selectValueSupportedBy,
  setIsFormDirty,
  setValueSupportedBy,
} from '../../slice/activityLog.slice';
import fieldAddEditActivityLog from '../field.AddEditActivityLog';

export default function SupportedByActivityLog() {
  const dispatch = useDispatch();

  const valueOrganizingTeam = useSelector(selectValueSupportedBy); // selected Value

  const handleOnChange = (val) => {
    dispatch(setValueSupportedBy(val)); // set selected value
    dispatch(setIsFormDirty(true));
  };

  return (
    <FormElWithHeading heading={fieldAddEditActivityLog.supportedBy.label}>
      <DoctAutoCompleteWithChipsFreeSolo
        textFieldProps={{ label: 'Add Sponsors, Supporters' }}
        value={valueOrganizingTeam}
        onChange={handleOnChange}
      />
    </FormElWithHeading>
  );
}
