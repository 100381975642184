import React from 'react';
import style from '../SubscriptionModal.module.scss';
import { DoctTypography } from 'doct-core';
import ContactDetailChip from './ContactDetailChip';

export default function NeedAssistance({ Email, Phone, infoMessage }) {
  return (
    <div className={`${style['need-assistance-body']} d-md-flex mt-4 align-items-center`}>
      <DoctTypography variant="subtitle1" className={`ml-4 ${style['need-assistance-text-color']}`}>
        Need Assistance?
      </DoctTypography>
      <ContactDetailChip Email={Email} Phone={Phone} />
      <DoctTypography variant="subtitle2" className="text-grey-600 font-weight-regular">
        {infoMessage
          ? infoMessage
          : 'For information about the subscription plan, connect with our support team.'}
      </DoctTypography>
    </div>
  );
}
