import React from 'react';
import { DoctButton } from 'doct-core';
export const MembershipCreationFooter = ({ newMembershipLoading }) => {
  return (
    <DoctButton
      className="mx-3 my-3 float-right position-relative btn_membership_information_submission"
      text="Submit"
      type="primary"
      size="medium"
      disabled={newMembershipLoading}
    />
  );
};
