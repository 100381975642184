import { DoctForm } from '@docthub.frontend/app';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEventById,
  saveApiData,
  saveRegistartionDetails,
  saveSaveAsDraft,
  selectCreateEventResponse,
  selectCurrentStep,
  selectRegistartionDetails,
  selectResponseData,
  selectSaveAsDraftClicked,
  selectUpdateEventLoader,
  setCurrentStep,
  setDefaultState,
  setStepsFormData,
} from '../../../createEvent.slice';
import stepsName from '../stepsName';
import { getSpecialtyNames, getSubjectTagsNames } from './services/EventRegistrationSectionService';
import { useParams } from 'react-router-dom';
import { updateCreateEvent } from '../../../services/CreateEventServices';
import { useNavigate } from 'react-router-dom';

export default function useFormRegistration() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const registartionDetails = useSelector(selectRegistartionDetails);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const createdEventResponse = useSelector(selectResponseData);
  const loading = useSelector(selectUpdateEventLoader);
  const draftBtnClicked = useSelector(selectSaveAsDraftClicked);
  const navigate = useNavigate();

  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue, register } =
    DoctForm({
      mode: 'onChange',
      defaultValues: {},
    });

  const [specialtySearchText, setSpecialtySearchText] = useState();
  const [specialityArray, setSpecialityArray] = useState([]);
  const [specialtyDataOption, setSpecialtyDataOption] = useState();

  const [subjectTagsSearchText, setSubjectTagsSearchText] = useState();
  const [subjectTagsArray, setSubjectTagsArray] = useState([]);
  const [subjectTagsDataOption, setSubjectTagsDataOption] = useState();

  const [members, setMembers] = useState([]);
  const [supporters, setSupporters] = useState([]);

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const [photoSrc, setPhotoSrc] = useState();
  const [profileUrlOnEdit, setProfileUrlOnEdit] = useState(null);
  const [defaultCountryValue, setDefaultCountryValue] = useState();
  const [defaultStateValue, setDefaultStateValue] = useState();
  const [successfullHandleSubmit, setSuccessfullHandleSubmit] = useState(false);
  const [resetBrochureUrl, setResetBrochureUrl] = useState();
  const [deleteBrochureUrl, setDeleteBrochureUrl] = useState(false);
  const [deleteCoverImageUrl, setDeleteCoverImageUrl] = useState(false);

  const [isSpecialtyEmpty, setIsSpecialtyEmpty] = useState(false);

  const selectedSpecialityId = [];
  const selectedSubjectTagId = [];

  useEffect(() => {
    getSpecialtyNames(specialtySearchText).then((data) => {
      setSpecialtyDataOption(data);
    });

    getSubjectTagsNames(subjectTagsSearchText).then((data) => {
      setSubjectTagsDataOption(data);
    });
  }, [specialtySearchText, subjectTagsSearchText]);

  // select id of selectedSpeciality by name

  const findSpecialityByName = (selectedSpeciality) => {
    const foundSpeciality = specialtyDataOption?.filter((speciality) => {
      return speciality?.title == selectedSpeciality;
    });

    foundSpeciality?.map((item) => {
      selectedSpecialityId.push(item?.id);
    });
  };

  // select id of subjectTags by name

  const findSubjectTagsByName = (selectedSubjectTag) => {
    const foundSubjectTag = subjectTagsDataOption?.filter((subjectTag) => {
      return subjectTag?.title == selectedSubjectTag;
    });

    foundSubjectTag?.map((item) => {
      selectedSubjectTagId.push(item?.id);
    });
  };

  useEffect(() => {
    if (specialityArray?.length > 0) {
      setIsSpecialtyEmpty(false);
    }
  }, [specialityArray]);

  useEffect(() => {
    if (Object.keys(registartionDetails)?.length) {
      const values = { ...registartionDetails };
      if (values?.brochureFile) {
        setUploadedFiles([values?.brochureFile]);
      } else if (apiResponseData?.brochureUrl === null || apiResponseData?.brochureUrl) {
        setResetBrochureUrl(apiResponseData?.brochureUrl);
      }
      if (values?.coverImageFile) {
        setPhotoSrc(window.URL.createObjectURL(values?.coverImageFile));
      } else if (
        apiResponseData?.image?.coverImageUrl === null ||
        apiResponseData?.image?.coverImageUrl
      ) {
        setProfileUrlOnEdit(apiResponseData?.image?.coverImageUrl);
      }

      if (values.organizer) {
        values['Name'] = values.organizer.name;
        values['organizerCountry'] = {
          label: values.organizer.organizerCountry,
          value: values.organizer.organizerCountry,
        };
        setDefaultCountryValue(values.organizer.organizerCountry);
        values['organizerState'] = {
          label: values.organizer.organizerState,
          value: values.organizer.organizerState,
        };
        setDefaultStateValue(values.organizer.organizerState);
        values['organizerCity'] = {
          label: values.organizer.organizerCity,
          value: values.organizer.organizerCity,
        };
        values['organizerPinCode'] = values.organizer.organizerPinCode;
      }

      if (values.specialitiesArray?.length) {
        setSpecialityArray(values.specialitiesArray);
      }

      if (values.subjectTagsArray?.length) {
        setSubjectTagsArray(values.subjectTagsArray);
      }

      if (values?.AdditionalDetails?.length) {
        setValue('ContactInformation.AdditionalDetails', values.AdditionalDetails);
      }

      reset({ ...values });
    } else if (Object.keys(apiResponseData)?.length) {
      if (id === undefined) return;

      dispatch(saveApiData(apiResponseData));
      const values = { ...apiResponseData };

      if (values?.brochureFile) {
        setUploadedFiles([values?.brochureFile]);
      }

      if (values?.image?.coverImageUrl) {
        setProfileUrlOnEdit(values?.image?.coverImageUrl);
      }

      values.eventTitle = values.name;

      if (values.contactInformation) {
        values['personName'] = values.contactInformation?.personName;
        values['email'] = values.contactInformation?.email;
        values['mobileNo'] = values.contactInformation?.mobileNo;
        values['phoneNo'] = values.contactInformation?.phoneNo;
      }

      if (values?.organizer) {
        values['Name'] = values.organizer.name;
        values['organizerPinCode'] = values.organizer.organizerPinCode;
      }

      if (values.organizer?.organizerCountry) {
        values['organizerCountry'] = {
          label: values.organizer.organizerCountry,
          value: values.organizer.organizerCountry,
        };
        setDefaultCountryValue(values.organizer.organizerCountry);
        values['organizerState'] = {
          label: values.organizer.organizerState,
          value: values.organizer.organizerState,
        };
        setDefaultStateValue(values.organizer.organizerState);
        values['organizerCity'] = {
          label: values.organizer.organizerCity,
          value: values.organizer.organizerCity,
        };
      }

      if (values.specialities?.length) {
        setSpecialityArray(values.specialities);
      }

      if (values.tags?.length) {
        setSubjectTagsArray(values.tags);
      }

      if (values?.brochureUrl) {
        setResetBrochureUrl(values?.brochureUrl);
      }

      if (values?.contactInformation?.additionalDetails?.length) {
        values['ContactInformation.AdditionalDetails'] =
          values?.contactInformation?.additionalDetails;

        setValue(
          'ContactInformation.AdditionalDetails',
          values?.contactInformation?.additionalDetails,
        );
      }
      reset({ ...values });
    }
  }, [registartionDetails, Object.keys(apiResponseData || {}).length]);

  useEffect(() => {
    if (id == undefined) return;
    dispatch(fetchEventById(id));
  }, [id]);

  useEffect(() => {
    if (draftBtnClicked == true) {
      if (successfullHandleSubmit) {
        if (!loading) {
          dispatch(saveSaveAsDraft(false));
          dispatch(setDefaultState());
          navigate(-1);
        }
      } else {
        handleFormSubmit();
      }
    }
  }, [draftBtnClicked, successfullHandleSubmit, loading]);

  useMemo(() => {
    if (Object.keys(errors)?.length) {
      dispatch(saveSaveAsDraft(false));
    }
  }, [Object.keys(errors)?.length]);

  const handleFormSubmit = handleSubmit((values) => {
    if (specialityArray?.length == 0) {
      setIsSpecialtyEmpty(true);
    } else {
      specialityArray?.map((item) => {
        findSpecialityByName(item);
      });

      subjectTagsArray?.map((item) => {
        findSubjectTagsByName(item);
      });

      values.specialitiesArray = specialityArray;
      values.subjectTagsArray = subjectTagsArray;
      values.brochureFile = uploadedFiles ? uploadedFiles[0] : null;

      values.contactInformation = {};
      values.contactInformation.personName = values.personName;
      values.contactInformation.mobileNo = values.mobileNo;
      values.contactInformation.phoneNo = values.phoneNo ? values.phoneNo : null;
      values.contactInformation.email = values.email;

      values.contactInformation.additionalDetails = values.AdditionalDetails;

      values.organizer = {};
      values.organizer.OrganizingTeam = [];

      let transformMembers;

      if (members?.length) {
        transformMembers = members.map((objOfMember) => {
          return {
            ...objOfMember,
            pictureFile: objOfMember?.pictureFile?.[0] || objOfMember?.pictureFile,
          };
        });
      }

      values.organizer.OrganizingTeam = transformMembers;
      if (values?.imageProfile?.length) {
        values.coverImageFile = values.imageProfile[0];
      }
      values.organizer.name = values.Name;
      values.organizer.organizerCountry = values.organizerCountry?.label;
      values.organizer.organizerState = values.organizerState?.label;
      values.organizer.organizerCity = values.organizerCity?.label;
      values.organizer.organizerPinCode = values.organizerPinCode;
      let transformSupporters;

      if (supporters?.length) {
        transformSupporters = supporters.map((objOfSupporters) => {
          return {
            ...objOfSupporters,
            SupportedByPictureFile:
              objOfSupporters?.SupportedByPictureFile?.[0] ||
              objOfSupporters?.SupportedByPictureFile,
          };
        });
      }

      values.supportedBy = transformSupporters;

      if (deleteBrochureUrl === true && apiResponseData?.brochureUrl) {
        values.deleteBrochureUrl = true;
      }
      if (deleteCoverImageUrl === true && apiResponseData?.image?.coverImageUrl) {
        values.deleteCoverImageUrl = true;
      }

      values.specialtyIds = [...new Set(selectedSpecialityId)];
      values.subjectTagIds = [...new Set(selectedSubjectTagId)];

      if (createdEventResponse?.id) {
        values.Id = createdEventResponse.id;
      }
      if (id) {
        values.Id = id;
      }

      delete values?.organizerState?.cities;

      dispatch(saveRegistartionDetails(values));
      dispatch(updateCreateEvent(values.Id)); // PUT API Call
      setSuccessfullHandleSubmit(true);
      if (draftBtnClicked == false) {
        if (loading == false) {
          dispatch(setCurrentStep(currentStep + 1));
          dispatch(setStepsFormData({ [stepsName.registration.name]: values }));
        }
      }
    }
  });

  return {
    handleFormSubmit,
    control,
    errors,
    formState,
    reset,
    touched,
    register,
    watch,
    setValue,
    specialtyDataOption,
    setSpecialtySearchText,
    setSpecialityArray,
    specialityArray,
    setSubjectTagsSearchText,
    setSubjectTagsArray,
    subjectTagsArray,
    subjectTagsDataOption,
    setUploadedFiles,
    uploadedFiles,
    setMembers,
    members,
    setPhotoSrc,
    photoSrc,
    profileUrlOnEdit,
    findSpecialityByName,
    setSupporters,
    supporters,
    findSubjectTagsByName,
    defaultCountryValue,
    defaultStateValue,
    setResetBrochureUrl,
    resetBrochureUrl,
    setDeleteBrochureUrl,
    isSpecialtyEmpty,
    setDeleteCoverImageUrl,
    setProfileUrlOnEdit,
  };
}
