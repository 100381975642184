import React, { useEffect } from 'react';
import { DoctTabContent, DoctTabWrapper } from '@docthub.frontend/app';
import Subscriptions from './Subscriptions';
import MySubscriptionsLayout from './MySubscriptionsLayout';
import { useGetMySubscriptionsQuery } from './API/subscriptionApi';
import { useDispatch, useSelector } from 'react-redux';
import { setTabValue } from './API/subscriptionApiSlice';

const options = [
  {
    label: 'Packages',
  },
  {
    label: 'My Subscriptions',
  },
];

export default function SubscriptionTabContent() {
  const tabValue = useSelector((state) => state?.subscriptionData?.tabValue);
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem('encryptedSubscriptionPlanData');
  }, []);

  const handleChange = (event, newValue) => {
    dispatch(setTabValue(newValue));
  };

  const { data, isLoading } = useGetMySubscriptionsQuery();
  return (
    <div>
      <DoctTabWrapper tabOptions={options} value={tabValue} handleChange={handleChange} />
      {tabValue == 0 && (
        <DoctTabContent value={tabValue} index={0}>
          <Subscriptions />
        </DoctTabContent>
      )}
      {tabValue == 1 && (
        <DoctTabContent value={tabValue} index={1}>
          <MySubscriptionsLayout {...data} isLoading={isLoading} />
        </DoctTabContent>
      )}
    </div>
  );
}
