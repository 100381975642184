import React from 'react';
import { DoctAnimationLoading } from '@docthub.frontend/app';

export default function LoaderForModal() {
  return (
    <div className="content-with-animated-loader-area content-with-animated-loader-area-min-height">
      <div className="content-animated-loader-row transparent-bg">
        <DoctAnimationLoading />
      </div>
    </div>
  );
}
