import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { selectIsFormDirty, selectLoading, setIsFormDirty } from '../slice/activityLog.slice';
import fieldAddEditActivityLog from './field.AddEditActivityLog';
import qs from 'qs';
import { ACTIVITY_LOG_PATH } from '../../../../routes/constant';
import { AddEditFormHeaderFooterMainArea, Form, FormUIWrapper } from '../../../../shared';
import { DoctAnimationLoading } from '@docthub.frontend/app';
import { FormGrid } from './components';
import FormAddEditActivityLog from './Form.AddEditActivityLog';
import useFormCommon from '../../../../shared/form/useForm.Common';

// import style from './AddEditActivityLog.module.scss';

export default function AddEditActivityLogWrapper({
  onSubmit,
  defaultValues = {},
  title,
  edit,
  isAddOrUpdating,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(selectLoading);
  const isFormDirty = useSelector(selectIsFormDirty);

  const {
    formProps: { handleSubmit, control, errors, setValue, watch, formState, getValues },
  } = useFormCommon({ defaultValues });

  const formProps = { control, isErrors: errors, setValue, watch, getValues };

  const watchStartDate = watch(fieldAddEditActivityLog.startDate.name);

  useEffect(() => {
    dispatch(setIsFormDirty(!!Object.keys(formState?.dirtyFields)?.length));
  }, [qs.stringify(formState?.dirtyFields)]);

  const onCloseHandler = () => {
    navigate(ACTIVITY_LOG_PATH);
  };

  return (
    <>
      <AddEditFormHeaderFooterMainArea
        title={title}
        onClose={onCloseHandler}
        footerProps={{
          btnText: 'Save Activity',
          onButtonClicked: handleSubmit((data) => onSubmit(data)),
          loading: loading || isAddOrUpdating || (edit && !isFormDirty),
        }}
      >
        <Form>
          <FormUIWrapper heading={'Activity Details'}>
            <div
              className={`content-animated-loader-row ${isAddOrUpdating ? 'd-block' : 'd-none'}`}
            >
              <DoctAnimationLoading />
            </div>
            <FormGrid>
              <FormAddEditActivityLog formProps={formProps} minDateOfEndDate={watchStartDate} />
            </FormGrid>
          </FormUIWrapper>
        </Form>
      </AddEditFormHeaderFooterMainArea>
    </>
  );
}
