import React, { useState } from 'react';
import style from '../SubscriptionModal.module.scss';
import { DoctButton, DoctTypography } from 'doct-core';
import BillingDetailsFilled from './BillingDetailsFilled';
import { useGetAdditionalInformationQuery } from '../API/subscriptionApi';
import { DoctAnimationLoading } from '@docthub.frontend/app';
import * as ROUTE from '../../../routes/constant';
import useSendSubscription from '../hooks/useSendSubscription';

export default function BillingDetailsLayout() {
  const { data: billingInfo, isLoading } = useGetAdditionalInformationQuery();
  const { navigate } = useSendSubscription();
  const [openModal, setIsModalOpen] = useState(false);
  if (isLoading) {
    return (
      <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
        <DoctAnimationLoading />
      </div>
    );
  }
  return billingInfo?.id ? (
    <BillingDetailsFilled
      openModal={openModal}
      billingInfo={billingInfo}
      setIsModalOpen={setIsModalOpen}
    />
  ) : (
    <>
      <div className={`bg-grey-100 p-3 border-radius-tr border-radius-tl`}>
        <DoctTypography variant="subtitle2" className="text-grey-600 mt-0 mb-0">
          Billing Information
        </DoctTypography>
      </div>
      <div
        className={`${style['billing-details-body']} bg-grey-100 p-3 d-flex align-items-center justify-content-center`}
      >
        <div className="d-block">
          <div className="d-flex justify-content-center">
            <DoctTypography variant="subtitle3" className="text-grey-600 font-weight-regular">
              Add Organization information and Billing address
              <br /> details which will be used for subscriptions orders.
            </DoctTypography>
          </div>
          <div className="d-flex justify-content-center">
            <DoctButton
              text="Add now"
              variant="contained"
              size="medium"
              onButtonClickHandler={() =>
                navigate(
                  `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/:id?`,
                )
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}
