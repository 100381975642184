import { DoctForm } from '@docthub.frontend/app';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateProfileMutation } from '../../../../../components';
import { NETWORK_REQUEST_ERROR_MSG } from '../../../../../constants/constants';
import { TOST_BOTTOM_CENTER } from '../../../../../constants/toasterPosition.constants';
import { setShowToasterWithParams } from '../../../../featuresSlice';
import FormNameDesignation from './Form.NameDesignation';
import { excludeUnusedProfileData } from './helperFunction.AdminProfile';

export default function EditAdminProfile({
  defaultValues,
  userData,
  modalComponent: ModalComponent,
  handleModalClose,
}) {
  const dispatch = useDispatch();

  const { handleSubmit, control, errors, touched } = DoctForm({
    mode: 'onChange',
    defaultValues,
  });

  const [useUpdateProfile, { isLoading, isSuccess, isError }] = useUpdateProfileMutation();

  const onSubmitForm = handleSubmit((data) => {
    useUpdateProfile(
      excludeUnusedProfileData({
        ...userData,
        ...data,
        ImageFileName: 'image',
        ResumeFileName: '',
      }),
    );
  });

  const hookFormProps = { control, isErrors: errors, touched };
  const modalProps = { onSubmitForm, isLoading, handleModalClose };

  useEffect(() => {
    if (isSuccess) {
      handleModalClose();
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Changes saved successfully!',
        }),
      );
    }
    if (isError) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
    }
  }, [isSuccess, isError]);

  return (
    <ModalComponent {...modalProps}>
      <FormNameDesignation hookFormProps={hookFormProps} />
    </ModalComponent>
  );
}
