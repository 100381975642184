import React, { useState } from 'react';
import { DoctIconButton, DoctTypography } from 'doct-core';

import { ChangePassword, ChangePhoneNumber, ExpandableAreaHeader } from './components';

const TAB_NAME = {
  changePhoneNumber: 'CHANGE_PHONE_NUMBER',
  changPassword: 'CHANGE_PASSWORD',
};

function Layout({ children, selectedTab, setSelectedTab }) {
  return (
    <div className="bg-grey-100 pb-12px border-radius">
      <div className="panel-height-4x d-flex align-items-center px-3 d-inline-flex">
        {selectedTab && (
          <span className="cursor-pointer mr-2 d-inline-flex ml-n1">
            <DoctIconButton
              onButtonClickHandler={() => setSelectedTab()}
              icon="arrowBack"
              variant="text"
              type="secondary"
              size="medium"
            />
          </span>
        )}
        <DoctTypography variant="subtitle2" className="text-grey-600">
          {selectedTab || 'Login & Security Settings'}
        </DoctTypography>
      </div>
      {React.Children.map(children, (child) => {
        if (!child) return;
        return React.cloneElement(child, {
          setSelectedTab: setSelectedTab,
        });
      })}
    </div>
  );
}

function ExpandableTabView({ options, onChange, children }) {
  const [selectedTab, setSelectedTab] = useState(null);
  const [canExpandTab, setCanExpandTab] = useState(false);

  return (
    <Layout
      selectedTab={canExpandTab ? selectedTab : null}
      setSelectedTab={() => {
        setSelectedTab(null);
        onChange(null);
      }}
    >
      {options.map(({ title, tabName, canExpand }) => {
        return (
          <React.Fragment key={tabName}>
            <ExpandableAreaHeader
              onClick={() => {
                setSelectedTab(title);
                onChange(tabName);
                setCanExpandTab(canExpand);
              }}
              title={title}
              isExpanded={selectedTab && canExpandTab}
            />
          </React.Fragment>
        );
      })}
      {children}
    </Layout>
  );
}

const securityOptions = [
  { title: 'Change Phone Number', canExpand: true, tabName: TAB_NAME.changePhoneNumber },
  { title: 'Change Password', canExpand: false, tabName: TAB_NAME.changPassword },
];

export default function Security() {
  const [selectedTab, setSelectedTab] = useState(null);

  return (
    <ExpandableTabView onChange={setSelectedTab} options={securityOptions}>
      {selectedTab == TAB_NAME.changePhoneNumber && <ChangePhoneNumber />}
      {selectedTab == TAB_NAME.changPassword && <ChangePassword />}
    </ExpandableTabView>
  );
}
