import React, { useEffect, useState } from 'react';
import JobOverview from './components/JobOverview';
import JobDetail from './components/JobDetail';
import './previewJobs.styles.scss';
import { DoctContainer, DoctIcon, DoctTypography } from 'doct-core';
import { useNavigate } from 'react-router';
import { DoctAnimationLoading } from '@docthub.frontend/app';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectApiData,
  selectIsLoadingFetchJobDetails,
  setApiData,
} from '../ManageJob/slice/manageJobSlice';
import { scrollToTop } from '../../../utils/scrollToTop';

const PreviewJob = ({ addBgGrey = true, jobDetails = {}, showClosPreviewHeader = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoadingFetchJobDetails = useSelector(selectIsLoadingFetchJobDetails);
  const jobDetailFromId = useSelector(selectApiData);

  const [storedJobDetail, setStoredJobDetail] = useState({});

  useEffect(() => {
    if (jobDetailFromId && Object.keys(jobDetailFromId).length > 2) {
      localStorage.setItem('jobDetailFromId', JSON.stringify(jobDetailFromId));
    }
    const stored = localStorage.getItem('jobDetailFromId');
    if (stored) {
      setStoredJobDetail(JSON.parse(stored));
    }
  }, [jobDetailFromId]);

  useEffect(() => {
    scrollToTop();
  }, [jobDetails, jobDetailFromId]);

  return (
    <>
      {isLoadingFetchJobDetails && <DoctAnimationLoading />}
      {showClosPreviewHeader && (
        <div className="bg-info">
          <DoctContainer>
            <div className="d-flex align-items-center text-white">
              <span
                className="cursor-pointer"
                onClick={() => {
                  navigate(-1);
                  dispatch(setApiData({}));
                  localStorage.removeItem('jobDetailFromId');
                }}
              >
                <DoctIcon name="close" width="30" />
              </span>
              <DoctTypography variant="body2" className="mx-3">
                Close Preview
              </DoctTypography>
            </div>
          </DoctContainer>
        </div>
      )}
      <div className={`${addBgGrey && 'bg-grey-200'} full-page-hight py-2`}>
        <JobOverview
          jobPreviewDetail={
            jobDetails && Object.keys(jobDetails)?.length > 0
              ? jobDetails
              : Object.keys(jobDetailFromId)?.length > 2
              ? jobDetailFromId
              : storedJobDetail
          }
        />
        <JobDetail
          jobPreviewDetail={
            jobDetails && Object.keys(jobDetails)?.length > 0
              ? jobDetails
              : Object.keys(jobDetailFromId)?.length > 2
              ? jobDetailFromId
              : storedJobDetail
          }
        />
      </div>
    </>
  );
};

export default PreviewJob;
