import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DoctAutoCompleteWithChips } from '@docthub.frontend/app';

import {
  selectOrganizingOptions,
  selectValueOrganizingTeam,
  setIsFormDirty,
  setValueOrganizingTeam,
} from '../../slice/activityLog.slice';

import fieldAddEditActivityLog from '../field.AddEditActivityLog';
import { FormElWithHeading } from '../../../../../shared';
import { DoctTypography } from 'doct-core';

export default function OrganizingTeamActivityLog() {
  const dispatch = useDispatch();

  const organizingOptions = useSelector(selectOrganizingOptions); // data from API for Options
  const valueOrganizingTeam = useSelector(selectValueOrganizingTeam); // selected Value

  const handleOnChange = (val) => {
    dispatch(setValueOrganizingTeam(val)); // set selected value
    dispatch(setIsFormDirty(true));
  };

  return (
    <FormElWithHeading heading={fieldAddEditActivityLog.organizingTeam.label}>
      <DoctTypography variant="body2" className="mt-0 mb-2 text-grey-600">
        Search and Select from Members Directory.
      </DoctTypography>
      <DoctAutoCompleteWithChips
        textFieldProps={{ label: 'Search or Select' }}
        options={organizingOptions || []}
        value={
          organizingOptions?.filter(({ id }) =>
            valueOrganizingTeam?.find(({ id: valueId }) => id == valueId),
          ) || []
        }
        onChange={handleOnChange}
      />
    </FormElWithHeading>
  );
}
