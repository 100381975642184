import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
} from '@mui/material';

import { DoctButton, DoctTypography } from 'doct-core';
import { DoctActionMenu, DoctAnimationLoading } from '@docthub.frontend/app';
import { useState } from 'react';
import DropdownShareButton from '../../../../shared/ui/SocialShare/SocialShare';
import senNowIcon from '../../../../assets/images/eCertificate/Icon.png';
import APIIndividualJPEGDownload from '../../APIs/APIIndividualJPEGDownload.service';
import { setTrackPreviousUrl } from '../../slices/eCertificateSlice';
import { useDispatch, useSelector } from 'react-redux';
import { sendMailForAwardee } from '../../APIs/eCertificate';
import CustomToaster from '../../../../shared/ui/CustomToaster/CurstomToaster';
import dayjs from 'dayjs';

export default function AwardeeListingPage({
  awardeeData,
  pendingCount,
  certificateID,
  setAwardeeDataPageSize,
  awardeeDataPageSize,
  handleSendPendingCertificate,
  showToaster,
  setShowToaster,
  isSendPendingCertificateLoading,
}) {
  const [hoveredRow, setHoveredRow] = useState(null);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [sharePopupOpen, setSharePopupOpen] = useState(false);

  const handleRowMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleRowMouseLeave = () => {
    setHoveredRow(null);
  };

  const actionMenuOptions = [
    { title: 'Re-send Certificate' },
    // { title: 'Share Certificate' },
    { title: 'Download Certificate' },
  ];
  const { token } = useSelector((state) => state.app);

  React.useEffect(() => {
    dispatch(setTrackPreviousUrl(''));
  }, []);

  const handleUploadForMannual = (uploadType, id) => {
    setLoading(true);
    const response = sendMailForAwardee(certificateID, uploadType, token, id);
    response.then((res) => {
      setLoading(false);
      if (res?.ok) {
        setShowToaster(true);
      }
    });
  };

  return (
    <>
      {showToaster ? (
        <CustomToaster
          text={'Email sent successfully!'}
          handleRemoval={() => setShowToaster(false)}
          bgColor={'#4B4B4B'}
          top="80%"
          right="35%"
        />
      ) : (
        ''
      )}
      {loading && (
        <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
          <DoctAnimationLoading />
        </div>
      )}
      {!loading && (
        <div className="position-relative awardee-listing-page-height">
          <TableContainer
            component={Paper}
            className="awardee-tableContainer"
            style={{ maxHeight: '62vh', overflowY: 'scroll' }}
          >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow className="table-cell-text">
                  <TableCell>AWARDEE NAME</TableCell>
                  <TableCell align="start">DATE OF ISSUE</TableCell>
                  <TableCell align="start">EMAIL ADDRESS</TableCell>
                  <TableCell align="start">EMAIL STATUS</TableCell>
                  <TableCell align="start">ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {awardeeData.map((awardee, index) => (
                  <TableRow
                    key={awardee.id}
                    onMouseEnter={() => handleRowMouseEnter(index)}
                    onMouseLeave={handleRowMouseLeave}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      backgroundColor: hoveredRow === index ? '#E3E3E3' : 'inherit',
                    }}
                  >
                    <TableCell className="table-cell-list table-cell-name">
                      {awardee.name}
                    </TableCell>
                    <TableCell className="table-cell-list table-cell-date" align="start">
                      {dayjs(awardee.createdDate).format('D MMM YYYY')}
                    </TableCell>
                    <TableCell className="table-cell-list table-cell-date" align="start">
                      {awardee.email}
                    </TableCell>
                    <TableCell className={`table-cell-list table-cell-date`} align="start">
                      {awardee.emailStatus === 'Sent' && 'Sent'}
                      {awardee.emailStatus === 'Pending' && (
                        <div className="d-flex align-items-center">
                          <span style={{ color: '#FFB200', fontWeight: '600' }}> Pending </span>
                          <DoctButton
                            text="SEND NOW"
                            variant="outlined"
                            onButtonClickHandler={() =>
                              handleUploadForMannual('SendEmail', awardee.id)
                            }
                            className="assign-awardee-button ml-2"
                            size="small"
                          />
                        </div>
                      )}
                    </TableCell>
                    <TableCell className="table-cell-list" align="start">
                      <DoctActionMenu
                        key={index}
                        options={actionMenuOptions}
                        onClick={(actionMenuOptions) => {
                          if (actionMenuOptions?.title === 'Share Certificate') {
                            setSharePopupOpen(true);
                          }
                          if (actionMenuOptions?.title === 'Re-send Certificate') {
                            handleUploadForMannual('SendEmail', awardee.id);
                            // Handle share certificate action
                          }
                          if (actionMenuOptions?.title === 'Download Certificate') {
                            APIIndividualJPEGDownload(awardee.id, awardee.name);
                          }
                        }}
                        className="card_action_menu_options awardee-actionMenu-bg ml-auto"
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {pendingCount ? (
              <div
                style={{ position: 'absolute', bottom: 0, right: 0 }}
                className="send-now-bottom-list-body bg-primary-800 p-1"
              >
                <div className="d-flex align-items-center justify-content-between">
                  <DoctTypography
                    variant="subtitle1"
                    className="text-white send-now-bottom-digit m-0 d-flex justify-content-center align-items-center"
                  >
                    {pendingCount}
                  </DoctTypography>
                  <DoctTypography variant="textLabel2" className="text-white m-0">
                    Email Pending to
                    <br /> Send Certificates
                  </DoctTypography>
                  {isSendPendingCertificateLoading ? (
                    <div className="select-file-button send-now-text-body d-flex align-items-center justify-content-center">
                      <CircularProgress size={20} color="inherit" className="text-white" />
                    </div>
                  ) : (
                    <div onClick={handleSendPendingCertificate}>
                      <DoctTypography
                        variant="subtitle2"
                        className="text-white m-0 select-file-button d-flex align-items-center justify-content-center send-now-text-body cursor-pointer"
                      >
                        <img src={senNowIcon} alt="senNowIcon" className="mr-1" />
                        Send Now
                      </DoctTypography>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {sharePopupOpen && <DropdownShareButton setSharePopupOpen={setSharePopupOpen} />}
          </TableContainer>
          <div className="d-flex justify-content-center">
            {awardeeData.length < awardeeDataPageSize ? (
              ''
            ) : (
              <DoctButton
                onButtonClickHandler={() => {
                  setAwardeeDataPageSize((prevData) => prevData + 20);
                }}
                type="inverse"
                size="large"
                className="my-2"
                variant="contained"
                text="Load more"
                icon="down"
                iconPosition="right"
              />
            )}
          </div>
        </div>
      )}
    </>
  );
}
