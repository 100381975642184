import React from 'react';
import { Card, IconButton, Modal } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import { DoctTypography } from 'doct-core';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
  },
  card: {
    position: 'relative',
    boxSizing: 'border-box',
    height: '100vh !important',
    width: '100vw !important',
    background: 'transparent !important',
    overflowY: 'auto',
  },
  closeButton: { color: '#f0f0f0 !important', marginLeft: 'auto !important' },
  header: { color: '#f0f0f0 !important', textTransform: 'capitalize' },
});

export default function ViewPreviewCertificate({
  open,
  handleClose,
  certificateImg,
  certificateImgName,
}) {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose} className={`${classes.modal} modal-scrollbar`}>
      <Card className={`${classes.card}`}>
        <div className="demo-certificate-header d-flex justify-content-center">
          <DoctTypography
            variant="body2"
            className="font-weight-medium text-white demo-certificate-text ml-auto pl-5"
          >
            Preview Certificate :{certificateImgName}
          </DoctTypography>
          <IconButton onClick={handleClose} className={`${classes.closeButton} pr-5`}>
            <CloseIcon />
          </IconButton>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: certificateImg }}
          className="d-flex justify-content-center mt-5"
        />
      </Card>
    </Modal>
  );
}
