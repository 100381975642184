import { DoctAutoComplete } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React from 'react';

function PayoutCountryAndCurrency({ watch, formName }) {
  return (
    <div>
      <DoctTypography variant="h6" className="text-grey-800">
        Payout Country & Currency
      </DoctTypography>
      <DoctTypography variant="textLabel1" className="text-grey-600">
        Default country & currency for payment settlement.
      </DoctTypography>
      <DoctAutoComplete
        {...formName.payoutCountry}
        className="mb-3"
        options={[{ label: 'India', value: 'India' }]}
        validationRules={{
          required: "It's Required Field",
        }}
        value={
          typeof watch(formName.payoutCountry.name)?.value === 'string'
            ? watch(formName.payoutCountry.name)
            : null
        }
        onEndScroll={() => {}}
        onClearInput={() => {}}
        disabled
      />
      <DoctAutoComplete
        {...formName.currency}
        className="mb-3"
        options={[{ label: 'INR', value: 'INR' }]}
        validationRules={{
          required: "It's Required Field",
        }}
        value={
          typeof watch(formName.currency.name)?.value === 'string'
            ? watch(formName.currency.name)
            : null
        }
        onEndScroll={() => {}}
        onClearInput={() => {}}
        disabled
      />
    </div>
  );
}

export default PayoutCountryAndCurrency;
