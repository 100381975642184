import { DoctTypography } from 'doct-core';
import React, { useEffect } from 'react';
import { DoctActionMenu } from '@docthub.frontend/app';
import style from '../SubscriptionModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import useSendSubscription from '../hooks/useSendSubscription';
import * as ROUTE from '../../../routes/constant';
import { setBillingDetails } from '../API/subscriptionApiSlice';

const options = [
  {
    title: 'Edit',
  },
];

export default function BillingDetailsFilled({ setIsModalOpen, billingInfo }) {
  const { navigate } = useSendSubscription();
  const billingDetails = useSelector((state) => state?.subscriptionData?.billingDetails);
  const dispatch = useDispatch();
  useEffect(() => {
    if (Object.keys(billingDetails || {}).length <= 0) {
      dispatch(setBillingDetails(billingInfo));
    }
  }, [billingInfo, billingDetails]);
  return (
    <>
      <div
        className={`${style['billing-info-text-padding']} bg-grey-100 d-flex justify-content-between`}
      >
        <DoctTypography variant="textLabel1" className="text-grey-600 m-0 font-weight-medium">
          Billing Details
        </DoctTypography>
        <DoctActionMenu
          btnType="inverse"
          className="billing-detail-more-action-menu mt-n2 mr-n1 mb-n1"
          options={options}
          onClick={(item) => {
            if (item.title == 'Edit') {
              setIsModalOpen(true);
              navigate(
                `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/${billingInfo?.id}`,
              );
            }
          }}
        />
      </div>
      <div className={`${style['billing-detail-card-padding']} bg-white`}>
        <DoctTypography variant="textLabel1" className="text-grey-800 m-0 pb-1 font-weight-bold">
          Account Details
        </DoctTypography>
        <div className="d-flex mt-2">
          <DoctTypography
            variant="textLabel1"
            className={`${style['account-detail-width']} text-grey-600 m-0 font-weight-regular`}
          >
            Organization Name:
          </DoctTypography>
          <DoctTypography variant="textLabel1" className="text-grey-800 m-0 font-weight-regular">
            {billingDetails?.organizationName}
          </DoctTypography>
        </div>
        <div className="d-flex mt-2">
          {billingDetails?.billingName ? (
            <DoctTypography
              variant="textLabel1"
              className={`${style['account-detail-width']} text-grey-600 m-0 font-weight-regular`}
            >
              Billing Name:
            </DoctTypography>
          ) : (
            ''
          )}
          <DoctTypography variant="textLabel1" className="text-grey-800 m-0 font-weight-regular">
            {billingDetails?.billingName}
          </DoctTypography>
        </div>
        {billingDetails?.gstNo && (
          <div className="d-flex mt-2">
            <DoctTypography
              variant="textLabel1"
              className={`${style['account-detail-width']} text-grey-600 m-0 font-weight-regular`}
            >
              GSTIN:
            </DoctTypography>
            <DoctTypography variant="textLabel1" className="text-grey-800 m-0 font-weight-regular">
              {billingDetails?.gstNo}
            </DoctTypography>
          </div>
        )}
        {billingDetails?.taxId && (
          <div className="d-flex mt-2">
            <DoctTypography
              variant="textLabel1"
              className={`${style['account-detail-width']} text-grey-600 m-0 font-weight-regular`}
            >
              PAN Number:
            </DoctTypography>
            <DoctTypography variant="textLabel1" className="text-grey-800 m-0 font-weight-regular">
              {billingDetails?.taxId}
            </DoctTypography>
          </div>
        )}

        <div className="mt-3 mb-3">
          <div className={`${style['billing-info-line-divider']}`} />
        </div>
        <DoctTypography variant="textLabel1" className="text-grey-800 m-0 pb-1 font-weight-bold">
          Billing Address
        </DoctTypography>
        <div className="d-flex mt-2">
          <DoctTypography
            variant="textLabel1"
            className={`${style['billing-address-width']} text-grey-600 m-0 font-weight-regular`}
          >
            Address:
          </DoctTypography>
          <DoctTypography
            variant="textLabel1"
            className={`text-grey-800 m-0 font-weight-regular ${style['billing-address-width-truncate']}`}
          >
            {billingDetails?.address}
          </DoctTypography>
        </div>
        <div className="d-flex mt-2">
          <DoctTypography
            variant="textLabel1"
            className={`${style['billing-address-width']} text-grey-600 m-0 font-weight-regular`}
          >
            City:
          </DoctTypography>
          <DoctTypography variant="textLabel1" className="text-grey-800 m-0 font-weight-regular">
            {billingDetails?.city}
          </DoctTypography>
        </div>
        <div className="d-flex mt-2">
          <DoctTypography
            variant="textLabel1"
            className={`${style['billing-address-width']} text-grey-600 m-0 font-weight-regular`}
          >
            State:
          </DoctTypography>
          <DoctTypography variant="textLabel1" className="text-grey-800 m-0 font-weight-regular">
            {billingDetails?.state}
          </DoctTypography>
        </div>
        <div className="d-flex mt-2">
          <DoctTypography
            variant="textLabel1"
            className={`${style['billing-address-width']} text-grey-600 m-0 font-weight-regular`}
          >
            Country:
          </DoctTypography>
          <DoctTypography variant="textLabel1" className="text-grey-800 m-0 font-weight-regular">
            {billingDetails?.country}
          </DoctTypography>
        </div>
        {billingDetails?.pincode && (
          <div className="d-flex mt-2">
            <DoctTypography
              variant="textLabel1"
              className={`${style['billing-address-width']} text-grey-600 m-0 font-weight-regular`}
            >
              Postal Code:
            </DoctTypography>
            <DoctTypography variant="textLabel1" className="text-grey-800 m-0 font-weight-regular">
              {billingDetails?.pincode}
            </DoctTypography>
          </div>
        )}
      </div>
    </>
  );
}
