import { DoctModal } from '@docthub.frontend/app';
import { DoctButton, DoctTypography } from 'doct-core';
import React from 'react';
import '../EventsDashboard.scss';

const ManageCancellationOrder = ({ open, handleClose, onConfirmExit = () => {} }) => {
  return (
    <div className="disable_modal_outside_click">
      <DoctModal open={open} handleClose={handleClose} iconName="" showFooter={false}>
        <DoctTypography variant="subtitle1" className="mb-4">
          Are you sure want to cancel this order ?
        </DoctTypography>

        <DoctButton
          className="float-right mx-2 discard_btn "
          text="Yes"
          variant="contained"
          size="medium"
          onButtonClickHandler={onConfirmExit}
        />
        <DoctButton
          className="float-right save_draft_btn text-grey-600"
          text="No"
          variant="contained"
          size="medium"
          onButtonClickHandler={handleClose}
        />
      </DoctModal>
    </div>
  );
};

export default ManageCancellationOrder;
