import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import jwt_decode from 'jwt-decode';
import { AuthenticationContext } from '@axa-fr/react-oidc-context';
import { useSelector, useDispatch } from 'react-redux';
import { DoctAnimationLoading } from '@docthub.frontend/app';
import { appTokenState, setToken, setUser, useGetUserQuery } from '../components/App';
import applyAxiosConfig from '../helper/axiosConfig';
import { pageLoad } from '../helper/helperFunction';

export default function AuthWrapper({ children }) {
  const dispatch = useDispatch();
  const appToken = useSelector(appTokenState);

  const [appLoading, setAppLoading] = useState(true);

  const context = useContext(AuthenticationContext);
  const { oidcUser, isLoading, login } = context;

  const { data: userData, isError } = useGetUserQuery('', {
    skip: !appToken,
  });

  const navigateToBranding = useCallback(() => {
    window.location.href = process.env.REACT_APP_DOCTHUB_WEB_APP_LINK;
  }, []);

  useLayoutEffect(() => {
    // When This component loaded it will redirect user to login
    login(process.env.IDENTITY_ID);
  }, []);

  useLayoutEffect(() => {
    if (!oidcUser?.access_token) return;
    // tenantId and tenantType from userToken returned by OIDC
    const { tenantId, tenantType } = jwt_decode(oidcUser?.access_token);
    if (!tenantId && !tenantType) {
      // non enterprise user - redirect to branding page
      navigateToBranding();
      return;
    }
    applyAxiosConfig(oidcUser.access_token);
    dispatch(setToken(oidcUser.access_token));
  }, [oidcUser?.access_token, isLoading]);

  useEffect(() => {
    // set business user to application state and run application
    if (userData?.id) {
      dispatch(setUser(userData));
      setAppLoading(false);
      pageLoad(); // for remove loader from DOM
    }
  }, [userData?.id]);

  useEffect(() => {
    if (isError) navigateToBranding();
  }, [isError]);

  if (appLoading) {
    return <DoctAnimationLoading />;
  }

  return children;
}
