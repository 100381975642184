import React from 'react';
import { DoctTypography } from 'doct-core';

export default function TotalPriceCard({ text, amount, discountedAmount, totalAmount }) {
  return (
    <>
      <div
        className={`d-flex justify-content-between ${
          totalAmount && 'bg-white pl-4 pr-4 ml-n4 mr-n4'
        }`}
      >
        <DoctTypography
          variant={totalAmount ? 'subtitle2' : 'subtitle3'}
          className={`${
            totalAmount ? 'font-weight-bold' : 'm-0 mt-2 pt-1 mb-2 mb-1'
          } text-grey-800`}
        >
          {text}
        </DoctTypography>
        <DoctTypography
          variant="subtitle2"
          className={`${discountedAmount ? 'text-success' : 'text-grey-800'} ${
            totalAmount ? 'font-weight-bold' : 'm-0 mt-2 pt-1 mb-2 mb-1'
          }`}
        >
          {amount}
        </DoctTypography>
      </div>
    </>
  );
}
