import React from 'react';
import { DoctTypography } from 'doct-core';

function SmallQuickCardHighlighted({ title, children }) {
  return (
    <div className="bg-white small-quick_card_height border-radius p-1 mb-auto mt-3">
      <div className="d-flex justify-content-between align-items-center mb-n2 mt-n2">
        <DoctTypography variant="subtitle1" fontWeight="medium" className="text-grey-800 px-2">
          {title}
        </DoctTypography>
      </div>
      {children}
    </div>
  );
}

export default SmallQuickCardHighlighted;
