import React, { useEffect, useState } from 'react';
import { DoctAutoComplete, DoctTextField } from '@docthub.frontend/app';
import { DoctCol, DoctRow, DoctTypography } from 'doct-core';
import useSendSubscription from '../hooks/useSendSubscription';
import { useLocation } from 'react-router';
import { useGetUserQuery } from '../../../components';

/**
 * BillingInfo Component
 *
 * This component handles the display and management of billing information.
 *
 * It includes logic for:
 * - Populating state options for a country (e.g., India).
 * - Managing default state values and updating the UI based on location changes.
 * - Updating city options based on the selected state.
 * - Handling input fields for country, state, city, and postal code.
 * - Managing and validating address input.
 *
 * @param {Object} control - React Hook Form control object.
 * @param {Object} errors - Form errors object.
 * @param {Object} touched - Form touched object.
 * @param {Function} watch - React Hook Form watch function.
 * @param {Function} setValue - React Hook Form setValue function.
 */

const BillingInfo = ({ control, errors, touched, watch, setValue }) => {
  const [cities, setCities] = useState([]);
  const [defaultStateValue, setDefaultStateValue] = useState('');
  const [isStateHasNoValue, setIsStateHasNoValue] = useState(false);
  const { states, billingDetails } = useSendSubscription();

  const statesData = states
    ?.map((state) => state?.name) // Extract the 'name' property
    .filter((name, index, self) => self.indexOf(name) === index) // Filter unique values
    .map((uniqueName) => ({
      value: uniqueName,
      label: uniqueName,
    }));

  const { data: userData } = useGetUserQuery('');
  const watchState = watch('state');

  const location = useLocation();

  useEffect(() => {
    if (!billingDetails?.state) {
      setDefaultStateValue(userData?.state ?? '');
    } else if (billingDetails?.state) {
      setDefaultStateValue(billingDetails.state);
    } else {
      setDefaultStateValue([]);
      setIsStateHasNoValue(true);
    }
  }, [location, billingDetails?.state]);

  useEffect(() => {
    setValue('state', { label: defaultStateValue, value: defaultStateValue });
  }, [defaultStateValue]);

  useEffect(() => {
    if (!billingDetails?.state) {
      if (watchState?.value !== userData?.state) {
        setValue('city', '');
      } else {
        setValue('city', { label: userData?.city ?? '', value: userData?.city ?? '' });
      }
    } else if (watchState?.value !== billingDetails?.state) {
      setValue('city', '');
    }
  }, [watchState?.value, billingDetails?.state]);

  useEffect(() => {
    if (!billingDetails?.state) {
      setValue('pincode', userData?.pinCode ?? '');
      setValue('address', userData?.address ?? '');
    }
  }, [billingDetails?.state]);

  useEffect(() => {
    if (typeof watchState?.value === 'undefined') {
      setValue('city', '');
    }
  }, [watchState?.value]);

  // Add this useEffect to update city options when state changes
  useEffect(() => {
    // Get the selected state's cities
    const selectedState = states?.find((state) => state?.name === watchState?.value);
    const filteredCities = selectedState?.cities || [];

    // Filter unique city names
    const uniqueCityNames = [...new Set(filteredCities.map((city) => city?.name))];

    // Create an array of unique city objects
    const mappedCities = uniqueCityNames.map((uniqueCityName) => ({
      value: uniqueCityName,
      label: uniqueCityName,
    }));

    setCities(mappedCities);
  }, [watchState?.value]);

  return (
    <>
      <DoctRow>
        <DoctCol sm={6}>
          <DoctTypography variant="subtitle2">Country</DoctTypography>
          <DoctTextField
            control={control}
            isErrors={errors}
            id={'country'}
            name={'country'}
            label={'India'}
            variant="standard"
            validationRules={{}}
            disabled
          />
        </DoctCol>
        <DoctCol sm={6}>
          <DoctTypography variant="subtitle2">State/ Place of supply</DoctTypography>
          <DoctAutoComplete
            control={control}
            isErrors={errors}
            id={'state'}
            name={'state'}
            label={'Select'}
            options={statesData ?? []}
            validationRules={{
              required: "It's Required Field",
            }}
            variant="standard"
            value={watch('state') || null}
            onEndScroll={() => {}}
          />
          <DoctTypography
            variant="body3"
            className={`${
              isStateHasNoValue &&
              Object.keys(errors || {}).length &&
              errors?.city?.type === 'required'
                ? 'mt-1 text-danger'
                : 'd-none'
            }`}
          >
            {"It's Required Field"}
          </DoctTypography>
        </DoctCol>
      </DoctRow>

      <DoctRow>
        <DoctCol sm={6}>
          <DoctTypography variant="subtitle2">City</DoctTypography>
          <DoctAutoComplete
            control={control}
            isErrors={errors}
            id={'city'}
            name={'city'}
            label={'Select'}
            options={cities ?? []}
            validationRules={{
              required: "It's Required Field",
            }}
            value={watch('city') || null}
            variant="standard"
            onEndScroll={() => {}}
          />
        </DoctCol>
        <DoctCol sm={6}>
          {location.pathname.includes('billing-info-recruiter') ? (
            <DoctTypography variant="subtitle2">Postal Code</DoctTypography>
          ) : (
            <DoctTypography variant="subtitle2">Postal Code (optional)</DoctTypography>
          )}

          <form noValidate>
            <DoctTextField
              control={control}
              isErrors={errors}
              id={'pincode'}
              name={'pincode'}
              label={'Enter code'}
              showStar={false}
              validationRules={{
                required: location.pathname.includes('billing-info-recruiter')
                  ? `It's Required Field`
                  : '',
                pattern: {
                  value: /^([1-9])([0-9]){2}(\s)?([0-9]){3}$/,
                  message: 'Please enter a valid Indian postal code',
                },
                minLength: {
                  value: 6,
                  message: 'Postal code is not valid',
                },
                maxLength: {
                  value: 6,
                  message: 'Postal code is not valid',
                },
              }}
              variant="standard"
            />
          </form>
        </DoctCol>
      </DoctRow>

      <div className="input-column">
        <div className="d-flex align-items-center justify-content-between">
          <DoctTypography variant="subtitle2">Address</DoctTypography>
        </div>
        <DoctRow>
          <DoctCol sm={12}>
            <DoctTextField
              className="mb-2"
              name="address"
              label="Line 1"
              id="address"
              control={control}
              isErrors={errors}
              defaultValue=""
              touched={touched}
              showStar={false}
              validationRules={{
                required: "It's Required Field",
                pattern: {
                  value: /^(?!\s*$).+/,
                  message: 'Spaces are not allowed',
                },
              }}
            />
          </DoctCol>
        </DoctRow>
      </div>
    </>
  );
};

export default BillingInfo;
