import { DoctTypography } from 'doct-core';
import React from 'react';

export default function AssignViaCommonCard({ title, subtitle, children, className }) {
  return (
    <div className={`${className} mr-3`}>
      <DoctTypography
        variant="subtitle2"
        className="assign-via-chip text-white bg-primary-800 p-1 m-0"
      >
        Assign via
      </DoctTypography>
      <div className="assign-via-card">
        <DoctTypography variant="h6" className="text-grey-800">
          {title}
        </DoctTypography>
        {subtitle && (
          <DoctTypography variant="textLabel1" className="text-grey-600 mt-1 m-0">
            {subtitle}
          </DoctTypography>
        )}
        {children}
      </div>
    </div>
  );
}
