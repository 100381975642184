import React, { useEffect, useState } from 'react';
import { DoctButton, DoctIcon, DoctTypography } from 'doct-core';
import * as ROUTE from '../../../routes/constant';
import { useNavigate } from 'react-router';
import style from '../SubscriptionModal.module.scss';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import INRAmount from './INRAmount';
import { encryptData } from '../../../helper/helperFunction';
import { useDispatch } from 'react-redux';
import { setChoosedPlan } from '../API/subscriptionApiSlice';
import { customBundle } from '../helper/getJobUsage';
import UnlimitedChip from '../CommonChip/UnlimitedChip';

const PakageBoxRecruiter = (props) => {
  const [selectSub, setSelectSub] = useState(props?.subscriptionBundle[0]);
  const [unlimitedChipText, setUnlimitedChipText] = useState(props?.subscriptionBundle[0]);
  const [inputValue, setInputValue] = useState();
  const [customInputError, setCustomInputError] = useState();
  const [maxValue, setMaxValue] = useState();
  const [customePlans, setCustomePlans] = useState();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const storePlanId = () => {
    const planData = {
      ['id']: props?.id,
      ['planName']: props?.name,
      ['bundleId']: selectSub?.id,
      ['bundleName']: selectSub?.name,
      ['totalAmount']: customePlans ? customePlans?.totalAmount : selectSub?.totalAmount,
      ['saveUpTo']: selectSub?.saveUpTo,
    };
    dispatch(setChoosedPlan(planData));
    const encryptedData = encryptData(planData);
    localStorage.setItem('encryptedSubscriptionPlanData', encryptedData);
  };

  const handleChange = (text, plans) => {
    let numPlan = [];
    plans.map((e) => {
      if (e.numberOfJob !== null) {
        numPlan.push(e.numberOfJob);
      }
    });

    let maxValue = 0;

    if (numPlan.length > 0) {
      maxValue = Math.max(...numPlan);
    }

    setMaxValue(maxValue);
    const numericValue = text.target.value.replace(/[^0-9]/g, '');

    if (maxValue < numericValue) {
      setInputValue(numericValue);
      setCustomInputError('');
    } else {
      setInputValue(numericValue);
      setCustomInputError(`Add a value greater than ${maxValue}`);
    }
  };

  const CustomBundle = async () => {
    const res = await customBundle(inputValue, selectSub?.id);
    setCustomePlans(res?.data);
  };

  const handleRadioChanges = (subs) => {
    setSelectSub(subs);
    setUnlimitedChipText(subs);
    setInputValue('');
    setCustomePlans();
  };
  useEffect(() => {
    const handler = setTimeout(() => {
      if (inputValue > maxValue) {
        CustomBundle();
      } else {
        setCustomePlans();
      }
    }, 450);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  return (
    <>
      <div
        className={`${style['package-box-body']} ${
          name === 'Institute Premium'
            ? `bg-info text-white`
            : `bg-white text-grey-800 ${style['package-card-border']}`
        } position-relative`}
      >
        <DoctTypography
          variant="h5"
          className="text-center m-3 font-weight-bold text-truncate-two-lines"
        >
          {props?.name}
        </DoctTypography>
        {props?.subscriptionBundle?.map((subs) => (
          <>
            <div className={`${style['divider-dark-grey']}`} />

            <div className="d-flex align-items-center justify-content-between">
              <div key={subs.id} className="py-1">
                <DoctTypography
                  variant="subtitle1"
                  className="px-3 font-weight-medium text-grey-800 text-truncate"
                >
                  {subs.name}
                </DoctTypography>
              </div>

              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={selectSub?.id === subs.id ? selectSub.id : ''}
                  name="selectSubscription"
                >
                  <DoctTypography
                    variant="textLabel2"
                    className={`text-grey-400 m-0 ${style['validity-text']}`}
                  >
                    Validity
                  </DoctTypography>
                  <FormControlLabel
                    className={`${style['validity-month-text']} btn-hover-remover flex-row-reverse mr-1`}
                    value={subs?.id}
                    label={subs?.jobValidity ? `${subs?.jobValidity} Days` : '0 Month'}
                    control={
                      <Radio
                        className="mt-n4"
                        onClick={() => {
                          handleRadioChanges(subs);
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {subs.type === 'Custom' && selectSub.id === subs.id && (
              <>
                {/* NOte:- remove bellow condition as per Jignesh sir requirement */}
                {/* {!customePlans && ( */}
                <div className="d-flex justify-content-center">
                  <TextField
                    size="small"
                    variant="outlined"
                    label=""
                    onChange={(e) => handleChange(e, props?.subscriptionBundle)}
                    value={inputValue === '0' ? '' : inputValue}
                    keyboardType="numeric"
                    helperText={
                      customInputError ? (
                        <span style={{ color: 'red' }}>{customInputError}</span>
                      ) : (
                        <span style={{ color: 'red' }}>Enter only numbers</span>
                      )
                    }
                    error={!!customInputError}
                  />
                </div>
                {/* )} */}
              </>
            )}
          </>
        ))}
        <div className={`${style['divider-dark-grey']}`} />

        {unlimitedChipText?.type !== 'Custom' && (
          <div className="d-flex align-items-center mb-2 pb-1 mt-3">
            <DoctIcon width="15" height="15" name="check" fill="#707070" className="ml-3 mr-2" />
            <UnlimitedChip
              blackChipText={
                unlimitedChipText?.jobMode === 'Limited'
                  ? unlimitedChipText?.numberOfJob
                  : 'Unlimited'
              }
            />
            <DoctTypography variant="subtitle2" className="m-0">
              Job Listing
            </DoctTypography>
          </div>
        )}

        <div
          className={`d-flex align-items-center mb-2 pb-1 ${
            unlimitedChipText?.type === 'Custom' && 'mt-3'
          }`}
        >
          <DoctIcon width="15" height="15" name="check" fill="#707070" className="ml-3 mr-2" />
          <UnlimitedChip
            blackChipText={
              unlimitedChipText?.jobApplicantMode === 'Limited'
                ? unlimitedChipText?.numberOfApplicants
                : 'Unlimited'
            }
          />
          <DoctTypography variant="subtitle2" className="m-0">
            Job Applicants Views
          </DoctTypography>
        </div>

        <div className="text-grey-800 m-0 ml-4 mt-3">
          <div
            className={`${style['figure-media-margin']} m-0 ck-editor-p-margin mb-2`}
            dangerouslySetInnerHTML={{
              __html: props?.otherFeatures,
            }}
          />
        </div>

        <div className="ml-3">
          {/* {customePlans ? (
            <INRAmount
              isGSTApplicable={customePlans?.isGSTApplicable}
              subscriptionChipText={
                customePlans?.saveUpTo && `UP TO ${customePlans?.discountPercent}% OFF`
              }
              MainAmount={customePlans?.netAmount ? customePlans?.netAmount : false}
              DiscountedAmount={customePlans?.totalAmount ? customePlans?.totalAmount : '000'}
              className={customePlans?.saveUpTo ? 'd-flex mt-3' : 'd-none'}
              textGrey={true}
            />
          ) : ( */}
          <INRAmount
            isGSTApplicable={
              customePlans ? customePlans?.isGSTApplicable : selectSub?.isGSTApplicable
            }
            subscriptionChipText={
              customePlans
                ? customePlans?.discountPercent > 0
                  ? customePlans?.saveUpTo
                    ? `Save up to ${customePlans?.discountPercent} % OFF`
                    : `${customePlans?.discountPercent} % OFF`
                  : ''
                : selectSub?.discountPercent > 0
                ? selectSub?.saveUpTo
                  ? `Save up to ${selectSub?.discountPercent} % OFF`
                  : `${selectSub?.discountPercent} % OFF`
                : ''
            }
            MainAmount={
              customePlans
                ? customePlans?.netAmount
                  ? customePlans?.netAmount
                  : false
                : selectSub?.netAmount
                ? selectSub?.netAmount
                : false
            }
            DiscountedAmount={
              customePlans
                ? customePlans?.totalAmount
                  ? customePlans?.totalAmount
                  : '000'
                : selectSub?.totalAmount
                ? selectSub?.totalAmount
                : '000'
            }
            className={
              customePlans
                ? customePlans?.discountPercent
                  ? 'd-flex mt-3'
                  : 'd-none'
                : selectSub?.discountPercent
                ? 'd-flex mt-3'
                : 'd-none'
            }
            textGrey={true}
          />
          {/* )} */}
        </div>

        <DoctButton
          onButtonClickHandler={() => {
            storePlanId();
            navigate(
              `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO_RECRUITER}/rBundleId?=${selectSub?.id}`,
              {
                state: { ['planId']: props.id, ['bundleId']: selectSub?.id },
              },
            );
          }}
          text="Choose Plan"
          type="primary"
          className="ml-3 mb-4"
          disabled={(selectSub?.type === 'Custom' && !inputValue) || !!customInputError}
        />
      </div>
    </>
  );
};

export default PakageBoxRecruiter;
