import React, { useContext, useRef } from 'react';
import { DoctContainer, DoctTypography } from 'doct-core';
import brandLogo from '../../assets/icons/dashboard_header_logo.svg';
import DefaultProfilePicture from '../../assets/images/topbar/default-profile.png';
import { AuthenticationContext } from '@axa-fr/react-oidc-context';
import { useGetUserQuery } from '../../components';
import useDetectRoutes from '../hooks/useDetectRoutes';
import './Header.scss';
import ProfileDropdown from './ProfileDropdown/ProfileDropdown';
import { useOnClickOutside } from '../../hooks';
import { clearCookieFromServer } from '../../helper/helperFunction';

const LayoutTopBar = () => {
  const [isDrawerOpen, SetIsDrawerOpen] = React.useState(false);
  const { result } = useDetectRoutes();
  const context = useContext(AuthenticationContext);
  const { logout } = context;

  const { data: user } = useGetUserQuery('');

  const { tenant } = user || {};
  const { organizationLogoUrl } = tenant || {};

  const handleAvatarClick = () => {
    SetIsDrawerOpen(true);
  };

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => SetIsDrawerOpen(false));

  return (
    <>
      <div
        className="business-top-bar business-top-bar-sticky"
        style={{ zIndex: isDrawerOpen ? '999999' : 1 }}
      >
        <DoctContainer>
          <div className="d-flex align-items-center">
            <img src={brandLogo} alt="logo" />
            <div className="d-flex ml-auto position-relative">
              <div className="d-flex flex-column text-right">
                <DoctTypography variant="subtitle2" className="my-0">
                  {result?.label ? result?.label : 'Enterprise Support'}
                </DoctTypography>
                <DoctTypography variant="subtitle3" className="my-0">
                  <div className="d-flex text-nowrap">
                    {result?.email ? (
                      <>
                        <a href={`mailto:${result.email}`} className="text-grey-600">
                          {result.email}
                        </a>{' '}
                        &nbsp; | &nbsp;
                        <a
                          href={`tel:${result.mobile?.replace(/ +/g, '')}`}
                          className="text-grey-600"
                        >
                          {result.mobile}
                        </a>
                      </>
                    ) : (
                      <>
                        <a href={`mailto:enterprise@docthub.com`} className="text-grey-600">
                          enterprise@docthub.com
                        </a>{' '}
                        &nbsp; | &nbsp;
                        <a href={`tel:+919090902626`} className="text-grey-600">
                          +91 9090902626
                        </a>
                      </>
                    )}
                  </div>
                </DoctTypography>
              </div>
              <span
                className="business-top-bar-profile-photo-wrapper ml-4 cursor-pointer"
                onClick={handleAvatarClick}
              >
                <img src={organizationLogoUrl || DefaultProfilePicture} alt="user profile photo" />
              </span>
              {isDrawerOpen && (
                <ProfileDropdown
                  ref={dropdownRef}
                  user={user}
                  onMenuItemClick={(title) => {
                    if (title == 'Log out') {
                      // TODO: for temp testing
                      clearCookieFromServer();
                      logout();
                      return;
                    }
                    SetIsDrawerOpen(false);
                  }}
                />
              )}
            </div>
          </div>
        </DoctContainer>
      </div>

      {/* <Drawer SetIsDrawerOpen={SetIsDrawerOpen} isDrawerOpen={isDrawerOpen}>
        <UserMenu user={user} SetIsDrawerOpen={SetIsDrawerOpen} logout={logout} />
      </Drawer> */}
    </>
  );
};
export default LayoutTopBar;
