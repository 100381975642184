import React from 'react';
import { DoctTypography } from 'doct-core';

import InfoIconWithTooltip from '../InfoIconWithTooltip';
import { LightTooltip, ProfileDataBox } from '../../../../../shared';

import organizationPlaceholder from '../../../../../assets/images/settings/placeholder/organizations.svg';

import style from '../../../Settings.module.scss';
import { useGetUserQuery } from '../../../../../components';

function ToolTip() {
  return (
    <LightTooltip
      title={
        <span>
          To make changes in organization name, profile picture, you need to contact
          <b> Enterprise Support.</b>
        </span>
      }
      arrow
    >
      <InfoIconWithTooltip />
    </LightTooltip>
  );
}

function ImageAndData({ img, data }) {
  return (
    <div className="d-flex align-items-center">
      <div className={`${style['organization-profile-thumbnail']}`}>
        <img src={img} alt="org thumb" width={'60px'} height={'60px'} loading="lazy" />
      </div>
      <div className="d-flex flex-column">
        {data.map((obj) => {
          return Object.entries(obj).map(([key, value]) => {
            return (
              <div className="my-0 d-flex flex-column" key={key}>
                <DoctTypography
                  variant="textLabel1"
                  className={`mr-4 text-grey-600 my-0 ${style['organization-profile-text-min-width-7x']}`}
                >
                  {key}
                </DoctTypography>
                <DoctTypography variant="textLabel1" className="my-0">
                  {value}
                </DoctTypography>
              </div>
            );
          });
        })}
      </div>
    </div>
  );
}

export default function OrganizationProfile() {
  const { data: userData } = useGetUserQuery('');

  const { tenant: { organizationName } = {} } = userData || {};

  return (
    <ProfileDataBox heading={'Organization'} actionMenu={<ToolTip />}>
      <ImageAndData
        img={organizationPlaceholder}
        data={[
          {
            ['Organization Name']: organizationName,
          },
        ]}
      />
    </ProfileDataBox>
  );
}
