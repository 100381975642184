import React, { useContext, useLayoutEffect, useState } from 'react';
import { DoctTypography, DoctContainer, DoctLogoEnterprise, DoctIcon, DoctButton } from 'doct-core';
import { DoctPrivacyPolicy, DoctTermsAndCondition, DoctPaymentPolicy } from '@docthub.frontend/app';
import BeforeLoginImg from '../../../src/assets/images/before-login-page-img/beforeLoginPage.webp';
import style from './BeforeLoginPage.module.scss';
import { AuthenticationContext } from '@axa-fr/react-oidc-context';
import { setToken } from '../../components';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import * as ROUTE from '../../routes/constant';
import PageSEO from '../../shared/SEO/PageSEO';

export default function BeforeLoginPage() {
  const dispatch = useDispatch();
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [, setIsBusinessUser] = useState(true);

  const context = useContext(AuthenticationContext);
  const { oidcUser, login } = context;

  useLayoutEffect(() => {
    if (!oidcUser?.access_token) return;
    const { tenantId, tenantType } = jwt_decode(oidcUser?.access_token);
    if (tenantId && tenantType) {
      window.location.pathname = `${ROUTE.DASHBOARD}`;
      setIsBusinessUser(true);
    } else {
      dispatch(setToken(oidcUser.access_token));
      setIsBusinessUser(false);
      window.location.href = `${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}`;
    }
  }, [oidcUser?.access_token]);

  const handleLogin = () => {
    login(process.env.IDENTITY_ID);
  };
  return (
    <>
      <PageSEO
        title={'Docthub Enterprise Login | Sign In '}
        description={
          'Login Docthub enterprise account and access dashboard to post Jobs, publish courses, create events and use membership management dashboard. '
        }
        canonicalUrl={`${process.env.REACT_APP_ENTERPRISE_APP_LINK}`}
      />
      <DoctContainer>
        <DoctTermsAndCondition open={termsModalOpen} handleClose={() => setTermsModalOpen(false)} />
        <DoctPrivacyPolicy open={privacyModalOpen} handleClose={() => setPrivacyModalOpen(false)} />
        <DoctPaymentPolicy open={paymentModalOpen} handleClose={() => setPaymentModalOpen(false)} />
        <DoctPrivacyPolicy />
        <div className={`d-flex flex-column ${style['page-container']}`}>
          <div className="d-md-flex align-items-center justify-content-between p-3 p-md-4 mx-md-n4 text-center text-md-left">
            <a href={`${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}/enterprise-solutions`}>
              <DoctLogoEnterprise />
            </a>
            <div className="d-none d-md-flex">
              <DoctTypography variant="subtitle3" className="text-grey-600 m-0">
                Contact Support:
              </DoctTypography>
              <DoctTypography variant="textLabel2" className="text-grey-600 m-0">
                &nbsp; enterprise@docthub.com &nbsp; &#124; &nbsp; +91 9090902626
              </DoctTypography>
            </div>
          </div>
          <DoctTypography
            variant="textLabel1"
            className={`text-info d-md-none m-0 p-3 font-weight-medium ${style['login-text-card']}`}
          >
            Enterprise Dashboard experience is designed for desktop use, please switch to laptop or
            pc to access all features.
          </DoctTypography>
          <div
            className={`d-flex justify-content-center align-items-center ${style['centered-container']}`}
          >
            <DoctContainer>
              <div className={`${style['before-login-page-border']} m-n3 m-sm-0`}>
                <div className={`${style['doctIcon-padding']} d-flex justify-content-center`}>
                  <DoctIcon
                    name="courseEnterpriseFilled"
                    width="44"
                    height="44"
                    className={`mr-2 pr-md-1 ${style['doctIcon-size-responsive']}`}
                  />
                  <DoctIcon
                    name="jobsEnterpriseFilled"
                    width="44"
                    height="44"
                    className={`mr-2 pr-md-1 ${style['doctIcon-size-responsive']}`}
                  />
                  <DoctIcon
                    name="eventEnterpriseFilled"
                    width="44"
                    height="44"
                    className={`mr-2 pr-md-1 ${style['doctIcon-size-responsive']}`}
                  />
                  <DoctIcon
                    name="membershipOutline"
                    width="44"
                    height="44"
                    className={`${style['doctIcon-size-responsive']}`}
                  />
                </div>
                <DoctTypography
                  variant="h3"
                  className={`${style['before-loginPage-title']} text-primary-800 text-center`}
                >
                  Welcome to your <br />
                  Docthub Enterprise Account
                </DoctTypography>
                <div className={`d-flex justify-content-center ${style['login-button-padding']}`}>
                  <DoctButton
                    text="Login"
                    variant="contained"
                    onButtonClickHandler={handleLogin}
                    className={`${style['login-button-inner-padding']} mr-2 bg-primary-800`}
                  />
                  <DoctButton
                    text="Learn More"
                    variant="outlined"
                    onButtonClickHandler={() => {
                      window.location.href = `${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}/enterprise-solutions`;
                    }}
                    className={`${style['learn-more-button-border']} ml-md-1 px-4`}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  <img
                    src={BeforeLoginImg}
                    alt="BeforeLoginImg"
                    height={207}
                    width={965}
                    className="d-none d-md-block"
                  />
                </div>
              </div>
            </DoctContainer>
          </div>
          <div
            className={`d-md-flex align-items-center justify-content-between ${style['footer-padding']}`}
          >
            <div className="d-md-none text-center">
              <DoctTypography variant="subtitle3" className="text-grey-600 m-0 mb-1">
                Contact Support:
              </DoctTypography>
              <DoctTypography variant="textLabel2" className="text-grey-600 m-0">
                &nbsp; enterprise@docthub.com &nbsp; &#124; &nbsp; +91 9090902626
              </DoctTypography>
            </div>
            <DoctTypography variant="textLabel2" className="d-none d-md-block text-grey-400 m-0">
              Copyright © {new Date().getFullYear()} Docthub. All rights reserved.
            </DoctTypography>
            <div className={`d-md-none my-3 ${style['footer-divider-responsive']}`} />
            <div className={`d-md-flex align-items-center justify-content-center`}>
              <div className="d-flex mb-2 pb-1 mb-md-0 pb-md-0 justify-content-center">
                <a href={`${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}`} rel="noreferrer">
                  <DoctTypography
                    variant="textLabel2"
                    className="cursor-pointer text-grey-600 mr-3 mr-md-4 pr-1 m-0"
                  >
                    Docthub Home
                  </DoctTypography>
                </a>
                <a href={`${process.env.REACT_APP_DOCTHUB_WEB_APP_LINK}/about-us`} rel="noreferrer">
                  <DoctTypography
                    variant="textLabel2"
                    className="cursor-pointer text-grey-600 mr-md-4 pr-md-1 m-0"
                  >
                    About Docthub
                  </DoctTypography>
                </a>
              </div>

              <div className="d-flex justify-content-center">
                <a href={`/enterprise-terms-conditions`} target="_blank" rel="noreferrer">
                  <DoctTypography
                    variant="textLabel2"
                    className="text-grey-600 mr-3 mr-md-4 pr-1 m-0 cursor-pointer"
                  >
                    Terms & Conditions
                  </DoctTypography>
                </a>
                <a href={`/enterprise-privacy-policy`} target="_blank" rel="noreferrer">
                  <DoctTypography
                    variant="textLabel2"
                    className="text-grey-600 mr-3 mr-md-4 pr-1 m-0 cursor-pointer"
                  >
                    Privacy Policy
                  </DoctTypography>
                </a>
                <a href={`/enterprise-payment-policy`} target="_blank" rel="noreferrer">
                  <DoctTypography
                    variant="textLabel2"
                    className="text-grey-600 mr-3 mr-md-4 pr-1 m-0 cursor-pointer"
                  >
                    Payment Policy
                  </DoctTypography>
                </a>
                <a href={`/enterprise-disclaimer-policy`} target="_blank" rel="noreferrer">
                  <DoctTypography
                    variant="textLabel2"
                    className="cursor-pointer text-grey-600 m-0 "
                  >
                    Disclaimer Policy
                  </DoctTypography>
                </a>
              </div>
            </div>
            <DoctTypography
              variant="textLabel2"
              className="text-center d-md-none text-grey-400 m-3"
            >
              Copyright © {new Date().getFullYear()} Docthub. All rights reserved.
            </DoctTypography>
          </div>
        </div>
      </DoctContainer>
    </>
  );
}
