import React, { useMemo, useState } from 'react';
import { DoctButton, DoctIcon, DoctTypography } from 'doct-core';
import EventIcon from '@material-ui/icons/Event';
import dayjs from 'dayjs';
import useEventCardStatus from '../hooks/useEventCardStatus';
import PropTypes from 'prop-types';
import EventPlaceholderImage from '../../../../assets/images/event-placeholder.png';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD, EVENTS } from '../../../../routes/constant';
import RejectionReasonModal from '../../../InstituteDashboard/Courses/components/RejectionReasonModal/RejectionReasonModal';
import './Card.Event.scss';
import { DoctModal } from '@docthub.frontend/app';
import IconTicketColor from '../../../../assets/images/Create Events Form/Tickets/IconTicketColor.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { EventStatus, NUMBER_OF_GATHERING } from '../../../../helper/enums/eventStatus';

function BadgeWithAction({ labelClass, labelTextColor, labelText }) {
  return (
    <span className={`${labelClass} manage_event_card_label position-relative`}>
      <DoctTypography
        variant="textLabel2"
        className={`${labelTextColor} font-weight-medium my-0 d-inline-flex align-items-center`}
      >
        {labelText} <DoctIcon width="16" height="16" name="down" className="" />
      </DoctTypography>
      <span className="position-absolute card_dropdown_menu flex-column">
        <span className="card_dropdown_menu-item w-100 cursor-pointer d-inline-flex">
          <DoctTypography variant="body2" className="my-0 text-capitalize">
            Active
          </DoctTypography>
          <DoctIcon width="20" height="20" name="check" className="text-grey-500 ml-auto" />
        </span>
        <span className="card_dropdown_menu-item w-100 cursor-pointer">
          <DoctTypography variant="body2" className="my-0 text-capitalize">
            Pause Online Registration
          </DoctTypography>
        </span>
      </span>
    </span>
  );
}
BadgeWithAction.propTypes = {
  labelClass: PropTypes.string,
  labelTextColor: PropTypes.string,
  labelText: PropTypes.string,
};

export default function CardEvent({
  type = 'Workshop',
  status,
  name,
  endDate,
  image: { coverImageUrl } = {},
  id,
  rejectionReason,
  code,
  availableTicket,
  maxAttendees,
  startDate,
  modifiedDate,
  numberOfGathering,
}) {
  const {
    eventStatusData: {
      className,
      btnText,
      labelClass,
      labelTextColor,
      labelText,
      isButtonDisabled,
      btnVariant,
    },
  } = useEventCardStatus(status);
  const [openRejectionReasonModal, setOpenRejectionReasonModal] = useState(false);
  const [openSeatsInfoModal, setOpenSeatsInfoModal] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={`manage_event_card white-paper overflow-hidden d-inline-block ${className}`}>
      <span className="manage_event_card__header bg-grey-100 w-100 d-inline-flex justify-content-between align-items-center px-12px py-2 text-uppercase">
        <DoctTypography variant="overline" className="text-grey-600">
          {type}
        </DoctTypography>
        {/* {status == EventStatus.ACTIVE && (
          <BadgeWithAction
            labelClass={labelClass}
            labelTextColor={labelTextColor}
            labelText={labelText}
          />
        )} */}
        {/* {status != EventStatus.ACTIVE && (
          <span className={labelClass}>
            <DoctTypography
              variant="textLabel2"
              className={`${labelTextColor} font-weight-medium my-0`}
            >
              {labelText}
            </DoctTypography>
          </span>
        )} */}
        <span className={labelClass}>
          <DoctTypography
            variant="textLabel2"
            className={`${labelTextColor} font-weight-medium my-0`}
          >
            {labelText}
          </DoctTypography>
        </span>
      </span>
      <DoctTypography
        variant="subtitle2"
        className="manage_event_card_title my-0 px-3 pt-12px pb-1"
      >
        {name}
      </DoctTypography>
      <p className="manage_event_card_date d-inline-flex align-items-center pb-12px px-12px mb-0 text-grey-600 mt-0">
        <EventIcon className="vertical-align-sub icon-18" />
        <DoctTypography variant="subtitle2" className="my-0 pl-1">
          {dayjs(startDate).format('DD MMM YYYY')} - {dayjs(endDate).format('DD MMM YYYY')}
        </DoctTypography>
      </p>
      <div className="manage_event_card-image-wrapper">
        <img src={coverImageUrl || EventPlaceholderImage} alt={name} />
      </div>
      {/* ======= seats info ===== */}
      {/* {status !== EventStatus.ACTIVATIONREQUESTED && ( */}
      <InfoBar
        setOpenSeatsInfoModal={setOpenSeatsInfoModal}
        status={status}
        availableTicket={availableTicket}
        maxAttendees={maxAttendees}
        startDate={startDate}
        modifiedDate={modifiedDate}
        numberOfGathering={numberOfGathering}
      />
      {/* )} */}
      {/* ============ */}
      <div className="line-divider bg-grey-200 mx-3" />
      {status == 'Rejected' && (
        <DoctButton
          size="medium"
          variant="text"
          type={`semantic-danger`}
          text="Learn How ?"
          className="mx-auto"
          disabled={isButtonDisabled}
          onButtonClickHandler={() => setOpenRejectionReasonModal(true)}
        />
      )}
      <RejectionReasonModal
        openRejectionReasonModal={openRejectionReasonModal}
        handleClose={() => setOpenRejectionReasonModal(false)}
        rejectionReason={rejectionReason}
        handlePrimaryButtonClick={() => setOpenRejectionReasonModal(false)}
        enterpriseModule="events"
      />
      {status != 'Completed' && (
        <DoctButton
          size="medium"
          variant="text"
          type={btnVariant}
          text={btnText}
          className="mx-auto"
          disabled={isButtonDisabled}
          onButtonClickHandler={() => {
            navigate(`/${DASHBOARD}/${EVENTS}/${id}/edit`);
          }}
        />
      )}
      <DoctButton
        size="medium"
        variant="contained"
        type={`primary`}
        text="View Event"
        className="mx-auto mb-2"
        disabled={
          isButtonDisabled ||
          status === EventStatus.ACTIVATIONREQUESTED ||
          status === EventStatus.DRAFT
          // status === EventStatus.COMPLETED
        }
        onButtonClickHandler={() =>
          window.open(`${process.env.REACT_APP_EVENT_URL}/${code}`, '_blank', 'noopener,noreferrer')
        }
      />
      {/* ============== */}
      <RemainingInfoPopup
        open={openSeatsInfoModal}
        handleClose={() => setOpenSeatsInfoModal(false)}
        availableTicket={(availableTicket || []).length > 0 ? availableTicket : []}
      />
    </div>
  );
}

const InfoBar = ({
  setOpenSeatsInfoModal,
  status,
  availableTicket = [],
  maxAttendees,
  startDate = '',
  modifiedDate = '',
  numberOfGathering,
}) => {
  const totalRemainingSeats = useMemo(() => {
    return availableTicket.length > 0
      ? availableTicket.reduce((acc, curr) => acc + curr.remainingSeats, 0)
      : 0;
  }, [availableTicket]);
  const isLimitedGathering = numberOfGathering === NUMBER_OF_GATHERING.LIMITED;
  return (
    <div className="_info d-flex align-items-center justify-content-between px-12px">
      {/* ======== left side ======== */}
      {status === EventStatus.DRAFT ? (
        <span className="text-grey-500">Created: {dayjs(startDate).format('DD MMM YYYY')}</span>
      ) : (
        <span className="text-grey-500"></span>
      )}
      {/* ======== right side ======== */}
      <div
        onClick={() => {
          totalRemainingSeats > 0 &&
          (status === EventStatus.ACTIVE || status === EventStatus.CLOSED)
            ? setOpenSeatsInfoModal(true)
            : null;
        }}
        className="manage_event_card_seats_info text-grey-500 d-flex align-items-center justify-content-between cursor-pointer"
      >
        {status === EventStatus.ACTIVE && isLimitedGathering && (
          <>
            <span>
              {`Registration limit: ${maxAttendees} • ${
                totalRemainingSeats > 0 ? 'Remaining: ' + totalRemainingSeats : 'Registration full'
              }`}
            </span>
            <span>
              <KeyboardArrowRightIcon />
            </span>
          </>
        )}
        {status === EventStatus.DRAFT && (
          <span>Modified: {dayjs(modifiedDate).format('DD MMM YYYY')}</span>
        )}
        {status === EventStatus.CLOSED && isLimitedGathering && (
          <>
            <span>Registration limit: {maxAttendees} • Registration full</span>
            <span>
              <KeyboardArrowRightIcon />
            </span>
          </>
        )}
        {/* {status === EventStatus.COMPLETED && (
          <>
            <span>
              Registration limit: {maxAttendees === null ? 'Unlimited' : maxAttendees} • Attended
              by: -
            </span>
          </>
        )} */}
      </div>
    </div>
  );
};

const RemainingInfoPopup = ({ open, handleClose, availableTicket }) => {
  return (
    <DoctModal
      title="Registration Details"
      handlePrimaryButtonClick={handleClose}
      handleClose={handleClose}
      open={open}
      showFooter={false}
      className="doctModalTicketInfo"
    >
      <div className="manage_event_card_seats_info_modal_table">
        <div className="customTypo1 text-grey-500 d-flex align-items-center justify-content-between">
          <span>Ticket Name</span>
          <span>Remaining Seats</span>
        </div>
        <div className="line-divider bg-grey-200 my-2" />
        {availableTicket.length > 0 &&
          availableTicket.map((item, i) => (
            <div key={i} className="customTypo2 d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center item">
                <span>
                  <img src={IconTicketColor} alt="" className="" />
                </span>
                <span className="ml-2 text-grey-800">{item.ticketName}</span>
              </div>
              {item.isSoldOut ? (
                <span className="text-grey-800 badge_ item">SOLD OUT</span>
              ) : (
                <span className="text-grey-800 item">{item.remainingSeats || '-'}</span>
              )}
            </div>
          ))}
      </div>
    </DoctModal>
  );
};

CardEvent.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
  name: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  image: PropTypes.object,
};
