import { DoctTextField } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React, { useEffect } from 'react';
import UploadImage from '../../../../../../../shared/ui/UploadImage/UploadImageSquare';

function ManageSupported({
  register,
  setPhotoSrc,
  photoSrc,
  control,
  touched,
  errors,
  selectedRecord,
  supporters,
  setValue,
}) {
  useEffect(() => {
    if (selectedRecord != null) {
      if (
        supporters[selectedRecord]?.SupportedByPictureFile?.length > 0 ||
        supporters[selectedRecord]?.SupportedByPictureFile
      ) {
        if (supporters[selectedRecord]?.SupportedByPictureFile?.length > 0) {
          setPhotoSrc(
            window.URL.createObjectURL(supporters[selectedRecord].SupportedByPictureFile[0]),
          );
        } else if (supporters[selectedRecord]?.SupportedByPictureFile?.type) {
          setPhotoSrc(
            window.URL.createObjectURL(supporters[selectedRecord]?.SupportedByPictureFile),
          );
        }
      } else {
        setPhotoSrc(supporters[selectedRecord]?.supportedByPictureUrl);
      }
    }
  }, []);
  return (
    <>
      <UploadImage
        register={register}
        id={`userProfilePhoto`}
        setPhotoSrc={setPhotoSrc}
        setValue={setValue}
        photoSrc={photoSrc}
        text="Add Logo/Image"
        name="SupportedByPictureFile"
      />
      <DoctTypography variant="textLabel2" className="text-grey-600 text-center">
        Upload JPEG, PNG, JPG file up to 2 MB size
      </DoctTypography>
      <DoctTextField
        showStar={false}
        className="mt-2 mb-2"
        name="supportedByName"
        label="Supporter Name"
        id="supportedByName"
        control={control}
        isErrors={errors}
        defaultValue=""
        validationRules={{
          required: "It's Required Field",
        }}
        touched={touched}
      />
    </>
  );
}

export default ManageSupported;
