import React from 'react';

import { DoctTextField } from '@docthub.frontend/app';

import { FormElWithHeading } from '../../../../shared';
import fieldCommittee from '../components/Common/field.Committee';

export default function FormAddSubCommittee({ formProps }) {
  return (
    <>
      <FormElWithHeading heading={fieldCommittee.subCommittee.label}>
        <DoctTextField
          {...formProps}
          name={fieldCommittee.subCommittee.keyOfGetApi}
          label="For example: Conference committee"
          validationRules={fieldCommittee.subCommittee.validationRules}
          id={fieldCommittee.subCommittee.name}
          showStar={false}
        />
      </FormElWithHeading>
    </>
  );
}
