import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import MUIButton from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import MuiTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

import { DoctTypography } from 'doct-core';
import { NUMBER_OF_GATHERING } from '../../../../../../helper/enums/eventStatus';

const CustomToolTip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#6665ff',
    color: '#ffffff',
    padding: '14px',
    minWidth: '290px',
    fontSize: '14px',
  },
}))(Tooltip);

const AddRemoveCounter = (props) => {
  const {
    item,
    ticketInformation = { checked: false, count: 0 },
    addTicketInformation,
    removeTicketInformation,
    isDisabled,
    numberOfGathering,
    noOfSeatsLeftTotal,
    maxAttendees,
    tickets,
    ticketLimit,
    addedTicketsInformation,
  } = props;

  const { checked = false, count = 0 } = ticketInformation;

  const isDisabledAddTicked = count >= ticketLimit;

  // all logic for not defined tickets validations ===============

  // Check if the ticket is Not Defined =====
  const isNotDefinedTicket = item.numberOfSeats === null;

  // Calculate total assigned seats to Not Defined tickets =====
  const totalSeatsAssignedToNotDefinedTickets =
    maxAttendees -
    (tickets?.reduce((total, ticket) => total + (ticket?.numberOfSeats || 0), 0) || 0);

  // total attendees added to assignes seats for Not Defined tickets =====
  const totalSeatsAddedOnNotDefinedAddedTickets =
    (addedTicketsInformation || []).length > 0
      ? addedTicketsInformation
          .filter((ticket) => ticket?.numberOfSeats === null)
          .reduce((total, ticket) => total + (ticket?.count || 0), 0)
      : 0;

  const remainingIndivisualCount =
    totalSeatsAssignedToNotDefinedTickets - totalSeatsAddedOnNotDefinedAddedTickets;

  const isDisableNotDefined =
    totalSeatsAssignedToNotDefinedTickets <= 0 || remainingIndivisualCount <= 0;

  const updateCount = (newCount) => {
    if (newCount === 0) {
      removeTicketInformation({ id: item.id });
      return;
    }
    addTicketInformation({ ...ticketInformation, count: newCount });
  };

  const onChangeHandler = (clicked) => {
    if (clicked) {
      addTicketInformation({ ...item, checked: true, count: 1 });
    } else {
      removeTicketInformation({ id: item.id });
    }
  };

  const isCountDisable = () => {
    if (numberOfGathering === NUMBER_OF_GATHERING.UNLIMITED) {
      return false;
    } else {
      return (
        isDisabledAddTicked ||
        (numberOfGathering === NUMBER_OF_GATHERING.LIMITED && noOfSeatsLeftTotal === null) ||
        (isNotDefinedTicket ? isDisableNotDefined : count >= item.availableSeats)
      );
    }
  };

  const displayValidationMessage = () => {
    if (numberOfGathering === NUMBER_OF_GATHERING.UNLIMITED && isDisabledAddTicked) {
      return `You can add max ${ticketLimit} Tickets`;
    }

    if (numberOfGathering === NUMBER_OF_GATHERING.LIMITED) {
      const hasNoSeatsLeft = item.availableSeats !== null && count >= item.availableSeats;
      if (hasNoSeatsLeft || (isNotDefinedTicket && isDisableNotDefined)) {
        return `No more seats left!`;
      }
    }

    return '';
  };

  return (
    <CustomToolTip
      title={isDisabled ? 'You can only select single currency registration category.' : ''}
      arrow
      placement="top"
    >
      <div>
        {!checked && (
          <MUIButton
            variant="contained"
            startIcon={<AddIcon />}
            className={`button-slate-blue add-button ${
              isDisabled || isCountDisable() ? 'add-button-disabled' : ''
            }`}
            onClick={() => !isCountDisable() && onChangeHandler(true)}
            disabled={isDisabled || isCountDisable()}
          >
            Add
          </MUIButton>
        )}
        {checked && (
          <div className="d-flex align-items-center">
            <MUIButton
              variant="contained"
              color="primary"
              startIcon={count === 1 ? <DeleteIcon /> : <RemoveIcon />}
              className="circle-button"
              onClick={() => updateCount(count - 1)}
            >
              <span />
            </MUIButton>
            <DoctTypography variant="textLabel1" className="mb-0 add-remove-count">
              {count}
            </DoctTypography>
            <MuiTooltip title={displayValidationMessage()} arrow placement="top">
              <span className={isCountDisable() ? 'cursor-not-allow' : ''}>
                <MUIButton
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  className="circle-button"
                  disabled={isCountDisable()}
                  onClick={() => !isCountDisable() && updateCount(count + 1)}
                >
                  <span />
                </MUIButton>
              </span>
            </MuiTooltip>
          </div>
        )}
      </div>
    </CustomToolTip>
  );
};

export default AddRemoveCounter;
