import React from 'react';
import { DoctModal } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import AssignAwardeeCards from '../../featAllECertificate/AssignAwardeeCards';

export default function AssignNewAwardeePopUp({
  isPopUp,
  name,
  open,
  handleClose,
  sendEmailPopUpHandleClose,
  id,
}) {
  return (
    <>
      {isPopUp && (
        <DoctModal
          open={open}
          showFooter={false}
          className="modal-hidden disable_modal_outside_click doct-modal-extra-padding assign-via-dashboard-modal"
          handleClose={handleClose}
          width={914}
        >
          <DoctTypography
            variant="textLabel1"
            className="text-grey-800 m-0 text-center font-weight-medium mt-n4"
          >
            Assign Certificate to:
          </DoctTypography>
          <DoctTypography
            variant="h6"
            className="text-grey-800 font-weight-bold m-0 mb-4 text-center text-word-break"
          >
            {name}
          </DoctTypography>
          <AssignAwardeeCards
            id={id}
            toggleNewAwardee={handleClose}
            sendEmailPopUpHandleClose={sendEmailPopUpHandleClose}
          />
        </DoctModal>
      )}
    </>
  );
}
