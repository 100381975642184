import React, { useEffect, useState } from 'react';
import AssignAwardeeCards from './featAllECertificate/AssignAwardeeCards';
import AwardeesListing from './shared/AwardeesListing';
import AwardeeListingPage from './featAllECertificate/AwardeeListingPage';
import { useLocation } from 'react-router';
import {
  useFetchAwardeesQuery,
  useSendPendingCertificateMutation,
} from './services/eCertificateApi';
import { DoctAnimationLoading } from '@docthub.frontend/app';
import { useDispatch, useSelector } from 'react-redux';
import { getCertificate } from './shared/EcertificateAPIcall';
import { DoctTypography } from 'doct-core';
import {
  setAllCertificateData,
  setAwardeeData,
  setMailSentForAwardee,
} from '../slices/eCertificateSlice';
import { Toaster } from '../../../shared';

export default function AllAwardeesListingCard() {
  const [awardeeDataPageSize, setAwardeeDataPageSize] = useState(20);
  const location = useLocation();
  const [certificateData, setCertificateData] = useState({});
  const [searchText, setSearchText] = useState('');
  const [showToaster, setShowToaster] = useState(false);
  const [deleteToast, setDeleteToast] = useState(false);
  // const [mailSentForAwardee, setMailSentForAwardee] = useState(true);
  const mailSentForAwardee = useSelector((state) => state?.eCertificateData?.mailSentForAwardee);

  const filterProps = {
    ...location.state,
  };
  const [pendingCount, setPendingCount] = useState(0);

  const dispatch = useDispatch();

  const certificateID = location?.search?.includes('id')
    ? location?.search?.split('=')[1]
    : certificateData[0]?.id;

  useEffect(() => {
    if (certificateID) {
      setAwardeeDataPageSize(20);
    }
  }, [certificateID]);

  useEffect(() => {
    if (typeof certificateID === 'undefined') {
      getCertificate().then((res) => {
        setCertificateData(res?.data?.eCertificates);
        dispatch(setAllCertificateData(res?.data?.eCertificates));
      });
    } else {
      setCertificateData({});
    }
  }, []);

  const {
    data: awardeeData,
    isLoading: isAwardeeDataLoading,
    refetch,
  } = useFetchAwardeesQuery({ certificateID, searchText, filterProps, awardeeDataPageSize });

  useEffect(() => {
    refetch();
  }, [showToaster, location]);

  useEffect(() => {
    dispatch(setAwardeeData(awardeeData));
    getCertificate().then((res) => {
      setCertificateData(res?.data?.eCertificates);
      dispatch(setAllCertificateData(res?.data?.eCertificates));
    });
    setPendingCount(awardeeData?.awardeesEmailPending);
  }, [awardeeData]);

  const [
    sendPendingCertificate,
    { isLoading: isSendPendingCertificateLoading, isSuccess: sendSuccess },
  ] = useSendPendingCertificateMutation();

  // ToDo:- will need to add pageNuber, pageSize in paylod after Pagination add in Awardees list
  const handleSendPendingCertificate = async () => {
    await sendPendingCertificate({
      id: certificateID,
      ...filterProps,
      SearchText: searchText,
      PageNumber: 1,
      PageSize: awardeeDataPageSize,
    });
  };

  useEffect(() => {
    if (sendSuccess) {
      refetch();
      setTimeout(() => {
        setDeleteToast(true);
      }, 4000);
    }
  }, [sendSuccess]);

  const { eCertificateData } = useSelector((state) => state) ?? {};

  const [newAwardee, setNewAwardee] = useState(false);

  useEffect(() => {
    const fullPath = location.pathname + location.search;
    if (
      fullPath?.includes('/dashboard/e-certificate/all-awardees-list?id=') &&
      awardeeData?.totalRecords > 0
    ) {
      setNewAwardee(false);
    } else if (
      eCertificateData?.trackPreviousUrlOfCertificate ||
      fullPath === '/dashboard/e-certificate/all-awardees-list' ||
      awardeeData?.totalRecords == 0
    ) {
      setNewAwardee(true);
    }
    if (Object.keys(filterProps ?? {}).length > 0) {
      setNewAwardee(false);
    }
  }, [location]);

  const awardeeCounts = 0;

  const handleCloseMailToaster = () => {
    dispatch(setMailSentForAwardee(false));
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setMailSentForAwardee(false));
    }, 3000);
  }, [mailSentForAwardee]);

  return (
    <>
      {isAwardeeDataLoading ? (
        <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
          <DoctAnimationLoading />
        </div>
      ) : (
        <>
          {mailSentForAwardee && (
            <div className="position-fixed tost-container-top-right">
              <Toaster
                variant={'positive'}
                text={'Email sent successfully!'}
                onPressedClose={handleCloseMailToaster}
              />
            </div>
          )}
          <AwardeesListing
            awardeeDataPageSize={awardeeDataPageSize}
            awardeeCount={awardeeData?.totalRecords ?? 0}
            searchText={searchText}
            filterProps={filterProps}
            setSearchText={setSearchText}
            newAwardee={newAwardee}
            setNewAwardee={setNewAwardee}
            certificateID={certificateID}
          >
            {awardeeData?.totalRecords <= 0 &&
            Object.keys(filterProps)?.length <= 0 &&
            certificateID ? (
              <>
                <DoctTypography variant="h5" className="text-grey-800 font-weight-bold mt-5">
                  Assign Certificate to Awardees
                </DoctTypography>
                <DoctTypography variant="textLabel1" className="text-grey-600 mt-2 mb-4">
                  There are 3 ways to assign certificate:
                </DoctTypography>
                <AssignAwardeeCards
                  toggleNewAwardee={() => {}}
                  certificateID={certificateID}
                  awardeeCounts={awardeeCounts}
                />
              </>
            ) : (
              <div>
                <AwardeeListingPage
                  awardeeData={awardeeData?.awardees ?? []}
                  pendingCount={pendingCount}
                  certificateID={certificateID}
                  setNewAwardee={setNewAwardee}
                  setAwardeeDataPageSize={setAwardeeDataPageSize}
                  awardeeDataPageSize={awardeeDataPageSize}
                  handleSendPendingCertificate={handleSendPendingCertificate}
                  setShowToaster={setShowToaster}
                  showToaster={showToaster}
                  isSendPendingCertificateLoading={isSendPendingCertificateLoading}
                />

                {deleteToast ||
                  (sendSuccess && (
                    <div className="position-fixed tost-container-top-right">
                      <Toaster
                        variant="positive"
                        text="Email sent successfully"
                        onPressedClose={() => setDeleteToast(true)}
                      />
                    </div>
                  ))}
              </div>
            )}
          </AwardeesListing>
        </>
      )}
    </>
  );
}
