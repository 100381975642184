import { DoctForm, DoctModal } from '@docthub.frontend/app';
import { DoctButton, DoctTypography } from 'doct-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import inrImg from '../../../../../../../../assets/images/Create Events Form/Tickets/ticket-usd-image.svg';
import { TOST_TOP_RIGHT } from '../../../../../../../../constants/toasterPosition.constants';
import { setShowToasterWithParams } from '../../../../../../../featuresSlice';
import {
  fetchEventById,
  indianTicket,
  selectAttendeeValue,
  selectBasicInfoDetails,
  selectCapacityUsed,
  selectCreateEventResponse,
  selectIndianTicket,
  selectTicketCategory,
  selectTicketsDetails,
} from '../../../../../createEvent.slice';
import InrCard from '../../TicketsSubSection/components/InrCard';
import ManageIndianRegistration from './ManageIndianRegistration';

function IndianRegistration({ inrTickets, setInrTickets, tarrif, setTotalSeatCapacity }) {
  const { handleSubmit, control, touched, errors, reset, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });
  const dispatch = useDispatch();
  const { id } = useParams();
  const ticketCategory = useSelector(selectTicketCategory);
  const selectindianTicket = useSelector(selectIndianTicket);
  const tickets = useSelector(selectTicketsDetails);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const [dataStoreInState, setDataStoreInState] = useState(false);
  const maxAttendeesForEvent = useSelector(selectAttendeeValue);
  const capacity = useSelector(selectCapacityUsed);
  const basicInfoPageDetails = useSelector(selectBasicInfoDetails);
  const [allowToAddSeatsInUnLimited, setAllowToAddSeatsInUnLimited] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(false);

  const [notDefined, setNotDefined] = useState(false);
  const [seatCapacityReachedToaster, setSeatCapacityReachedToaster] = useState(false);
  const [oldSeatCapacityValue, setOldSeatCapacityValue] = useState();

  const manageActionHandler = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (id == undefined) return;
    dispatch(fetchEventById(id));
  }, [id]);

  const deleteRecordHandler = (index) => {
    let newArray = [...inrTickets];
    newArray.splice(index, 1);
    setInrTickets(newArray);
  };

  const [selectedRecords, setSelectedRecords] = useState(null);
  const [isDuplicateTicket, setIsDuplicateTicket] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);

  useEffect(() => {
    const inrReg = [...inrTickets];
    if (Object.keys(selectindianTicket).length > 1) {
      inrReg.push(selectindianTicket);
    }
    setInrTickets(inrReg);
  }, [selectindianTicket]);

  useEffect(() => {
    if (dataStoreInState == true) return;
    const inrReg = [...inrTickets];
    if (Object.keys(apiResponseData).length) {
      if (id == undefined) return;
      const inrArray = apiResponseData?.tickets?.filter(({ currency }) => currency == 'INR') || [];
      const newArrayForInrReg = [];
      inrArray?.map((item) => {
        const newObj = {};
        newObj.id = item.id;
        newObj.attendeeType = item.attendeeType;
        if (item.numberOfSeats == null) {
          newObj.notDefined = true;
        }
        newObj.numberOfSeats = item.numberOfSeats;
        newObj.ticketInUse = item.ticketInUse;
        newObj.category = {};
        item.prices.map((item) => {
          const categoryName = {};
          newObj.category[item.category] = categoryName;
          categoryName.code = 'INR';
          categoryName.amount = item.amount;
        });
        newArrayForInrReg.push(newObj);
      });
      newArrayForInrReg?.map((item) => {
        if (inrReg.includes(item) != true) {
          inrReg.push(item);
        }
      });
      setDataStoreInState(true);
    } else if (Object.keys(tickets).length) {
      const inrArray = tickets?.tickets?.filter(({ Currency }) => Currency == 'INR') || [];
      const newArrayForInrReg = [];
      inrArray?.map((item) => {
        const newObj = {};
        if (item.id) {
          newObj.id = item.id;
        }
        newObj.attendeeType = item.attendeeType;
        newObj.notDefined = item.notDefined;
        newObj.numberOfSeats = item.numberOfSeats;
        newObj.ticketInUse = item.ticketInUse;
        newObj.category = {};
        item.prices.map((item) => {
          const categoryName = {};
          newObj.category[item.category] = categoryName;
          categoryName.code = 'INR';
          categoryName.amount = item.amount;
        });
        newArrayForInrReg.push(newObj);
      });
      newArrayForInrReg?.map((item) => {
        if (inrReg.includes(item) != true) {
          inrReg.push(item);
        }
      });
      setDataStoreInState(true);
    }
    setInrTickets(inrReg);
  }, [Object.keys(apiResponseData).length, Object.keys(tickets).length]);

  useEffect(() => {
    if (basicInfoPageDetails?.numberOfGathering == 'Unlimited') {
      setAllowToAddSeatsInUnLimited(basicInfoPageDetails?.numberOfGathering);
    } else if (apiResponseData?.numberOfGathering == 'Unlimited') {
      setAllowToAddSeatsInUnLimited(apiResponseData?.numberOfGathering);
    }
  }, [apiResponseData, basicInfoPageDetails]);

  useEffect(() => {
    if (inrTickets?.length) {
      const sum = inrTickets.reduce(function (prev, current) {
        return prev + +current.numberOfSeats;
      }, 0);
      setTotalSeatCapacity(+sum);
    } else {
      setTotalSeatCapacity(0);
    }
  }, [inrTickets, selectedRecords]);

  const handleFormSubmit = handleSubmit((values) => {
    let _values = {
      id: selectedTicketId,
      ...values,
    };
    if (isDuplicateTicket) {
      const newCapacity = capacity + +_values.numberOfSeats;
      _values.notDefined = notDefined;
      if (newCapacity > maxAttendeesForEvent && allowToAddSeatsInUnLimited != 'Unlimited') {
        setSeatCapacityReachedToaster(true);
      } else {
        dispatch(indianTicket(_values));
        setSelectedRecords(null);
        setSelectedTicketId(null);
        manageActionHandler();
        setEditingData(false);
        setNotDefined(false);
        setIsDuplicateTicket(false);
      }
    } else if (editingData) {
      const newCapacity = capacity + +_values.numberOfSeats - +oldSeatCapacityValue;
      _values.notDefined = notDefined;
      if (newCapacity > maxAttendeesForEvent && allowToAddSeatsInUnLimited != 'Unlimited') {
        setSeatCapacityReachedToaster(true);
      } else {
        inrTickets[selectedRecords] = _values;
        setSelectedRecords(null);
        setSelectedTicketId(null);
        manageActionHandler();
        setEditingData(false);
        setNotDefined(false);
      }
    } else {
      const newCapacity = capacity + +_values.numberOfSeats;
      _values.notDefined = notDefined;
      if (newCapacity > maxAttendeesForEvent && allowToAddSeatsInUnLimited != 'Unlimited') {
        setSeatCapacityReachedToaster(true);
      } else {
        dispatch(indianTicket(_values));
        setSelectedRecords(null);
        setSelectedTicketId(null);
        manageActionHandler();
        setEditingData(false);
        setNotDefined(false);
      }
    }
  });

  useEffect(() => {
    if (seatCapacityReachedToaster) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [seatCapacityReachedToaster]);

  const onTostCloseHandler = () => {
    setSeatCapacityReachedToaster(false);
  };

  useEffect(() => {
    if (seatCapacityReachedToaster) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_TOP_RIGHT,
          message: 'Seat Capacity Reached',
        }),
      );
    }
  }, [seatCapacityReachedToaster]);

  return (
    <>
      {inrTickets.length ? (
        <div className="INR_section_second_page bg-grey-100 px-3 py-3 border-radius mt-3">
          <DoctTypography variant="h6" className="inr_text">
            Indian Registration (INR)
          </DoctTypography>
          <DoctButton
            type="inverse"
            variant="text"
            icon="plus"
            text="Add Ticket"
            className="mx-sm-n4 mt-2"
            onButtonClickHandler={(e) => {
              setSelectedRecords(null);
              setSelectedTicketId(null);
              manageActionHandler();
              e.preventDefault();
            }}
            disabled={ticketCategory?.ticketCategories ? false : true}
          />
          <div className="inr_categories_second-page position-relative bg-white border-radius">
            {inrTickets?.map((item, index) => {
              return (
                <div key={index}>
                  <InrCard
                    regtype={item?.attendeeType}
                    seats={item?.numberOfSeats}
                    category={item?.category}
                    latestCategory={ticketCategory}
                    setIsModalOpen={setIsModalOpen}
                    additionalFun={() => {
                      setSelectedRecords(index);
                      setSelectedTicketId(item?.id);
                      setEditingData(true);
                    }}
                    deleteRecordHandler={deleteRecordHandler}
                    index={index}
                    ticketCategory={ticketCategory?.ticketCategories}
                    notDefined={item.notDefined}
                    setIsDuplicateTicket={setIsDuplicateTicket}
                    ticketInUse={item.ticketInUse}
                  />
                </div>
              );
            })}
          </div>
          {tarrif && (
            <DoctTypography variant="body3" className="text-grey-400 text-center">
              Prices are
              {tarrif == 'ExcludingAllTaxes' ? ' Excluding All Taxes' : ' Including All Taxes'}
            </DoctTypography>
          )}
        </div>
      ) : (
        <div className="INR_section bg-grey-100 mt-3 border-radius mx-auto px-4 py-3">
          <h6 className="inr_text">Indian Registration (INR)</h6>
          <div className="d-flex text-center justify-content-center align-items-center">
            <div>
              <div className="mt-2">
                <img src={inrImg} alt="INR" />
              </div>
              <div>
                <DoctTypography variant="body2" className="mt-1 text-grey-600">
                  Add tickets as per your attendee types.
                </DoctTypography>
              </div>
              <div className="d-flex text-center justify-content-center align-items-center">
                <DoctButton
                  variant="contained"
                  icon="plus"
                  text="Add Ticket"
                  className="doct_btn_set_catrgories mb-3"
                  disabled={ticketCategory?.ticketCategories ? false : true}
                  onButtonClickHandler={(e) => {
                    setSelectedRecords(null);
                    setSelectedTicketId(null);
                    manageActionHandler();
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <DoctModal
        iconName=""
        primaryBtnText={'Save'}
        open={isModalOpen}
        className={'disable_modal_outside_click modal-content-position-relative'}
        handlePrimaryButtonClick={handleFormSubmit}
        handleClose={() => {
          setSelectedRecords(null);
          setSelectedTicketId(null);
          manageActionHandler();
          setEditingData(false);
        }}
        title={editingData ? 'Edit Ticket' : 'Add Ticket'}
        width={360}
      >
        <ManageIndianRegistration
          control={control}
          errors={errors}
          touched={touched}
          handleFormSubmit={handleFormSubmit}
          inrTickets={inrTickets}
          selectedRecord={selectedRecords}
          selectedTicketId={selectedTicketId}
          reset={reset}
          tarrif={tarrif}
          setValue={setValue}
          setNotDefined={setNotDefined}
          notDefined={notDefined}
          setOldSeatCapacityValue={setOldSeatCapacityValue}
        />
      </DoctModal>
    </>
  );
}

export default IndianRegistration;
