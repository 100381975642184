import { DoctAnimationLoading, DoctForm } from '@docthub.frontend/app';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useUpdateProfileMutation } from '../../../../../components';
import { NETWORK_REQUEST_ERROR_MSG } from '../../../../../constants/constants';
import { TOST_BOTTOM_CENTER } from '../../../../../constants/toasterPosition.constants';
import { setShowToasterWithParams } from '../../../../featuresSlice';
import { excludeUnusedProfileData } from '../AdminProfile/helperFunction.AdminProfile';
import FormEditLocationDetailsProfile from './Form.EditLocationDetailsProfile';
import transformFormData from './helperFunction.LocationDetailsProfile';

function LoaderForModal() {
  return (
    <div className="content-with-animated-loader-area content-with-animated-loader-area-min-height">
      <div className="content-animated-loader-row transparent-bg">
        <DoctAnimationLoading />
      </div>
    </div>
  );
}

export default function EditLocationDetailsProfile({
  defaultValues,
  userData,
  modalComponent: ModalComponent,
  handleModalClose,
  isLocationApiLoading,
}) {
  const dispatch = useDispatch();

  const { handleSubmit, control, errors, touched, setValue, watch, reset } = DoctForm({
    mode: 'onChange',
    defaultValues: defaultValues || {},
  });

  const [useUpdateProfile, { isLoading, isSuccess, isError }] = useUpdateProfileMutation();

  const onSubmitForm = handleSubmit((data) => {
    useUpdateProfile(
      excludeUnusedProfileData({
        ...userData,
        ...transformFormData(data),
        ImageFileName: 'image',
        ResumeFileName: 'resume',
      }),
    );
  });

  const hookFormProps = { control, errors, isErrors: errors, touched, setValue, watch };
  const modalProps = { onSubmitForm, isLoading, handleModalClose };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  useEffect(() => {
    if (isSuccess) {
      handleModalClose();
      dispatch(
        setShowToasterWithParams({
          variant: 'neutral',
          position: TOST_BOTTOM_CENTER,
          message: 'Changes saved successfully!',
        }),
      );
    }
    if (isError) {
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_BOTTOM_CENTER,
          message: NETWORK_REQUEST_ERROR_MSG,
        }),
      );
    }
  }, [isSuccess, isError]);

  return (
    <ModalComponent {...modalProps}>
      {isLocationApiLoading && <LoaderForModal />}
      {!isLocationApiLoading && defaultValues && (
        <FormEditLocationDetailsProfile
          hookFormProps={hookFormProps}
          defaultValues={defaultValues}
        />
      )}
    </ModalComponent>
  );
}
