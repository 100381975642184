import React from 'react';
import { DoctButton } from 'doct-core';
import { saveDiscount } from '../../../offlineRegistration.slice';
import { useDispatch } from 'react-redux';

const Discount = ({
  setDiscount,
  discountClicked,
  setDiscountClicked,
  applyClick,
  setApplyClick,
}) => {
  const dispatch = useDispatch();

  return discountClicked ? (
    <div className="d-flex">
      {applyClick === true ? (
        <DoctButton
          variant="text"
          type="semantic-danger"
          size="medium"
          text={`Remove Discount`}
          className="my-0 mt-sm-auto"
          onButtonClickHandler={() => {
            setApplyClick(!applyClick);
            setDiscount(null);
          }}
        />
      ) : (
        <input
          className="border-radius border-light numbers_adding px-1 py-1 text-grey-800 bg-grey-200 mt-1"
          name="discount"
          type="number"
          required
          min={0}
          onChange={(e) => {
            setDiscount(Math.abs(e.target.value));
            dispatch(saveDiscount(Math.abs(e.target.value)));
          }}
        />
      )}
      <DoctButton
        variant="text"
        type="semantic-info"
        size="medium"
        text={`${applyClick ? '' : 'Apply'}`}
        className="my-0 mt-sm-auto"
        onButtonClickHandler={() => setApplyClick(!applyClick)}
      />
    </div>
  ) : (
    <DoctButton
      variant="text"
      type="primary"
      size="medium"
      text="ADD DISCOUNT"
      className="my-0"
      onButtonClickHandler={() => {
        setDiscountClicked(true);
      }}
    />
  );
};

export default Discount;
