import React, { useLayoutEffect, useState } from 'react';
import { DoctButton, DoctContainer, DoctIconButton, DoctTypography } from 'doct-core';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { GuidelineModal } from '../../../features/RecruiterDashboard/JobListing/GuidlineForJobsPosting';
import { DASHBOARD } from '../../../routes/constant';
import billingDetailsLayoutPageActionBar from '../../../features/SubscriptionModal/BillingDetails/billingDetailsLayout.actionbar.data';
import { subscriptionLayoutPageActionBar } from '../../../features/SubscriptionModal';
import allECertificatePageActionBar from '../../../features/ECertificates/components/allECertificate.actionbar.data';
import allAwardeesListingActionBar from '../../../features/ECertificates/components/allAwardeesListing.actionbar.data';
import {
  eventPageActionBar,
  eventRegistrationPageActionBar,
  manageAttendeesActionBar,
  invoicesSummaryActionBar,
  allMembershipsPageActionBar,
  manageDirectoryPageActionBar,
  instituteDashboardCoursesPageActionBar,
  instituteDashboardApplicantsPageActionBar,
  recruiterDashboardAllJobsPageActionBar,
  recruiterDashboardAllJobApplicantPageActionBar,
  activityLogActionBar,
  profileActionBarData,
  securityActionBarData,
  EventAbstractActionBar,
  committeeLogActionBar,
} from '../../../features';

const initialState = {
  pathName: '',
  title: '',
  navigateButtonLink: '',
  buttonText: '',
  buttonIcon: '',
  GuidelineBtnShow: '',
  disablePostAJobBtn: '',
};

// add all page actionbar here
const allPageActionBar = {
  ...manageDirectoryPageActionBar,
  ...allMembershipsPageActionBar,
  ...eventPageActionBar,
  ...eventRegistrationPageActionBar,
  ...manageAttendeesActionBar,
  ...invoicesSummaryActionBar,
  ...instituteDashboardCoursesPageActionBar,
  ...instituteDashboardApplicantsPageActionBar,
  ...recruiterDashboardAllJobsPageActionBar,
  ...recruiterDashboardAllJobApplicantPageActionBar,
  ...activityLogActionBar,
  ...profileActionBarData,
  ...securityActionBarData,
  ...EventAbstractActionBar,
  ...committeeLogActionBar,
  ...subscriptionLayoutPageActionBar,
  ...billingDetailsLayoutPageActionBar,
  ...allECertificatePageActionBar,
  ...allAwardeesListingActionBar,
};

export default function ManagePageActionBar() {
  let location = useLocation();
  const navigate = useNavigate();

  const [titleAndAction, setTitleAndAction] = useState({});
  const [isOpenModal, setIsOpenModal] = useState(false); // Move useState to the top level

  useLayoutEffect(() => {
    const currentPathObj = allPageActionBar[location.pathname] || {};
    if (Object.keys(currentPathObj).length) {
      setTitleAndAction(currentPathObj);
    } else {
      setTitleAndAction(initialState);
    }
  }, [location.pathname]);

  if (!titleAndAction.pathname) {
    return null;
  }

  const handleButtonClick = () => {
    setIsOpenModal(true);
  };

  const renderActionButton = () => {
    if (titleAndAction.btnComponent) {
      return titleAndAction.btnComponent;
    }

    if (titleAndAction.isButtonDisabled) {
      return null;
    }

    return (
      <div className="d-flex align-items-center">
        {titleAndAction.GuidelineBtnShow && (
          <>
            <DoctButton
              variant="contained"
              text="Posting Guidelines"
              size="medium"
              className="mr-2"
              onButtonClickHandler={handleButtonClick}
            />

            <GuidelineModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
          </>
        )}
        <Link
          to={
            titleAndAction.disablePostAJobBtn
              ? titleAndAction.pathname
              : titleAndAction.navigateButtonLink || '/'
          }
          className="ml-auto"
        >
          <DoctButton
            variant="contained"
            text={titleAndAction.buttonText || 'Create'}
            size="medium"
            icon={titleAndAction.buttonIcon}
            disabled={titleAndAction.isButtonDisabled || titleAndAction.disablePostAJobBtn}
          />
        </Link>
      </div>
    );
  };

  return (
    <div className="bg-grey-100 manage_page__action_bar">
      <DoctContainer>
        <div className="d-flex-center-y action-bar-height justify-content-between">
          <div className="d-flex align-items-center">
            <span
              className="ml-n2 d-inline-flex"
              onClick={() =>
                location?.search?.includes('upload-certificate=true') ||
                location?.search?.includes('?=certificateID=')
                  ? ''
                  : navigate(`/${DASHBOARD}`)
              }
            >
              <DoctIconButton
                variant="text"
                type="secondary"
                icon="arrowBack"
                size="medium"
                disabled={
                  location?.search?.includes('?=certificateID=') ||
                  location?.search?.includes('upload-certificate=true')
                }
              />
            </span>
            <DoctTypography variant="subtitle2">{titleAndAction.title || 'Manage'}</DoctTypography>
          </div>
          <>{renderActionButton()}</>
        </div>
      </DoctContainer>
    </div>
  );
}
