import { DoctTypography } from 'doct-core';
import React, { useEffect, useRef, useState } from 'react';
import image from '../../../assets/icons/photo-upload.svg';
import './UploadImage.scss';
import { setShowToasterWithParams } from '../../../features';
import { TOST_TOP_RIGHT } from '../../../constants/toasterPosition.constants';
import { useDispatch } from 'react-redux';

function UploadImage({
  user = { image: '' },
  id,
  register,
  photoSrc,
  setPhotoSrc,
  text,
  name,
  setValue,
}) {
  const [toasterMessage, setToasterMessage] = useState(false);
  const [toasterMessageForInvalidFile, setToasterMessageForInvalidFile] = useState(false);
  const [erorFormSubmit, setErrorFormSubmit] = useState(null);
  const labelRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (user.image) {
      setPhotoSrc(user.image);
    } else {
      setPhotoSrc(photoSrc || image);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setToasterMessage(false);
    }, 2000);
  }, [toasterMessage]);

  useEffect(() => {
    setTimeout(() => {
      setToasterMessageForInvalidFile(false);
    }, 2000);
  }, [toasterMessageForInvalidFile]);

  const onTostCloseHandler = () => {
    setToasterMessage(false);
    setErrorFormSubmit(null);
  };
  useEffect(() => {
    if (toasterMessage) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [toasterMessage]);

  useEffect(() => {
    toasterMessage &&
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_TOP_RIGHT,
          message: erorFormSubmit?.Title || 'Maximum allowed size is 5 MB',
        }),
      );
  }, [toasterMessage]);

  useEffect(() => {
    toasterMessageForInvalidFile &&
      dispatch(
        setShowToasterWithParams({
          variant: 'danger',
          position: TOST_TOP_RIGHT,
          message: erorFormSubmit?.Title || 'Invalid File Type !',
        }),
      );
  }, [toasterMessageForInvalidFile]);

  if (user.image) {
    return (
      <div className="d-flex align-items-center mb-4 mt-2">
        <div className="profile_photo">
          <img src={photoSrc} alt="image" />
        </div>
        <label className="cursor-pointer ml-4" htmlFor={id}>
          <div className="mr-4">
            <input
              id={id}
              type="file"
              hidden
              name={name}
              ref={register}
              onChange={(e) => {
                setPhotoSrc(null);
                if (e.target.files[0]?.size >= 2097152) {
                  setToasterMessage(true);
                  if (setValue) setValue(name, null);
                } else {
                  setPhotoSrc(URL.createObjectURL(e.target.files[0]));
                }
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              accept="image/*"
            />
          </div>
          <span className="doct-button d-flex align-items-center justify-content-center doct-medium-button doct-outlined-button">
            {text}
          </span>
        </label>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex mb-3">
        <label className="cursor-pointer  mb-4 mt-2 d-flex align-items-center" ref={labelRef}>
          <input
            id={id}
            type="file"
            hidden
            name={name}
            ref={register}
            onChange={(e) => {
              setPhotoSrc(null);
              const fileExtension = e?.target?.files[0]?.name?.toString()?.split('.')[1];
              if (!['jpeg', 'jpg', 'png'].includes(fileExtension.toLowerCase())) {
                setToasterMessageForInvalidFile(true);
                if (setValue) setValue(name, null);
                return;
              }
              if (e.target.files[0]?.size >= 2097152) {
                setToasterMessage(true);
                if (setValue) setValue(name, null);
                setPhotoSrc(image);
              } else {
                if (e?.target?.files?.length != 0) {
                  setPhotoSrc(URL.createObjectURL(e.target.files[0]));
                } else {
                  setPhotoSrc(image);
                }
              }
            }}
            onClick={(event) => {
              event.target.value = null;
            }}
            accept="image/*"
          />
        </label>
        <div className="cursor-pointer mr-4 profile_photo">
          <img
            src={photoSrc || image}
            alt="Upload"
            onClick={() => {
              labelRef.current.click();
            }}
          />
        </div>
        <div className="mt-5">
          <span
            className="doct-button d-flex align-items-center justify-content-center bg-info text-white doct-info-button doct-medium-button doct-contained-button"
            onClick={() => {
              labelRef.current.click();
            }}
          >
            {text}
          </span>
          <DoctTypography variant="textLabel2" className="text-grey-600 justify-content-end d-flex">
            Upload JPEG, PNG, JPG file
            <br />
            up to 2 MB size
          </DoctTypography>
        </div>
      </div>
    </>
  );
}

export default UploadImage;
