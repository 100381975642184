import { DoctActionMenu, DoctChip } from '@docthub.frontend/app';
import { DoctIcon, DoctTypography } from 'doct-core';
import React from 'react';
import style from './DetailsActivityLog.module.scss';
import dayjs from 'dayjs';
import DefaultImage from '../../../../../assets/images/activityLog/default-activity-log-xl.svg';

const options = [
  {
    title: 'Edit',
  },
  {
    title: 'Delete',
    className: 'text-danger',
  },
];

export default function DetailsActivityLog({ activity, cardMenuOptions }) {
  const { coverImageUrl, startDate, members, highlights, supportedBy } = activity || {};
  return (
    <div>
      <div className={`${style['activity-log-details-img']} mx-n3 mt-n3`}>
        <img
          src={coverImageUrl || DefaultImage}
          alt="activity details"
          className="object-fit-cover"
        />
      </div>
      <div className="py-4 px-2">
        <div className="mb-0 d-flex align-items-center">
          <div className="d-flex">
            <DoctIcon name="calendar" height="18" width="18" className="text-grey-600 mr-1" />
            <DoctTypography variant="textLabel1" className="my-0">
              {dayjs(startDate).format('DD MMM YYYY')}
            </DoctTypography>
          </div>
          <DoctActionMenu
            className={'ml-auto rotate90'}
            options={options}
            onClick={(item) => {
              cardMenuOptions(item, activity?.id, activity);
            }}
          />
        </div>

        {!!members?.length && (
          <>
            <DoctTypography variant="subtitle2" className="mb-6px mt-3">
              Organizing Team
            </DoctTypography>

            {members.map(({ memberName, memberId }) => {
              return (
                <span className={'mr-6px d-inline-flex mb-6px'} key={memberId}>
                  <DoctChip title={memberName} showCloseIcon={false} />
                </span>
              );
            })}
          </>
        )}
        {highlights && (
          <>
            <DoctTypography variant="subtitle1" className="pt-3 mt-n1 mb-12px">
              Highlights
            </DoctTypography>
            <div className="mb-3" dangerouslySetInnerHTML={{ __html: highlights }} />
          </>
        )}
        {!!supportedBy?.length && (
          <>
            <DoctTypography variant="subtitle1" className="mb-6px mt-0">
              Supported By
            </DoctTypography>
            {supportedBy?.map((supportedByName) => {
              return (
                <span key={supportedByName} className={'mr-6px d-inline-flex mb-6px'}>
                  <DoctChip title={supportedByName} showCloseIcon={false} />
                </span>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
