import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import FormHelperText from '@mui/material/FormHelperText';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import '~doct-core/build/textEditor.scss';
import './TextEditor.scss';

const TEXT_EDITOR_PROPS = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
    ],
  },
  formats: ['bold', 'italic', 'underline', 'link', 'list'],
};

export default function TextEditor({
  placeholder = 'Type here...',
  control,
  name = 'textEditor',
  validationRules,
  isErrors,
  setValue: setValueHookForm = () => {},
}) {
  const [value, setValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const getClassName = () => {
    let initialClassName = 'doct-text-editor';
    if (control && isErrors?.[name]) {
      return `${initialClassName} text-editor-on-error`;
    }
    if (isFocused) {
      return `${initialClassName} quill-focused`;
    }

    return initialClassName;
  };

  const props = {
    className: getClassName(),
    value,
    onChange: setValue,
    placeholder,
    theme: 'snow',
    modules: TEXT_EDITOR_PROPS.modules,
    formats: TEXT_EDITOR_PROPS.formats,
    onFocus: () => setIsFocused(true),
    onBlur: () => setIsFocused(false),
    name,
  };

  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        rules={validationRules}
        render={({ onChange, value }) => {
          return (
            <>
              <ReactQuill
                {...props}
                onChange={(value) => {
                  onChange(value);
                  if (value === '<p><br></p>') {
                    setValue(null);
                    if (setValueHookForm) {
                      setValueHookForm(name, null);
                    }
                  }
                }}
                value={value || ''}
              />
              {isErrors?.[name] && (
                <FormHelperText error className="custom-error">
                  {isErrors[name]?.message}{' '}
                </FormHelperText>
              )}
            </>
          );
        }}
      />
    );
  }

  return <ReactQuill {...props} />;
}
