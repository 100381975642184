import { DoctTypography } from 'doct-core';
import React from 'react';
import style from './AddEditFormElement.module.scss';

function FormUIWrapper({ heading, children }) {
  return (
    <div
      className={`mt-4 bg-grey-100 overflow-hidden ${style['add-edit-form-element-radius']} ${style['add-edit-form-element-wrapper']}`}
    >
      <div
        className={`px-4 panel-height-6x d-flex align-items-center ${style['form-ui-heading-bg']}`}
      >
        <DoctTypography variant="h6" fontWeight="regular" className="text-grey-600">
          {heading}
        </DoctTypography>
      </div>
      <div className="p-4 content-with-animated-loader-area">{children}</div>
    </div>
  );
}

export default React.memo(FormUIWrapper);
