import React, { useEffect, useState } from 'react';
import { DoctTextField } from '@docthub.frontend/app';
import { DoctButton, DoctIcon, DoctTypography } from 'doct-core';
import AssignViaCommonCard from '../shared/AssignViaCommonCard';
import AssignDashboardCard from '../shared/AssignDashboardCard';
import Tooltip from '@mui/material/Tooltip';
import AssignViaEventsDashboardPopUp from '../shared/PopUps/AssignViaEventsDashboardPopUp';
import AssignViaMembershipDashboardPopUp from '../shared/PopUps/AssignViaMembershipDashboardPopUp';
import { fileConvertionInBytesToMb, getMimeType } from '../../../../helper/helperFunction';
import { useDispatch } from 'react-redux';
import { setShowToasterWithParams } from '../../../featuresSlice';
import { TOST_TOP_RIGHT } from '../../../../constants/toasterPosition.constants';
import { EMAIL_VALIDATION } from '../../../../constants/constants';
import useAwardeeForm from '../../hooks/Form/useAwaardeeForm';
import { Toaster } from '../../../../shared';
import AssignCertificatePopUp from '../shared/PopUps/AssignCertificatePopUp';
import ViewPreviewCertificate from './ViewPreviewCertificate';
import { setTrackPreviousUrl } from '../../slices/eCertificateSlice';
import { useLocation, useNavigate } from 'react-router';
import { Typography } from '@mui/material';
import DragAndDropFileUploder from '../shared/DragAndDropFileUploder';
import dummyExcelFile from '../shared/ExcelFile/Awardees_Sample_Excel.xlsx';
import useCertificateData from '../../hooks/useGetCertificateData';
import { APIAllPdfDownloadForPopup } from '../../APIs/APIAllPdfDownload.service';

export default function AssignAwardeeCards({
  certificateID,
  sendEmailPopUpHandleClose,
  id,
  toggleNewAwardee,
}) {
  const {
    handleSubmit,
    control,
    errors,
    touched,
    onSubmit,
    sendAllAwardees,
    setSelectExcelFileResponse,
    selectExcelFileResponse,
    openModal,
    setOpenModal,
    showToaster,
    setShowToaster,
    mannualResponse,
    handleUpload,
    handleUploadForMannual,
    isPreview,
    open,
    toggle,
    awardeeImgData,
    awardeeImgName,
    emailLoading,
    pdfLoading,
    laterLoading,
  } = useAwardeeForm(certificateID, id, sendEmailPopUpHandleClose, toggleNewAwardee);

  const [openEventMenu, setOpenEventMenu] = useState(false);
  const [openMembershipMenu, setOpenMembershipMenu] = useState(false);
  const [generateCertificateForExcel, setGenerateCertificateForExcel] = useState(false);
  const [isRemoved, setIsRemoved] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [fileSizeError, setFileSizeError] = useState(false);

  useEffect(() => {
    dispatch(setTrackPreviousUrl('showUpload'));
  }, [location.pathname]);

  // const handleUploadXlsx = async (uploadedFile) => {
  //   const fileExtension = uploadedFile[0]?.name?.toString()?.split('.');
  //   const lastElement = fileExtension[fileExtension?.length - 1];
  //   const fileSizeInMB = await fileConvertionInBytesToMb(uploadedFile[0]);
  //   if (fileSizeInMB > 20) {
  //     setFileSizeError(true);
  //     return;
  //   }

  //   const fileType = getMimeType(uploadedFile[0]);
  //   if (fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
  //     dispatch(
  //       setShowToasterWithParams({
  //         variant: 'danger',
  //         position: TOST_TOP_RIGHT,
  //         message: 'Invalid File Type!',
  //       }),
  //     );
  //     return;
  //   }

  //   try {
  //     if (!['xlsx'].includes(lastElement.toLowerCase())) {
  //       dispatch(
  //         setShowToasterWithParams({
  //           variant: 'danger',
  //           position: TOST_TOP_RIGHT,
  //           message: 'Invalid File Type!',
  //         }),
  //       );
  //       return;
  //     }
  //     const response = sendAllAwardees(uploadedFile, certificateID || id);
  //     response?.then((res) => {
  //       setSelectExcelFileResponse(res);
  //     });
  //   } catch (error) {
  //     console.error('Error converting PDF to images:', error);
  //     // Handle error appropriately
  //   }
  // };

  const handleUploadXlsx = async (uploadedFile) => {
    const file = uploadedFile[0]; // Assuming only one file is uploaded
    const fileExtension = file?.name?.toString()?.split('.');
    const lastElement = fileExtension[fileExtension?.length - 1];
    const fileSizeInMB = await fileConvertionInBytesToMb(file);

    if (fileSizeInMB > 20) {
      setFileSizeError(true);
      return;
    }

    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      const fileContent = event.target.result;
      const fileType = getMimeType(new Uint8Array(fileContent));

      if (fileType !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        dispatch(
          setShowToasterWithParams({
            variant: 'danger',
            position: TOST_TOP_RIGHT,
            message: 'Invalid File Type!',
          }),
        );
        return;
      }

      if (!['xlsx'].includes(lastElement.toLowerCase())) {
        dispatch(
          setShowToasterWithParams({
            variant: 'danger',
            position: TOST_TOP_RIGHT,
            message: 'Invalid File Type!',
          }),
        );
        return;
      }

      try {
        const response = sendAllAwardees([file], certificateID || id);
        response?.then((res) => {
          const invalidEntries = res?.awardeeExcel?.filter((entry) => !entry.name || !entry.email);
          if (invalidEntries.length > 0) {
            dispatch(
              setShowToasterWithParams({
                variant: 'danger',
                position: TOST_TOP_RIGHT,
                message:
                  'Excel is not in a proper format. Name and email are required in every entry.',
              }),
              setSelectExcelFileResponse([]),
            );
          } else {
            setSelectExcelFileResponse(res);
          }
        });
      } catch (error) {
        console.error('Error converting PDF to images:', error);
      }
    };

    fileReader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    setTimeout(() => {
      closeToast();
    }, 3000);
  }, [selectExcelFileResponse.Status === 400]);

  const closeToast = () => {
    setFileSizeError(false);
    setSelectExcelFileResponse([]);
  };

  //Note :- This Remove Excel Data is not store in database if we need other functionality on remove then we need API for this remove Excel data.
  const handleRemoveButtonClick = () => {
    setIsRemoved(!isRemoved);
    setSelectExcelFileResponse([]);
  };

  const [downloadAllInOnePdfLoading, setDownloadAllInOnePdfLoading] = useState(false);

  const certificateData = useCertificateData();

  const individualCertificateID = location?.search?.includes('id')
    ? location?.search?.split('=')[1]
    : certificateID;
  const downloadAllPDF = () => {
    setDownloadAllInOnePdfLoading(true);
    const response = APIAllPdfDownloadForPopup(
      individualCertificateID?.length ? individualCertificateID : String(certificateData[0]?.id),
    );
    response.then((res) => {
      if (res?.status) {
        setDownloadAllInOnePdfLoading(false);
        setOpenModal(false);
        toggleNewAwardee(false);
      }
    });
  };

  return (
    <>
      {isPreview && (
        <ViewPreviewCertificate
          open={open}
          handleClose={toggle}
          certificateImg={awardeeImgData}
          certificateImgName={awardeeImgName}
        />
      )}
      {fileSizeError && (
        <div className="position-fixed tost-container-top-right">
          <Toaster
            variant={'error'}
            text={'file size shoould not be more then 20 mb'}
            onPressedClose={closeToast}
          />
        </div>
      )}

      {selectExcelFileResponse.Status === 400 && (
        <div className="position-fixed tost-container-top-right">
          <Toaster
            variant={'error'}
            text={`${selectExcelFileResponse.Title}`}
            onPressedClose={closeToast}
          />
        </div>
      )}
      <div className="d-flex assign-via-cards-max-width">
        <AssignViaCommonCard title="Manually" subtitle="Enter awardees details to assign:">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DoctTextField
              className="mt-12px"
              name="name"
              label="Full Name"
              showStar={true}
              id="name"
              control={control}
              isErrors={errors}
              validationRules={{
                required: "It's a required field",
                pattern: {
                  value: /^(?!\s+$).+/,
                  message: 'It should not contain only blank spaces',
                },
              }}
              touched={touched}
            />

            <DoctTextField
              className="mt-12px"
              name="email"
              label="Email Address"
              showStar={true}
              id="email"
              control={control}
              isErrors={errors}
              validationRules={{
                required: "It's required",

                pattern: {
                  value: EMAIL_VALIDATION,
                  message: 'Email is not valid',
                },
              }}
              touched={touched}
            />
            <DoctTextField
              showStar={false}
              className="mt-12px"
              name="mobileNumber"
              label="Mobile Number"
              id="mobileNumber"
              control={control}
              isErrors={errors}
              validationRules={{
                required: false,
                minLength: {
                  value: 10,
                  message: 'Mobile No is not valid',
                },
                maxLength: {
                  value: 10,
                  message: 'Mobile No is not valid',
                },
                pattern: {
                  value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  message: 'Mobile No is not valid',
                },
              }}
              touched={touched}
            />
            <div className="d-flex justify-content-between mt-3">
              <DoctButton
                className="text-grey-600 assign-via-grey-button assign-card-button"
                text="Preview"
                variant="contained"
                size="medium"
                onButtonClickHandler={(e) => {
                  e.preventDefault();
                  handleSubmit(onSubmit)('preview');
                }}
              />
              <DoctButton
                className="select-file-button assign-card-button"
                text="Generate Certificate"
                variant="contained"
                size="medium"
                onButtonClickHandler={handleSubmit(onSubmit)}
              />
            </div>
          </form>
          {/* {showToaster && (
            <div className="position-fixed tost-container-top-right">
              <Toaster
                variant={'positive'}
                text={'Sent Manual Data successfully!'}
                onPressedClose={() => setShowToaster(false)}
              />
            </div>
          )} */}
        </AssignViaCommonCard>
        <AssignViaCommonCard title="Import names" subtitle="Upload excel sheet with bulk names:">
          {selectExcelFileResponse?.awardeeExcel?.length > 0 && (
            <>
              <div
                className={`${
                  isRemoved ? 'clicked-remove-button' : 'bg-white'
                } ready-assign-card mt-12px`}
              >
                <div className="d-flex justify-content-between mb-2">
                  <DoctTypography variant="h5" className="text-grey-800">
                    {selectExcelFileResponse?.awardeesCount}
                  </DoctTypography>
                  <DoctButton
                    className="text-white remove-button-bg"
                    text="REMOVE"
                    variant="contained"
                    size="small"
                    onButtonClickHandler={handleRemoveButtonClick}
                  />
                </div>

                <DoctTypography variant="textLabel1" className={`text-grey-600 m-0`}>
                  Records added,
                  <br />
                  and ready to assign.
                </DoctTypography>
              </div>
            </>
          )}
          {selectExcelFileResponse?.length <= 0 && (
            <DragAndDropFileUploder handleUpload={handleUploadXlsx}>
              <div className={`clicked-remove-button mt-12px`}>
                <div className="text-center file-uploader-bg">
                  <div className="mb-2 text-grey-600 subtitle-2">
                    Drag and Drop your excel file here
                  </div>
                  <div className="d-flex align-items-center justify-content-center">
                    <label
                      style={{ border: '2px solid rgb(67 137 255 / 43%)', width: '108px' }}
                      className="text-info doct-button doct-medium-button text-center align-items-center justify-content-center py-2 cursor-pointer"
                    >
                      <div className="mr-4">
                        <input
                          type="file"
                          hidden
                          name="imageProfile"
                          onChange={async (e) => {
                            e.preventDefault();
                            handleUploadXlsx(e.target.files);
                          }}
                          onClick={(event) => {
                            event.target.value = null;
                          }}
                          accept=".xlsx"
                        />
                      </div>
                      <div>Select File</div>
                    </label>
                  </div>
                </div>
              </div>
            </DragAndDropFileUploder>
          )}
          <DoctButton
            className={`${
              selectExcelFileResponse?.awardeesCount && 'select-file-button'
            } text-white mt-12px w-100`}
            text="Generate Certificate"
            variant="contained"
            size="medium"
            onButtonClickHandler={() => {
              setOpenModal(true);
            }}
            disabled={!selectExcelFileResponse?.awardeesCount}
          />
          {openModal && (
            <AssignCertificatePopUp
              firstButtonText="Send Certificate to All via Email"
              middleButtonText="Download All-in-one PDF"
              lastButtonText="Will Do Later"
              emailLoading={emailLoading}
              pdfLoading={downloadAllInOnePdfLoading}
              laterLoading={laterLoading}
              open={openModal}
              handleExcelPopUpClose={() => {
                setOpenModal(false);
                toggleNewAwardee(false);
                setGenerateCertificateForExcel(true);
                handleUpload('WillDoLater');
                navigate(`/dashboard/e-certificate/all-awardees-list?id=${certificateID || id}`);
                if (typeof handleClose === 'function') {
                  sendEmailPopUpHandleClose();
                }
              }}
              generateCertificateForExcel={generateCertificateForExcel}
              handleUploadExcelEmailShare={() => handleUpload('SendEmail')}
              handleUploadExcelWillDoLater={() => handleUpload('WillDoLater')}
              handleUploadExcelFileShare={downloadAllPDF}
              assignTo={mannualResponse?.name}
            />
          )}

          {showToaster && (
            <AssignCertificatePopUp
              shareButtonHide
              firstButtonText="Send Email"
              middleButtonText="Share"
              lastButtonText="Will Do Later"
              emailLoading={emailLoading}
              pdfLoading={pdfLoading}
              laterLoading={laterLoading}
              open={showToaster}
              handleManualPopUpClose={() => {
                setShowToaster(false);
                toggleNewAwardee(false);
                // handleUpload('WillDoLater');
                navigate(`/dashboard/e-certificate/all-awardees-list?id=${certificateID || id}`);
                if (typeof handleClose === 'function') {
                  sendEmailPopUpHandleClose();
                }
              }}
              handleUploadExcelEmailShare={() => handleUploadForMannual('SendEmail')}
              handleUploadExcelWillDoLater={() => handleUploadForMannual('WillDoLater')}
              // handleUploadExcelFileShare={() => handleUploadForMannual('ShareEmail')}
              assignTo={mannualResponse?.name}
            />
          )}

          <div className="d-flex align-items-center mt-2">
            <a href={dummyExcelFile} download className="w-100">
              <DoctButton
                className="text-grey-600 assign-via-grey-button w-100"
                text="Get Sample Excel File"
                variant="contained"
                size="medium"
              />
            </a>
            <Tooltip
              arrow
              placement="top"
              sx={{ backgroundColor: '#002830 !important' }}
              title={
                <Typography
                  sx={{
                    backgroundColor: '#002830 !important',
                    color: '#FFFFFF',
                    borderRadius: '8px',
                    padding: '10px',
                    margin: '0px',
                  }}
                >
                  Download our sample excel file, replace <br />
                  dummy data with your original data. <br />
                  Upload the file here to import bulk <br /> names.
                </Typography>
              }
            >
              <div className="border-circle border-circle-grey pt-1 ml-1 pr-1">
                <DoctIcon
                  className="ml-1 cursor-pointer"
                  fill="#717171"
                  width="24"
                  height="24"
                  color="primary"
                  name="infoOutline"
                />
              </div>
            </Tooltip>
          </div>
        </AssignViaCommonCard>
        <AssignViaCommonCard title="Dashboards" className="mr-n3">
          <AssignDashboardCard
            name="Events Dashboard"
            iconName="eventEnterpriseFilled"
            className="mt-3"
            onClick={() => setOpenEventMenu(true)}
          />
          <AssignDashboardCard
            name="Memberships"
            iconName="membershipOutline"
            onClick={() => setOpenMembershipMenu(true)}
          />
          <AssignDashboardCard
            name="Recruiter Dashboard"
            iconName="jobsEnterpriseFilled"
            disabled
          />
          <AssignDashboardCard
            name="Institute Dashboard"
            iconName="courseEnterpriseFilled"
            disabled
          />
        </AssignViaCommonCard>
      </div>
      <AssignViaEventsDashboardPopUp setOpenMenu={setOpenEventMenu} openMenu={openEventMenu} />
      <AssignViaMembershipDashboardPopUp
        setOpenMenu={setOpenMembershipMenu}
        openMenu={openMembershipMenu}
      />
    </>
  );
}
