import { Autocomplete, TextField } from '@mui/material';
import React from 'react';

const DoctFreeSoloAutoCompleteWithChips = ({
  register,
  errors,
  field,
  value,
  options = [],
  handleChange,
  freeSolo = false,
  label,
  validationRules,
  className,
  getOptionDisabled,
}) => {
  return (
    <Autocomplete
      className={className}
      freeSolo={freeSolo}
      blurOnSelect
      value={value || null}
      options={options}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.label || '')}
      isOptionEqualToValue={(option, val) => option.value === val?.value}
      getOptionDisabled={getOptionDisabled}
      onInputChange={(_, newInputValue) => handleChange(newInputValue, field)}
      onChange={(_, newValue) => handleChange(newValue, field)}
      renderInput={(params) => (
        <TextField
          {...params}
          {...register(field, {
            ...validationRules,
          })}
          label={label}
          error={!!errors[field]}
          helperText={errors[field]?.message}
          sx={{
            '.MuiFormLabel-root': {
              fontSize: '14px',
              color: '#717171 !important',
              fontWeight: '400',
              lineHeight: '16px',
              zIndex: '1',
              transition: 'all 0.2s ease-in-out',
              pointerEvents: 'none',
              '&.Mui-focused': {
                transform: 'translate(12px, 9px) scale(0.75)',
                fontSize: '14px !important',
                color: 'rgb(0, 160, 192) !important',
              },
              '&.Mui-error': {
                color: '#d32f2f !important',
              },
              ...(value && {
                transform: 'translate(12px, 9px) scale(0.75)',
                fontSize: '14px !important',
                color: '#717171 !important',
                '&.Mui-focused': {
                  color: 'rgb(0, 160, 192) !important',
                },
              }),
            },
            '.MuiInputBase-root': {
              marginTop: '0',
              padding: '13px 12px 5px !important',
              backgroundColor: 'rgba(255, 255, 255, 0.6) !important',
              height: '46px !important',
              borderRadius: '0 !important',
              position: 'relative',
              transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1)',
              '&::before': {
                content: '""',
                position: 'absolute',
                left: '0',
                right: '0',
                bottom: '0',
                height: '1px',
                backgroundColor: '#e3e3e3',
                transition: 'background-color 0.2s ease-in-out, height 0.2s ease-in-out',
              },
              '&:hover::before': {
                backgroundColor: 'rgb(0, 160, 192)',
                height: '2px',
              },
              '&.Mui-focused::before': {
                backgroundColor: 'rgb(0, 160, 192)',
                height: '2px',
              },
              '&.Mui-error::before': {
                backgroundColor: '#e3e3e3',
                height: '1px',
              },
              '&.Mui-error:hover::before': {
                backgroundColor: '#d32f2f',
                height: '1px',
              },
              '.MuiInputBase-input': {
                paddingLeft: '0 !important',
                paddingRight: '30px !important',
                paddingBottom: '0px !important',
                fontSize: '14px !important',
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
              },
            },
            '.MuiFormHelperText-root': {
              fontSize: '11px',
              lineHeight: '14px',
              marginTop: '4px',
            },
          }}
        />
      )}
    />
  );
};

export default DoctFreeSoloAutoCompleteWithChips;
