import React from 'react';
import { DoctCol, DoctIconButton, DoctRow, DoctTypography } from 'doct-core';
import { useFieldArray } from 'react-hook-form';
import { DoctTextField } from '@docthub.frontend/app';

function AdditionalContactInfoSection({ control, countryCode = '+91', errors, touched }) {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'ContactInformation.AdditionalDetails',
  });

  const handleAddDetail = () => {
    append({ contactLabel: '', mobileNumber: '', mobileCountryCode: countryCode });
  };

  const handleRemoveDetail = (index) => {
    remove(index);
  };

  return (
    <>
      <DoctRow>
        <DoctCol sm={10}>
          <div className="pb-5">
            <DoctTypography variant="h6" className="font-weight-medium text-grey-800">
              Additional Contact Information{' '}
              <span className="text-grey-600 text-label-1">(optional)</span>
            </DoctTypography>
            {fields.length > 0 ? (
              fields.map((field, index) => {
                return (
                  <div key={field.id} className="mb-3">
                    <DoctTextField
                      name={`ContactInformation.AdditionalDetails.${index}.contactLabel`}
                      label="Contact Person Name"
                      className="mt-3"
                      id={`ContactInformation.AdditionalDetails.${index}.contactLabel`}
                      control={control}
                      isErrors={{
                        [`ContactInformation.AdditionalDetails.${index}.contactLabel`]:
                          errors?.ContactInformation?.AdditionalDetails?.[index]?.contactLabel,
                      }}
                      defaultValue={field.contactLabel || ''}
                      validationRules={{
                        required: "It's Required Field",
                        maxLength: {
                          value: 20,
                          message: 'Name must not exceed 20 characters',
                        },
                        validate: {
                          noNumbers: (value) => !/\d/.test(value),
                        },
                      }}
                      touched={touched}
                      showStar={false}
                    />
                    <div className="d-flex align-items-center mt-2">
                      <div className="input-column-tiny mt-2 w-25 mr-12px">
                        <DoctTextField
                          name={`ContactInformation.AdditionalDetails.${index}.mobileCountryCode`}
                          label="Code"
                          id={`ContactInformation.AdditionalDetails.${index}.mobileCountryCode`}
                          control={control}
                          isErrors={errors}
                          touched={touched}
                          validationRules={{}}
                          onEndScroll={() => null}
                          defaultValue={field.mobileCountryCode || countryCode}
                          disabled
                        />
                      </div>
                      <DoctTextField
                        name={`ContactInformation.AdditionalDetails.${index}.mobileNumber`}
                        className="mt-2"
                        label="Mobile Number"
                        id={`ContactInformation.AdditionalDetails.${index}.mobileNumber`}
                        control={control}
                        isErrors={{
                          [`ContactInformation.AdditionalDetails.${index}.mobileNumber`]:
                            errors?.ContactInformation?.AdditionalDetails?.[index]?.mobileNumber,
                        }}
                        defaultValue={field.mobileNumber || ''}
                        validationRules={{
                          required: "It's Required Field",
                          minLength: {
                            value: 10,
                            message: 'Mobile No is not valid',
                          },
                          maxLength: {
                            value: 10,
                            message: 'Mobile No is not valid',
                          },
                          pattern: {
                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                            message: 'Mobile No is not valid',
                          },
                        }}
                        showStar={false}
                        touched={touched}
                      />
                      <div className="delete-danger-btn">
                        <DoctIconButton
                          onButtonClickHandler={() => handleRemoveDetail(index)}
                          icon="delete"
                          variant="filled"
                          type="semantic-danger"
                          size="large"
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No additional contact details yet. Click Add Contact to start.</p>
            )}
            <button type="button" onClick={handleAddDetail} className="btn btn-primary mt-3">
              Add Contact
            </button>
          </div>
        </DoctCol>
      </DoctRow>
    </>
  );
}

export default AdditionalContactInfoSection;
