import React from 'react';
import AssignCertificateViaDashboard from '../AssignCertificateViaDashboard';
import { DoctAutoComplete, DoctForm } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';

export default function AssignViaEventsDashboardPopUp({ setOpenMenu, openMenu }) {
  const { control, errors } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });
  return (
    <AssignCertificateViaDashboard
      openMenu={openMenu}
      setOpenMenu={setOpenMenu}
      iconName="eventEnterpriseFilled"
      name="Events Dashboard"
    >
      <DoctTypography variant="subtitle2" className="text-grey-800">
        Select Assignee from Events:
      </DoctTypography>
      <DoctAutoComplete
        label="Select Event*"
        isErrors={errors}
        id="selectEvent"
        name="selectEvent"
        variant="standard"
        control={control}
        validationRules={{ required: "It's Required Field" }}
        options={[
          { label: '1', value: '1' },
          { label: '2', value: '2' },
        ]}
      />
      <div className="d-flex justify-content-between mt-12px mb-12px">
        <DoctAutoComplete
          label="Select Attendee Type*"
          isErrors={errors}
          id="selectAttendee"
          name="selectAttendee"
          variant="standard"
          control={control}
          validationRules={{ required: "It's Required Field" }}
          options={[
            { label: '1', value: '1' },
            { label: '2', value: '2' },
          ]}
          className="select-attendee-type"
        />
        <DoctAutoComplete
          label="Attendee’s Visiting Status*"
          isErrors={errors}
          id="status"
          name="status"
          variant="standard"
          control={control}
          validationRules={{ required: "It's Required Field" }}
          options={[
            { label: '1', value: '1' },
            { label: '2', value: '2' },
          ]}
          className="select-attendee-type"
        />
      </div>
    </AssignCertificateViaDashboard>
  );
}
