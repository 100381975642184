import React from 'react';
import { DoctAnimationLoading, DoctChip } from '@docthub.frontend/app';
import { DoctButton, DoctCol, DoctIcon, DoctRow, DoctTypography } from 'doct-core';
import { useDispatch, useSelector } from 'react-redux';
import './PreviewPublish.scss';
import { FixedPanel, Toaster } from '../../../../../../shared/ui';
import * as ROUTE from '../../../../../../routes/constant';
import {
  fetchEventById,
  saveOnSubmitDraftBtnDisable,
  saveStepOnPreviewPage,
  selectBasicInfoDetails,
  selectCreateEventResponse,
  selectCurrentStep,
  selectIsPageBlockOnPreview,
  selectPaymentAndInvoice,
  selectRegistartionDetails,
  selectResponseData,
  selectScheduleAndSpeakersDetails,
  selectShowError,
  selectSubmissionFailed,
  selectTermsAndPoliciesDetails,
  selectTicketsDetails,
  selectUpdateEventLoader,
  setCurrentStep,
  setShowError,
} from '../../../createEvent.slice';
import { StepTitle } from '../../UiHelper';
import stepsName from '../stepsName';
import useFormPreviewPublish from './Form.PreviewPublish';
import PreviewPublishCard from './components/PreviewPublishCard';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { scheduleFromStartDateEndDate } from '../../../../../../helper/helperFunction';

export default function PreviewPublish() {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const createdEventResponse = useSelector(selectResponseData);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const registrationResponse = useSelector(selectRegistartionDetails);
  const [responseRecords, setResponseRecords] = useState([]);
  const [erorFormSubmit, setErrorFormSubmit] = useState(null);
  const [showTost, setShowTost] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const loading = useSelector(selectUpdateEventLoader);
  const [totalDayCount, seTotalDayCount] = useState([]);
  const submissionFailed = useSelector(selectSubmissionFailed);

  const BasicInfoDetails = useSelector(selectBasicInfoDetails);
  const registartionDetails = useSelector(selectRegistartionDetails);
  const tickets = useSelector(selectTicketsDetails);
  const schedules = useSelector(selectScheduleAndSpeakersDetails);
  const paymentsAndInvoice = useSelector(selectPaymentAndInvoice);
  const teamsAndPolicies = useSelector(selectTermsAndPoliciesDetails);
  const isPageBlockOnPreview = useSelector(selectIsPageBlockOnPreview);

  useEffect(() => {
    if (Object.keys(registrationResponse).length) {
      if (registrationResponse?.coverImageFile) {
        setCoverImage(window.URL.createObjectURL(registrationResponse.coverImageFile));
      }
    }
  }, [Object.keys(registrationResponse).length]);

  useEffect(() => {
    const newResponseArray = [];
    if (Object.keys(apiResponseData).length) {
      newResponseArray.push(apiResponseData);
      const prepareDateArray = scheduleFromStartDateEndDate(
        apiResponseData.startDate,
        apiResponseData.endDate,
      );
      seTotalDayCount(prepareDateArray);
    }
    setResponseRecords(newResponseArray);
  }, [apiResponseData]);

  useEffect(() => {
    if (createdEventResponse?.id == undefined) return;
    dispatch(fetchEventById(createdEventResponse?.id));
  }, [createdEventResponse?.id]);

  const { handleFormSubmit } = useFormPreviewPublish();

  const showError = useSelector(selectShowError);
  // const stepBlocked = useSelector(selectStepBlocked);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowError(false));
    }, 2000);
  }, [showError]);

  useEffect(() => {
    if (showTost) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [showTost]);

  const onTostCloseHandler = () => {
    setShowTost(false);
    setErrorFormSubmit(null);
  };

  const USDTicketRecords = (item) => {
    const usdRecords = item?.filter(({ currency }) => currency == 'USD');
    return usdRecords;
  };

  const INRUSDTicketRecords = (item) => {
    const inrRecords = item?.filter(({ currency }) => currency == 'USD' || currency == 'INR');

    const curr = inrRecords?.map((item) => {
      return item?.currency;
    });

    return [...new Set(curr)];
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        {showError && (
          <div className="position-fixed tost-container">
            <Toaster
              variant={'error'}
              text={erorFormSubmit?.Title || 'Oops! something went wrong'}
              onPressedClose={onTostCloseHandler}
            />
          </div>
        )}
        {responseRecords?.length == 0 && (
          <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
            <DoctAnimationLoading />
          </div>
        )}
        {isPageBlockOnPreview == false ? (
          <DoctRow>
            {responseRecords?.map((item) => {
              return (
                <>
                  <DoctCol xs={3} className="mx-auto">
                    <StepTitle>{stepsName.previewAndPublish.label}</StepTitle>
                    <div className="d-flex column_gap_between_chips_item">
                      <DoctChip title={item.type} showCloseIcon={false} />
                      <DoctChip
                        title={`${item.eventPaymentType} Event`}
                        className="ml-2"
                        showCloseIcon={false}
                      />
                      <DoctChip title={'Partnered Event'} showCloseIcon={false} />
                    </div>
                    <div className="d-flex column_gap_between_chips_item mt-1">
                      <DoctChip
                        title={`${totalDayCount?.length} Days event`}
                        showCloseIcon={false}
                      />
                      <DoctChip
                        title={`${
                          INRUSDTicketRecords(item?.tickets)?.length > 1
                            ? 'Indian + Foreign Registration'
                            : USDTicketRecords(item?.tickets)?.length
                            ? 'Foreign Registration'
                            : 'Indian Registration'
                        }`}
                        showCloseIcon={false}
                      />
                    </div>
                    <DoctTypography variant="body2" className="text-grey-600">
                      Confirm all the information.
                    </DoctTypography>
                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.eventPaymentType || BasicInfoDetails?.type
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.eventPaymentType || BasicInfoDetails?.type ? '#00C752' : '#AAAAAA'
                        }
                        className={
                          item?.eventPaymentType || BasicInfoDetails?.type
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.eventPaymentType || BasicInfoDetails?.type
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Basic Info
                      </DoctTypography>
                    </div>
                    <div className="d-flex">
                      <DoctIcon
                        name={item?.name || registartionDetails?.name ? 'roundDone' : 'exclamation'}
                        fill={item?.name || registartionDetails?.name ? '#00C752' : '#AAAAAA'}
                        className={
                          item?.name || registartionDetails?.name ? 'icon_done' : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.name || registartionDetails?.name
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Registration info
                      </DoctTypography>
                    </div>
                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.organizer?.name || registartionDetails?.Name
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.organizer?.name || registartionDetails?.Name ? '#00C752' : '#AAAAAA'
                        }
                        className={
                          item?.organizer?.name || registartionDetails?.Name
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.organizer?.name || registartionDetails?.Name
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Organizer info
                      </DoctTypography>
                    </div>
                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.contactInformation?.personName ||
                          registartionDetails?.ContactInformation?.personName
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.contactInformation?.personName ||
                          registartionDetails?.ContactInformation?.personName
                            ? '#00C752'
                            : '#AAAAAA'
                        }
                        className={
                          item?.contactInformation?.personName ||
                          registartionDetails?.ContactInformation?.personName
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.contactInformation?.personName ||
                          registartionDetails?.ContactInformation?.personName
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Contact info
                      </DoctTypography>
                    </div>
                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.tickets?.length || tickets?.tickets?.length
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.tickets?.length || tickets?.tickets?.length ? '#00C752' : '#AAAAAA'
                        }
                        className={
                          item?.tickets?.length || tickets?.tickets?.length
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.tickets?.length || tickets?.tickets?.length
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Tickets Registration
                      </DoctTypography>
                    </div>
                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.brochureUrl || registartionDetails?.name
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.brochureUrl || registartionDetails?.name ? '#00C752' : '#AAAAAA'
                        }
                        className={
                          item?.brochureUrl || registartionDetails?.name
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.brochureUrl || registartionDetails?.name
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Upload Registration form
                      </DoctTypography>
                    </div>
                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.schedules?.some((schedule) => schedule.scheduleSlots.length > 0) ||
                          schedules?.schedules?.some(
                            (schedule) => schedule.scheduleSlots.length > 0,
                          )
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.schedules?.some((schedule) => schedule.scheduleSlots.length > 0) ||
                          schedules?.schedules?.some(
                            (schedule) => schedule.scheduleSlots.length > 0,
                          )
                            ? '#00C752'
                            : '#AAAAAA'
                        }
                        className={
                          item?.schedules?.some((schedule) => schedule.scheduleSlots.length > 0) ||
                          schedules?.schedules?.some(
                            (schedule) => schedule.scheduleSlots.length > 0,
                          )
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.schedules?.some((schedule) => schedule.scheduleSlots.length > 0) ||
                          schedules?.schedules?.some(
                            (schedule) => schedule.scheduleSlots.length > 0,
                          )
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Schedule, Sessions
                      </DoctTypography>
                    </div>
                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.keySpeakers?.length || schedules?.keySpeakers?.length
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.keySpeakers?.length || schedules?.keySpeakers?.length
                            ? '#00C752'
                            : '#AAAAAA'
                        }
                        className={
                          item?.keySpeakers?.length || schedules?.keySpeakers?.length
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.keySpeakers?.length || schedules?.keySpeakers?.length
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Speakers/ Presenters
                      </DoctTypography>
                    </div>
                    {(item?.eventPaymentType !== 'Free' ||
                      BasicInfoDetails?.registrationType !== 'Free') && (
                      <>
                        <div className="d-flex">
                          <DoctIcon
                            name={
                              item?.paymentSettlement?.accountHolderName ||
                              paymentsAndInvoice?.paymentSettlement?.PaymentCountry
                                ? 'roundDone'
                                : 'exclamation'
                            }
                            fill={
                              item?.paymentSettlement?.accountHolderName ||
                              paymentsAndInvoice?.paymentSettlement?.PaymentCountry
                                ? '#00C752'
                                : '#AAAAAA'
                            }
                            className={
                              item?.paymentSettlement?.accountHolderName ||
                              paymentsAndInvoice?.paymentSettlement?.PaymentCountry
                                ? 'icon_done'
                                : 'icon_exclamation'
                            }
                          ></DoctIcon>
                          <DoctTypography
                            className={
                              item?.paymentSettlement?.accountHolderName ||
                              paymentsAndInvoice?.paymentSettlement?.PaymentCountry
                                ? 'text-grey-400 mt-0 px-1'
                                : 'text-grey-800 font-weight-medium mt-0 px-1'
                            }
                            variant="textLabel1"
                          >
                            Payment Settlement
                          </DoctTypography>
                        </div>
                        <div className="d-flex">
                          <DoctIcon
                            name={
                              item?.invoiceDetail?.registrationName ||
                              paymentsAndInvoice?.invoiceDetail?.registrationName
                                ? 'roundDone'
                                : 'exclamation'
                            }
                            fill={
                              item?.invoiceDetail?.registrationName ||
                              paymentsAndInvoice?.invoiceDetail?.registrationName
                                ? '#00C752'
                                : '#AAAAAA'
                            }
                            className={
                              item?.invoiceDetail?.registrationName ||
                              paymentsAndInvoice?.invoiceDetail?.registrationName
                                ? 'icon_done'
                                : 'icon_exclamation'
                            }
                          ></DoctIcon>
                          <DoctTypography
                            className={
                              item?.invoiceDetail?.registrationName ||
                              paymentsAndInvoice?.invoiceDetail?.registrationName
                                ? 'text-grey-400 mt-0 px-1'
                                : 'text-grey-800 font-weight-medium mt-0 px-1'
                            }
                            variant="textLabel1"
                          >
                            Invoice Details
                          </DoctTypography>
                        </div>
                      </>
                    )}

                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.metaData?.termsAndCondition ||
                          teamsAndPolicies?.metaData?.termsAndCondition
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.metaData?.termsAndCondition ||
                          teamsAndPolicies?.metaData?.termsAndCondition
                            ? '#00C752'
                            : '#AAAAAA'
                        }
                        className={
                          item?.metaData?.termsAndCondition ||
                          teamsAndPolicies?.metaData?.termsAndCondition
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.metaData?.termsAndCondition ||
                          teamsAndPolicies?.metaData?.termsAndCondition
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Terms & Policies
                      </DoctTypography>
                    </div>
                    <div className="d-flex">
                      <DoctIcon
                        name={
                          item?.metaData?.cancellationPolicy ||
                          teamsAndPolicies?.metaData?.cancellationPolicy
                            ? 'roundDone'
                            : 'exclamation'
                        }
                        fill={
                          item?.metaData?.cancellationPolicy ||
                          teamsAndPolicies?.metaData?.cancellationPolicy
                            ? '#00C752'
                            : '#AAAAAA'
                        }
                        className={
                          item?.metaData?.cancellationPolicy ||
                          teamsAndPolicies?.metaData?.cancellationPolicy
                            ? 'icon_done'
                            : 'icon_exclamation'
                        }
                      ></DoctIcon>
                      <DoctTypography
                        className={
                          item?.metaData?.cancellationPolicy ||
                          teamsAndPolicies?.metaData?.cancellationPolicy
                            ? 'text-grey-400 mt-0 px-1'
                            : 'text-grey-800 font-weight-medium mt-0 px-1'
                        }
                        variant="textLabel1"
                      >
                        Cancellation & Refund Policy
                      </DoctTypography>
                    </div>
                  </DoctCol>
                </>
              );
            })}

            {responseRecords?.map((item, index) => {
              return (
                <div className="mx-auto" key={index}>
                  <PreviewPublishCard
                    image={coverImage ? coverImage : '' || item?.image?.coverImageUrl}
                    eventstype={item.type}
                    specialities={item.specialities}
                    eventname={item.name}
                    location={`${item.venue.city}, ${item.venue.country}`}
                    date={`${dayjs(item.startDate).format('D MMM YYYY')} - ${dayjs(
                      item.endDate,
                    ).format('D MMM YYYY')}`}
                    eventsby={`by ${item.organizer.name}`}
                    views={`${item.views} views`}
                    // interested={item.interested}
                    regstatus={`${item.activePriceType?.name} registration ends on ${dayjs(
                      item.activePriceType?.validTill,
                    ).format('DD MMM YYYY')}`}
                  />
                </div>
              );
            })}
          </DoctRow>
        ) : (
          <>
            {loading == true ? (
              <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
                <DoctAnimationLoading />
              </div>
            ) : (
              <div className="ml-5 pl-5">
                {submissionFailed === true ? (
                  <DoctTypography
                    variant="h6"
                    className={`my-0 mr-auto semantic-danger mt-4 text-danger`}
                  >
                    Submission Failed
                  </DoctTypography>
                ) : (
                  <>
                    <DoctTypography
                      variant="h6"
                      className={`my-0 mr-auto semantic-success mt-4 text-success`}
                    >
                      Submitted Successfully
                    </DoctTypography>
                    <DoctTypography
                      variant="subtitle2"
                      className={`text-grey-600 my-0 mr-auto mt-4`}
                    >
                      Your Event is under review, it will be published shortly. For insights go to
                      ``Dashboard`` tab.
                    </DoctTypography>
                  </>
                )}
                <DoctRow>
                  {responseRecords?.map((item, index) => {
                    return (
                      <div className="mb-5" key={index}>
                        <PreviewPublishCard
                          image={coverImage ? coverImage : '' || item?.image?.coverImageUrl}
                          eventstype={item.type}
                          specialities={item.specialities}
                          eventname={item.name}
                          location={`${item.venue.city}, ${item.venue.country}`}
                          date={`${dayjs(item.startDate).format('D MMM YYYY')} - ${dayjs(
                            item.endDate,
                          ).format('D MMM YYYY')}`}
                          eventsby={`by ${item.organizer.name}`}
                          views={`${item.views} views`}
                          // interested={item.interested}
                          regstatus={`${
                            item.activePriceType?.name ? item.activePriceType?.name : ''
                          } registration ends on ${dayjs(item.activePriceType?.validTill).format(
                            'DD MMM YYYY',
                          )}`}
                        />
                      </div>
                    );
                  })}
                </DoctRow>
              </div>
            )}
          </>
        )}
      </form>
      {isPageBlockOnPreview == false ? (
        <FixedPanel
          container
          className="backdrop-filter"
          contentClassName="d-flex align-items-center py-12px"
        >
          <DoctButton
            text="Back"
            variant="outline"
            className="mr-2 ml-auto"
            onButtonClickHandler={() => {
              dispatch(setCurrentStep(currentStep - 1));
            }}
          />
          <DoctButton
            text="Preview"
            variant="outline"
            className="mr-2"
            disabled
            onButtonClickHandler={() => {}}
          />
          <DoctButton
            disabled={loading}
            text="Submit"
            type="semantic-success"
            className=""
            onButtonClickHandler={() => {
              dispatch(saveStepOnPreviewPage(8));
              handleFormSubmit();
              dispatch(saveOnSubmitDraftBtnDisable(true));
            }}
          />
        </FixedPanel>
      ) : (
        <FixedPanel
          container
          className="backdrop-filter bg-success"
          contentClassName="d-flex align-items-center py-12px"
        >
          {loading == false && (
            <>
              {submissionFailed == false && (
                <DoctTypography variant="body1" className={`text-white my-0 mr-auto ml-0`}>
                  Your Event is under review, you will get an email confirmation once your event
                  will go live!
                </DoctTypography>
              )}
            </>
          )}

          <DoctButton
            disabled={loading}
            variant="outlined"
            text="Go to Dashboard"
            type="inverse"
            className={submissionFailed == false ? 'text-white' : 'text-white ml-auto'}
            onButtonClickHandler={() => {
              dispatch(saveOnSubmitDraftBtnDisable(false));
              window.location.pathname = `/${ROUTE.DASHBOARD}/${ROUTE.EVENTS}/${ROUTE.EVENTS_ALL}`;
            }}
          />
        </FixedPanel>
      )}
    </>
  );
}
