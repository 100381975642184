import React from 'react';
import { DoctAnimationLoading, DoctChip } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import { PaginationComponent } from '../../Pagination/Pagination';
import { Chips, ChipsListComponent, getChipsTitle } from '../CustomTable';
import TableHead from './TableHead';
import TableRows from './TableRows';
import qs from 'qs';

const ChipsListing = React.memo(ChipsListComponent);

const CustomTableWithTabWrapper = ({
  resultCount,
  countHeading,
  filterChipsObj,
  handleChipsRemove = () => {},
  transformDisplayChips,
  filterCount,
  query,
  pathname,
  column,
  contentLoading,
  tableRowData,
  onNoDataFound,
  tableCollapsible,
  tableCollapsibleData,
  setSelectedItemId,
  actionMenu,
  actionButton,
  setTriggredDownload,
  navigate,
  setIsOpen,
  activeRowIndex,
  setActiveRowIndex,
  handleRowClickCallback = () => {},
  dropdownMenuItemInStatusWithButton = [],
  dropdownStatusButtonClassesHandler = () => {},
  handleButtonStatusChange = () => {},
  setStatus,
}) => {
  return (
    <div>
      <div
        className="d-flex align-items-center custom-table-result-filter-container 
      flex-wrap mt-2 mb-2"
      >
        {resultCount > 0 && (
          <DoctTypography variant="result-counter" className="text-grey-600  d-flex-center-y mr-3">
            {resultCount} {countHeading}
          </DoctTypography>
        )}

        <ChipsListing
          filterChipsObj={filterChipsObj}
          handleChipsRemove={handleChipsRemove}
          transformDisplayChips={transformDisplayChips}
        />

        {filterCount > 1 && (
          <span
            className="cursor-pointer"
            onClick={() => {
              const cloneQuery = { ...query, pageNumber: 1 };
              Object.keys(filterChipsObj).forEach((key) => {
                delete cloneQuery[key];
              });
              delete cloneQuery.fromDate;
              delete cloneQuery.toDate;
              navigate(`${pathname}?${qs.stringify(cloneQuery)}`);
            }}
          >
            <Chips
              title={'Clear All'}
              showCloseIcon={false}
              // title={filterChipsObj[key]}
            />
          </span>
        )}
        {Object.keys(filterChipsObj).map((key) => {
          if (Array.isArray(filterChipsObj[key])) {
            return filterChipsObj[key].map((nestedItem) => {
              return (
                <span className="mr-2 my-1" key={nestedItem}>
                  <DoctChip
                    title={getChipsTitle(nestedItem)}
                    onCloseHandler={() => {
                      handleChipsRemove(nestedItem, key);
                    }}
                  />
                </span>
              );
            });
          }
          return (
            <span className="mr-2 my-1" key={filterChipsObj[key]}>
              <DoctChip
                title={getChipsTitle(filterChipsObj[key])}
                onCloseHandler={() => {
                  handleChipsRemove(filterChipsObj[key], key);
                }}
              />
            </span>
          );
        })}
      </div>

      {resultCount > 0 && <TableHead column={column} />}

      <div className="position-relative loader-min-height">
        {contentLoading && (
          <div className="content-animated-loader-row page-content-loader transparent-bg adjust-content-animated-loader">
            <DoctAnimationLoading />
          </div>
        )}
        {tableRowData && tableRowData?.length == 0 && !contentLoading && onNoDataFound}
        {tableRowData && tableRowData?.length > 0 && !contentLoading && (
          <div className="table-container">
            <TableRows
              tableRowData={tableRowData}
              column={column}
              collapsibleItem={tableCollapsible}
              tableCollapsibleData={tableCollapsibleData}
              setSelectedItemId={setSelectedItemId}
              actionMenu={actionMenu}
              actionButton={actionButton}
              setTriggredDownload={setTriggredDownload}
              setIsOpen={setIsOpen}
              activeRowIndex={activeRowIndex}
              setActiveRowIndex={setActiveRowIndex}
              handleRowClickCallback={handleRowClickCallback}
              dropdownMenuItemInStatusWithButton={dropdownMenuItemInStatusWithButton}
              dropdownStatusButtonClassesHandler={dropdownStatusButtonClassesHandler}
              handleButtonStatusChange={handleButtonStatusChange}
              setStatus={setStatus}
            />
          </div>
        )}
      </div>

      {!contentLoading && (
        <>
          <PaginationComponent totalRecords={resultCount} PaginationComponent={false} />
        </>
      )}
    </div>
  );
};

export default CustomTableWithTabWrapper;
