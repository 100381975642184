import React from 'react';
import { DoctButton, DoctCol } from 'doct-core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixedPanel, Toaster } from '../../../../../../shared/ui';
import {
  selectCurrentStep,
  selectShowError,
  selectUpdateEventLoader,
  setCurrentStep,
  setShowError,
} from '../../../createEvent.slice';
import CurrentAndTotalSteps from '../../CurrentAndTotalSteps';
import { InvoiceDetails, PaymentSettlement, PayoutCountryAndCurrency } from './Components';
import useFormPaymentsInvoice from './Form.PaymentsInvoice';

export default function PaymentsInvoice() {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const loading = useSelector(selectUpdateEventLoader);
  const [erorFormSubmit, setErrorFormSubmit] = useState(null);
  const [showTost, setShowTost] = useState(false);

  const {
    formName,
    handleFormSubmit,
    touched,
    uploadSignature,
    setUploadSignature,
    uploadRegistration,
    setUploadRegistration,
    watch,
    setValue,
    control,
    errors,
    defaultStateValue,
    resetGSTRegistrationUploadUrl,
    setResetGSTRegistrationUploadUrl,
    setDeleteGSTRegistrationUploadUrl,
    resetSignatureUrl,
    setResetSignatureUrl,
    setDeleteSignatureUrl,
    isSignatureFileError,
    isGSTFileError,
  } = useFormPaymentsInvoice();

  const showError = useSelector(selectShowError);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowError(false));
    }, 2000);
  }, [showError]);

  useEffect(() => {
    if (showTost) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [showTost]);

  const onTostCloseHandler = () => {
    setShowTost(false);
    setErrorFormSubmit(null);
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <DoctCol xs={6} className="mx-auto">
          {showError && (
            <div className="position-fixed tost-container">
              <Toaster
                variant={'error'}
                text={erorFormSubmit?.Title || 'Oops! something went wrong'}
                onPressedClose={onTostCloseHandler}
              />
            </div>
          )}
          <PayoutCountryAndCurrency watch={watch} formName={formName} />
          <div className="horizontal_line_grey position-relative mt-4 mb-4"></div>
          <PaymentSettlement formName={formName} watch={watch} touched={touched} />
          <div className="horizontal_line_grey position-relative mb-4"></div>
          <InvoiceDetails
            formName={formName}
            touched={touched}
            uploadSignature={uploadSignature}
            setUploadSignature={setUploadSignature}
            uploadRegistration={uploadRegistration}
            setUploadRegistration={setUploadRegistration}
            watch={watch}
            control={control}
            errors={errors}
            setValue={setValue}
            defaultStateValue={defaultStateValue}
            resetGSTRegistrationUploadUrl={resetGSTRegistrationUploadUrl}
            setResetGSTRegistrationUploadUrl={setResetGSTRegistrationUploadUrl}
            setDeleteGSTRegistrationUploadUrl={setDeleteGSTRegistrationUploadUrl}
            resetSignatureUrl={resetSignatureUrl}
            setResetSignatureUrl={setResetSignatureUrl}
            setDeleteSignatureUrl={setDeleteSignatureUrl}
            isSignatureFileError={isSignatureFileError}
            isGSTFileError={isGSTFileError}
          />
        </DoctCol>
      </form>
      <FixedPanel
        container
        className="backdrop-filter"
        contentClassName="d-flex align-items-center py-12px"
      >
        <CurrentAndTotalSteps />
        <DoctButton
          text="Back"
          variant="outline"
          className="mr-2"
          onButtonClickHandler={() => {
            dispatch(setCurrentStep(currentStep - 1));
          }}
        />
        <DoctButton
          disabled={loading}
          text="Save & Next"
          className=""
          onButtonClickHandler={() => {
            handleFormSubmit();
          }}
        />
      </FixedPanel>
    </>
  );
}
