import { DoctRow, DoctTypography } from 'doct-core';
import { DoctActionMenu } from '@docthub.frontend/app';
import React from 'react';
import IconTicket from '../../../../../../../../assets/images/Create Events Form/Tickets/IconTicket.svg';
import { setShowToasterWithParams } from '../../../../../../../featuresSlice';
import { useDispatch } from 'react-redux';
import { TOST_TOP_RIGHT } from '../../../../../../../../constants/toasterPosition.constants';
const options = [
  {
    title: 'Duplicate',
  },
  {
    title: 'Edit',
  },
  {
    title: 'Delete',
  },
];

const InrCard = ({
  regtype,
  seats,
  category,
  setIsModalOpen,
  additionalFun,
  deleteRecordHandler,
  index,
  ticketCategory,
  notDefined,
  setIsDuplicateTicket,
  ticketInUse,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="d-flex">
      <div className="py-3">
        <img src={IconTicket} alt="INR" className="px-2" />
      </div>
      <DoctTypography variant="subtitle2" className="p-2 text-grey-600 text-truncate-small-line">
        {regtype}
      </DoctTypography>

      {notDefined && (
        <DoctTypography variant="textLabel2" className="text-grey-600 mb-3 p-2">
          Not Defined
        </DoctTypography>
      )}

      {seats && (
        <DoctTypography variant="textLabel2" className="text-grey-600 mb-3 p-2">
          {seats} Seats
        </DoctTypography>
      )}
      {/* <DoctTypography variant="textLabel2" className="text-white bg-warning mt-sm-n3">
          SOLD OUT
      </DoctTypography> */}
      <DoctRow>
        <div className="d-flex">
          {Object.entries(category || {})?.map(([key, { code, amount }, index]) => {
            return (
              <>
                <DoctTypography variant="textLabel2" className="text-grey-800 px-4" key={index}>
                  {key} <br />
                  <span>{code + ' ' + amount}</span>
                </DoctTypography>
              </>
            );
          })}
        </div>
        <DoctActionMenu
          btnType="inverse"
          options={options}
          onClick={(item) => {
            if (item.title == 'Duplicate') {
              if (ticketCategory == undefined) {
                setIsModalOpen(false);
              } else {
                setIsModalOpen(true);
                setIsDuplicateTicket(true);
                additionalFun();
              }
            }
            if (item.title == 'Edit') {
              if (ticketCategory == undefined) {
                setIsModalOpen(false);
              } else {
                setIsModalOpen(true);
                additionalFun();
              }
            }
            if (item.title == 'Delete') {
              if (ticketInUse) {
                dispatch(
                  setShowToasterWithParams({
                    variant: 'danger',
                    position: TOST_TOP_RIGHT,
                    message: 'Ticket is in use!',
                  }),
                );
                return;
              } else {
                deleteRecordHandler(index);
              }
            }
          }}
          className="position-absolute right-0 tickets_more_menu px-4 mt-2"
        />
      </DoctRow>
    </div>
  );
};

export default InrCard;
