import React, { memo, useCallback, useEffect, useState } from 'react';
import { DoctRadioGroup, DoctTextField } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import { LocationField, MobileWhatsAppForm } from '../../../../shared';
import { filedManageAttendee } from './field.ManageAttendee';
import CustomAutoComplete from '../../../../components/CustomAutoComplete';
import { WORK_STATUS_TYPE } from '../../../../helper/enums/eventStatus';
import { FORM_NAMES_STEPS } from '../../../../shared/ui/AttendeeForm/attendeeForm.constants';
import axios from 'axios';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { debounce } from '../../../../helper/helperFunction';
import { COURSE_CENTER, NAMES, QUALIFICATION, SPECIALTIES } from '../../../../api/apiEndpoints';

function FormManageAttendees({
  control,
  errors,
  touched,
  setValue,
  register,
  watch,
  clearErrors,
  defaultValue,
}) {
  const formProps = { control, isErrors: errors, touched, watch, setValue, clearErrors };

  useEffect(() => {
    register(filedManageAttendee.workStatus.name);
  }, []);

  return (
    <form>
      <div className="boxed-radio mx-n1 form_el">
        <DoctTypography variant="subtitle2" className="mt-0 mb-12px">
          Gender
        </DoctTypography>
        <DoctRadioGroup {...filedManageAttendee.gender} {...formProps} />
      </div>
      <span className="form_el d-inline-block">
        <DoctTextField {...filedManageAttendee.name} {...formProps} />
        <DoctTypography variant="caption1" className="text-grey-600">
          This name will be shown in Event Certificate.
        </DoctTypography>
      </span>
      <div className="form_el ">
        <LocationField
          inputProps={{
            country: {
              disabled: true,
            },
          }}
          {...formProps}
          columnLayout={{ country: 12, city: 6, state: 6 }}
          errors={errors}
          savedValue={defaultValue}
          valueAccessBy={'name'}
          className={'form_el form_el_gap_bottom'}
        />
      </div>

      <DoctTypography variant="subtitle2" className="mt-0">
        Contact Details
      </DoctTypography>
      <MobileWhatsAppForm
        {...formProps}
        errors={errors}
        columnLayout={{ mobile: 12, whatsapp: 12 }}
        className="form_el"
      />
      <DoctTextField {...filedManageAttendee.email} {...formProps} className="mb-4 form_el" />

      <ProfessionalDetailsComponent
        control={control}
        errors={errors}
        touched={touched}
        watch={watch}
        setValue={setValue}
        register={register}
        formProps={formProps}
      />

      <DoctTypography variant="subtitle2" className="mt-0">
        Other Details (Optional)
      </DoctTypography>
      <DoctTextField {...filedManageAttendee.licenseNo} {...formProps} className="form_el" />
      <DoctTextField {...filedManageAttendee.memberId} {...formProps} className="form_el" />
    </form>
  );
}

const ProfessionalDetailsComponent = ({
  control,
  errors,
  touched,
  watch,
  setValue,
  register,
  formProps,
}) => {
  const optionsWorkStatus = [
    {
      value: WORK_STATUS_TYPE.WorkingProfessional,
      label: 'Working Professional',
    },
    { value: WORK_STATUS_TYPE.Student, label: 'Student' },
  ];

  const [workStatusType, setWorkStatusType] = useState(
    watch(FORM_NAMES_STEPS.workStatus) || WORK_STATUS_TYPE.WorkingProfessional,
  );

  const [educationOptions, setEducationOptions] = useState([]);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);

  useEffect(
    () => setWorkStatusType(watch(FORM_NAMES_STEPS.workStatus)),
    [watch(FORM_NAMES_STEPS.workStatus)],
  );

  const handleWorkStatus = (isChecked, value) => {
    if (isChecked) {
      setValue(FORM_NAMES_STEPS.workStatus, value);
      setWorkStatusType(value);
    }
  };

  const getQualificationsNames = async (searchText = '') => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/${COURSE_CENTER}/${QUALIFICATION}/${NAMES}${
          searchText ? `?searchText=${searchText}` : ''
        }`,
      );
      const data = response?.data?.map((item = {}) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }));
      setEducationOptions(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getSpecialtyNames = async (searchText = '') => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/${COURSE_CENTER}/${SPECIALTIES}/${NAMES}${
          searchText ? `?searchText=${searchText}` : ''
        }`,
      );
      const data = response?.data?.map((item = {}) => ({
        value: item.name,
        label: item.name,
        id: item.id,
      }));
      setSpecialtyOptions(data);
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedGetSpecialtyNames = useCallback(
    debounce((val) => {
      getSpecialtyNames(val);
    }, 300),
    [],
  );

  const debouncedGetQualificationsNames = useCallback(
    debounce((val) => {
      getQualificationsNames(val);
    }, 300),
    [],
  );

  const handleChange = (val = '', field) => {
    if (field === FORM_NAMES_STEPS.qualification) {
      if (!val || val === undefined) {
        debouncedGetQualificationsNames(val);
        setValue(FORM_NAMES_STEPS.qualification, '');
      } else if (typeof val === 'string') {
        debouncedGetQualificationsNames(val);
        setValue(FORM_NAMES_STEPS.qualification, val);
      } else {
        debouncedGetQualificationsNames(val);
        setValue(FORM_NAMES_STEPS.qualification, val?.value ?? '');
      }
    }
    if (field === FORM_NAMES_STEPS.specialtyIds) {
      if (!val || val === undefined) {
        debouncedGetQualificationsNames(val);
        setValue(FORM_NAMES_STEPS.specialtyIds, '');
      }
      if (typeof val === 'object') {
        debouncedGetSpecialtyNames(val?.value);
        setValue(FORM_NAMES_STEPS.specialtyIds, val);
      } else if (typeof val === 'string') {
        debouncedGetSpecialtyNames(val);
      }
    }
  };

  useEffect(() => {
    getQualificationsNames();
    getSpecialtyNames();
  }, []);

  const watchQualification = watch(FORM_NAMES_STEPS.qualification) || '';
  const watchSpecialty = watch(FORM_NAMES_STEPS.specialtyIds) || null;
  const qualificationValue =
    {
      value: watchQualification,
      label: watchQualification,
    } || null;
  return (
    <div className="mb-5">
      <DoctTypography variant="subtitle2" className="mt-0 mb-12px">
        Professional Details
      </DoctTypography>
      <DoctTypography variant="subtitle2" className="my-0">
        Work Status
      </DoctTypography>
      <div className="mx-n2 mb-2 for-radio-primary-round">
        <RadioGroup
          sx={{ display: 'flex', flexDirection: 'row', paddingLeft: '9px' }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={workStatusType}
          value={workStatusType}
          name={FORM_NAMES_STEPS.workStatus}
        >
          {optionsWorkStatus.map((item, i) => (
            <FormControlLabel
              key={i}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '14px',
                  lineHeight: '14px',
                  color: '#242424',
                  fontWeight: '400',
                },
              }}
              control={<Radio />}
              onChange={(e) => handleWorkStatus(e.target.checked, item.value)}
              value={item.value}
              defaultChecked={item.value === workStatusType}
              label={item.label}
            />
          ))}
        </RadioGroup>
      </div>
      {workStatusType === WORK_STATUS_TYPE.WorkingProfessional && (
        <>
          <DoctTextField
            {...filedManageAttendee.organization}
            {...formProps}
            className="form_el form_el_gap_bottom mb-0"
          />
          <DoctTextField
            {...filedManageAttendee.designation}
            {...formProps}
            className="form_el mb-3"
          />
        </>
      )}
      <CustomAutoComplete
        control={control}
        register={register}
        touched={touched}
        errors={errors}
        field={FORM_NAMES_STEPS.specialtyIds}
        value={watchSpecialty}
        options={specialtyOptions}
        handleChange={handleChange}
        label="Specialty *"
        validationRules={{ required: 'Specialty is required' }}
        className="mb-3"
      />
      <CustomAutoComplete
        control={control}
        register={register}
        touched={touched}
        errors={errors}
        field={FORM_NAMES_STEPS.qualification}
        value={qualificationValue}
        options={educationOptions}
        handleChange={handleChange}
        freeSolo={true}
        label="Education *"
        validationRules={{
          required: 'Education is required',
          validate: (value) => {
            if (!value.trim()) return 'Education is required';
            return true;
          },
        }}
        className="mb-3"
      />
    </div>
  );
};

export default memo(FormManageAttendees);
