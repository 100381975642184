import { DoctTextField, DoctFormCheckbox } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  selectAttendeeValue,
  selectBasicInfoDetails,
  selectCapacityUsed,
  selectCreateEventResponse,
  selectTicketCategory,
} from '../../../../../createEvent.slice';
import TicketPriceCard from '../TicketPriceCard';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

function ManageIndianRegistration({
  control,
  errors,
  touched,
  handleFormSubmit,
  inrTickets,
  selectedRecord,
  selectedTicketId,
  reset,
  tarrif,
  setValue,
  notDefined,
  setNotDefined,
  setOldSeatCapacityValue,
}) {
  useEffect(() => {
    if (selectedRecord != null) {
      setOldSeatCapacityValue(inrTickets[selectedRecord]?.numberOfSeats);
      setNotDefined(inrTickets[selectedRecord]?.notDefined);
      reset(inrTickets[selectedRecord]);
    } else {
      setNotDefined(false);
      reset({});
    }
  }, [inrTickets, selectedRecord]);

  const ticketCategory = useSelector(selectTicketCategory);
  const basicInfoPageDetails = useSelector(selectBasicInfoDetails);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const maxAttendee = useSelector(selectAttendeeValue);
  const capacity = useSelector(selectCapacityUsed);
  const allTickets = useSelector(selectCreateEventResponse);

  const isDisabled = useMemo(() => {
    const res =
      allTickets?.tickets &&
      Array.isArray(allTickets?.tickets) &&
      allTickets?.tickets.length > 0 &&
      allTickets?.tickets.find((item) => item?.id === selectedTicketId);
    return res?.isDisable || false;
  }, [allTickets, selectedTicketId]);

  const [allowToAddSeatsInUnLimited, setAllowToAddSeatsInUnLimited] = useState();

  useEffect(() => {
    if (basicInfoPageDetails?.numberOfGathering == 'Unlimited') {
      setAllowToAddSeatsInUnLimited(basicInfoPageDetails?.numberOfGathering);
    } else if (apiResponseData?.numberOfGathering == 'Unlimited') {
      setAllowToAddSeatsInUnLimited(apiResponseData?.numberOfGathering);
    }
  }, [apiResponseData, basicInfoPageDetails]);

  const handleChange = (event) => {
    setNotDefined(event.target.checked);
  };

  useEffect(() => {
    if (notDefined) {
      setValue('numberOfSeats', '');
    }
  }, [notDefined]);

  useEffect(() => {
    if (
      apiResponseData?.numberOfGathering == 'Unlimited' ||
      basicInfoPageDetails?.numberOfGathering == 'Unlimited'
    ) {
      if (Object?.keys(apiResponseData)?.length) {
        apiResponseData?.tickets?.map((item) => {
          if (item?.numberOfSeats == null) {
            setNotDefined(true);
          }
        });
      } else if (Object.keys(basicInfoPageDetails)?.length) {
        if (inrTickets[selectedRecord]?.numberOfSeats == null) {
          setNotDefined(true);
        }
      } else {
        setNotDefined(true);
      }
    }
  }, []);

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <DoctTypography variant="subtitle2">Attendee Type</DoctTypography>
        <DoctTypography variant="subtitle2" className="text-grey-600">
          Add ticket label for attendee types like students, delegates, etc.
        </DoctTypography>
        <DoctTextField
          showStar={false}
          name="attendeeType"
          label="Ticket Label"
          id="attendeeType"
          control={control}
          isErrors={errors}
          defaultValue=""
          validationRules={{
            required: "It's Required Field",
          }}
          touched={touched}
        />
        <div className="d-flex">
          <DoctTypography variant="subtitle2" className="mr-auto">
            Seat Capacity (optional)
          </DoctTypography>

          <DoctTypography variant="textLabel1" className="text-grey-600">
            Not Defined
          </DoctTypography>
          <FormControlLabel
            control={
              <Switch
                checked={notDefined}
                onChange={handleChange}
                value="checkedB"
                color="default"
              />
            }
            className="mr-0 ml-sm-n1"
          />
        </div>
        <DoctTextField
          showStar={false}
          name="numberOfSeats"
          label="Add number of seats"
          id="numberOfSeats"
          control={control}
          isErrors={errors}
          defaultValue=""
          validationRules={{
            required: notDefined == false ? "It's Required Field" : false,
            pattern: {
              value: /^([1-9]\d*)(\.\d+)?$/,
              message: 'Not valid',
            },
          }}
          touched={touched}
          disabled={notDefined == true}
        />
        <DoctTypography variant="body3" className="text-grey-600 mt-1">
          {allowToAddSeatsInUnLimited != 'Unlimited' &&
            `${+maxAttendee - +capacity} out of ${maxAttendee} seats available`}
        </DoctTypography>
        <DoctTypography variant="subtitle2">Ticket Price by Category</DoctTypography>
        {ticketCategory?.ticketCategories?.map((item, index) => {
          return (
            <div key={index}>
              <TicketPriceCard
                control={control}
                errors={errors}
                touched={touched}
                categoryName={item.name}
                index={index}
                text="Indian"
                tarrif={tarrif}
              />
            </div>
          );
        })}
        <DoctFormCheckbox
          name="isDisable"
          label="Disable"
          id="isDisable"
          control={control}
          isErrors={errors}
          validationRules={{}}
          defaultValue={isDisabled}
          touched={touched}
        />
      </form>
    </>
  );
}

export default ManageIndianRegistration;
