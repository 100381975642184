import { DoctAutoComplete, DoctForm } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React from 'react';
import AssignCertificateViaDashboard from '../AssignCertificateViaDashboard';

export default function AssignViaMembershipDashboardPopUp({ openMenu, setOpenMenu }) {
  const { control, errors } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });
  return (
    <AssignCertificateViaDashboard
      openMenu={openMenu}
      setOpenMenu={setOpenMenu}
      iconName="membershipOutline"
      name="Memberships"
    >
      <DoctTypography variant="subtitle2" className="text-grey-800">
        Select Member from Memberships:
      </DoctTypography>
      <DoctAutoComplete
        label="Select Membership Type*"
        isErrors={errors}
        id="selectMembership"
        name="selectMembership"
        variant="standard"
        control={control}
        validationRules={{ required: "It's Required Field" }}
        options={[
          { label: '1', value: '1' },
          { label: '2', value: '2' },
        ]}
        className="mb-12px"
      />
    </AssignCertificateViaDashboard>
  );
}
