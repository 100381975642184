import { DoctModal } from '@docthub.frontend/app';
import { DoctButton, DoctTypography } from 'doct-core';
import React from 'react';

const CloseFormModal = ({
  open,
  clasname,
  handleClose,
  onConfirmExit = () => {},
  saveDraft,
  discard,
  handleSaveAsDraft,
}) => {
  return (
    <div>
      <DoctModal
        className={clasname}
        open={open}
        handleClose={handleClose}
        iconName=""
        showFooter={false}
      >
        <DoctTypography variant="h6">Are you sure you want to close?</DoctTypography>
        <DoctTypography variant="body3" className="text-grey-600">
          You can save draft to continue later from where you left or discard all the progress.
        </DoctTypography>

        <DoctButton
          className={`float-right mx-2  ${saveDraft ? 'save_draft_btn_for_job' : 'discard_btn'}`}
          variant="contained"
          text={saveDraft ? saveDraft : 'Yes'}
          size="medium"
          onButtonClickHandler={
            saveDraft === 'Save Draft'
              ? () => {
                  handleSaveAsDraft();
                  handleClose('Save Draft');
                }
              : onConfirmExit
          }
        />
        <DoctButton
          className="float-right save_draft_btn text-grey-600"
          text={discard ? discard : 'No'}
          variant="contained"
          size="medium"
          onButtonClickHandler={discard === 'Discard' ? () => handleClose('Discard') : handleClose}
        />
      </DoctModal>
    </div>
  );
};

export default CloseFormModal;
