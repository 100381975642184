import { DoctTextArea } from '@docthub.frontend/app';
import React from 'react';
import { FieldWrapper, LocationField } from '../../../../../shared';
import fieldProfileSetting from '../field.profileSettings';

export default function FormEditLocationDetailsProfile({ hookFormProps, defaultValues }) {
  return (
    <form>
      <LocationField
        validationRules={{
          country: fieldProfileSetting.country.validationRules,
          state: fieldProfileSetting.state.name,
          city: fieldProfileSetting.city.name,
          pincode: fieldProfileSetting.postalCode.validationRules,
        }}
        savedValue={{
          country: defaultValues?.[fieldProfileSetting.country.name],
          city: defaultValues?.[fieldProfileSetting.city.name],
          state: defaultValues?.[fieldProfileSetting.state.name],
        }}
        {...hookFormProps}
        inputProps={{
          country: {
            disabled: true,
          },
          showStar: {
            showStar: false,
          },
        }}
        showinGrid
        showPinCode
        columnLayout={{ country: 12, city: 12, state: 12, pincode: 12 }}
        className={'form_el form_el_gap_bottom'}
      />
      {/* <FieldWrapper>
        <DoctTextField
          type="number"
          {...hookFormProps}
          name={fieldProfileSetting.postalCode.name}
          label={fieldProfileSetting.postalCode.label}
          id={fieldProfileSetting.postalCode.id}
          validationRules={fieldProfileSetting.postalCode.validationRules}
          showStar={false}
        />
      </FieldWrapper> */}
      <FieldWrapper>
        <DoctTextArea
          {...hookFormProps}
          name={fieldProfileSetting.address.name}
          label={fieldProfileSetting.address.label}
          id={fieldProfileSetting.address.id}
          validationRules={fieldProfileSetting.address.validationRules}
          showStar={false}
        />
      </FieldWrapper>
    </form>
  );
}
