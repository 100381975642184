import React from 'react';
import { DoctForm, DoctModal } from '@docthub.frontend/app';

import { InputColumn, PasswordInput } from '../../../../../shared/form';
import { useChangePasswordMutation } from '../../../../../components';
import { useNotifySuccessFailed } from '../../../../../hooks';
import fieldProfileSetting from '../../../Profile/components/field.profileSettings';
import { DoctTypography } from 'doct-core';
import {
  MIN_PASSWORD_LENGTH,
  PASSWORD_VALIDATION,
  PASSWORD_VALIDATION_TEXT,
} from '../../../../../constants/constants';

function NewPassword({ control, errors, watch }) {
  const watchCurrentPassword = watch(fieldProfileSetting.currentPassword.name);
  const watchNewCurrentPassword = watch(fieldProfileSetting.newPassword.name);
  return (
    <>
      <InputColumn>
        <PasswordInput
          control={control}
          errors={errors}
          name={fieldProfileSetting.newPassword.name}
          label={fieldProfileSetting.newPassword.label}
          overrideValidation={{
            required: "It's a required field",
            validate: (value) => {
              if (value.includes(' ')) {
                return 'Password cannot contain white space';
              }
              if (value === watchCurrentPassword) {
                return 'Current password and new password cannot be the same';
              }
              return null;
            },
            pattern: {
              value: PASSWORD_VALIDATION,
              message: PASSWORD_VALIDATION_TEXT,
            },
            minLength: {
              value: MIN_PASSWORD_LENGTH,
              message: `Minimum ${MIN_PASSWORD_LENGTH} characters required!`,
            },
          }}
        />
      </InputColumn>
      <InputColumn>
        <PasswordInput
          name={fieldProfileSetting.confirmPassword.name}
          label={fieldProfileSetting.confirmPassword.label}
          control={control}
          errors={errors}
          overrideValidation={{
            required: "It's Required Field",
            validate: (value) => {
              return (
                value === watchNewCurrentPassword || 'The confirmation passwords does not match'
              );
            },
            pattern: {
              value: PASSWORD_VALIDATION,
              message: PASSWORD_VALIDATION_TEXT,
            },
            minLength: {
              value: MIN_PASSWORD_LENGTH,
              message: `Minimum ${MIN_PASSWORD_LENGTH} characters required!`,
            },
          }}
        />
      </InputColumn>
    </>
  );
}

function Form(props) {
  const { control, errors } = props;

  return (
    <>
      <DoctTypography variant="body2" className="mt-1 mb-12px">
        Enter your current password to create a new password.
      </DoctTypography>
      <PasswordInput
        control={control}
        errors={errors}
        name={fieldProfileSetting.currentPassword.name}
        label={fieldProfileSetting.currentPassword.label}
      />
      <DoctTypography variant="subtitle2" className="mb-12px text-grey-600 pt-2 mt-12px">
        Create New Password
      </DoctTypography>
      <NewPassword {...props} />
      <DoctTypography variant="textLabel2" className="mb-12px text-grey-600 pt-2 mt-12px">
        {PASSWORD_VALIDATION_TEXT}
      </DoctTypography>
    </>
  );
}

export default function ChangePassword({ setSelectedTab }) {
  const { handleSubmit, control, errors, reset, touched, watch } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const hookFormProps = { control, errors, touched, reset, watch };

  const [changePasswordMutation, { isLoading, isSuccess, isError, error }] =
    useChangePasswordMutation();

  const initApiCall = handleSubmit((data) => {
    const {
      [fieldProfileSetting.currentPassword.name]: currentPassword,
      [fieldProfileSetting.newPassword.name]: newPassword,
    } = data || {};
    changePasswordMutation({ currentPassword, newPassword });
  });

  useNotifySuccessFailed({
    isSuccess,
    isError,
    onSuccessHandler: () => setSelectedTab(),
    successNotifyMsg: 'Password changed successfully!',
    errorNotifyMsg: error?.data?.Title,
  });

  return (
    <DoctModal
      iconName={''}
      primaryBtnText={'Submit'}
      open
      className={'disable_modal_outside_click'}
      handlePrimaryButtonClick={initApiCall}
      handleClose={() => {
        setSelectedTab();
      }}
      title={'Change Password'}
      width={360}
      isPrimaryBtnDisable={isLoading}
    >
      <Form {...hookFormProps} />
    </DoctModal>
  );
}
