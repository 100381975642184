import React from 'react';
import { DoctButton, DoctTypography } from 'doct-core';

import * as ROUTE from '../../../../routes/constant';

function MembershipEmptyCard({ navigate }) {
  return (
    <>
      <DoctTypography variant="textLabel1" className="font-weight-medium text-grey-600 text-center">
        A cloud office of your association.
      </DoctTypography>
      <div className="d-flex justify-content-center">
        <DoctButton
          variant="contained"
          size="medium"
          text="Start Now"
          onButtonClickHandler={() => {
            navigate(`/${ROUTE.DASHBOARD}/${ROUTE.CREATE_NEW_MEMBERSHIP}`);
          }}
        />
      </div>
    </>
  );
}

export default MembershipEmptyCard;
