import { DoctForm } from '@docthub.frontend/app';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveApiData,
  saveSaveAsDraft,
  saveSponsorsAndExhibitorsDetails,
  selectCreateEventResponse,
  selectCurrentStep,
  selectResponseData,
  selectSaveAsDraftClicked,
  selectUpdateEventLoader,
  setCurrentStep,
  setDefaultState,
  setStepsFormData,
} from '../../../createEvent.slice';
import stepsName from '../stepsName';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { updateCreateEvent } from '../../../services/CreateEventServices';

const formNameWithDefaultProps = () => {
  return {};
};

export default function useFormSponsorsExhibitors() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const createdEventResponse = useSelector(selectResponseData);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const loading = useSelector(selectUpdateEventLoader);
  const draftBtnClicked = useSelector(selectSaveAsDraftClicked);
  const navigate = useNavigate();

  const [exhibitors, setExhibitors] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [exhibitorLayout, setExhibitorLayout] = useState([]);
  const [successfullHandleSubmit, setSuccessfullHandleSubmit] = useState(false);

  const [resetExhibitorLayoutUrl, setResetExhibitorLayoutUrl] = useState();
  const [deleteExhibitorLayoutUrl, setDeleteExhibitorLayoutUrl] = useState(false);

  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (draftBtnClicked == true) {
      if (successfullHandleSubmit) {
        if (!loading) {
          dispatch(saveSaveAsDraft(false));
          dispatch(setDefaultState());
          navigate(-1);
        }
      } else {
        handleFormSubmit();
      }
    }
  }, [draftBtnClicked, successfullHandleSubmit, loading]);

  useMemo(() => {
    if (Object.keys(errors)?.length) {
      dispatch(saveSaveAsDraft(false));
    }
  }, [Object.keys(errors)?.length]);

  const handleFormSubmit = handleSubmit((values) => {
    let transformSponsors;

    if (sponsors?.length) {
      transformSponsors = sponsors.map((objOfSponsors) => {
        return {
          ...objOfSponsors,
          SponsorPictureFile:
            objOfSponsors?.sponsorPictureFile?.[0] || objOfSponsors?.sponsorPictureFile,
        };
      });
    }

    values.sponsors = transformSponsors;

    values.exhibitors = exhibitors;

    values.ExhibitorStallLayoutFile = exhibitorLayout;

    if (deleteExhibitorLayoutUrl == true && apiResponseData?.exhibitorStallLayoutUrl) {
      values.deleteExhibitorStallLayoutUrl = true;
    }
    if (createdEventResponse?.id) {
      values.Id = createdEventResponse.id;
    }
    if (id) {
      values.Id = id;
      dispatch(saveApiData({ ...apiResponseData, ...values }));
    }
    dispatch(saveSponsorsAndExhibitorsDetails(values));
    dispatch(updateCreateEvent(values.Id)); // PUT API Call
    setSuccessfullHandleSubmit(true);
    if (draftBtnClicked == false) {
      if (loading == false) {
        dispatch(setCurrentStep(currentStep + 1));
        dispatch(setStepsFormData({ [stepsName.sponsorsAndExhibitors.name]: values }));
      }
    }
  });

  return {
    formName: formNameWithDefaultProps(control, errors),
    handleFormSubmit,
    control,
    errors,
    formState,
    reset,
    touched,
    watch,
    setValue,
    setExhibitors,
    exhibitors,
    setSponsors,
    sponsors,
    exhibitorLayout,
    setExhibitorLayout,
    resetExhibitorLayoutUrl,
    setResetExhibitorLayoutUrl,
    setDeleteExhibitorLayoutUrl,
  };
}
