import React from 'react';
import { DoctTypography } from 'doct-core';
import { checkKeyDownAndPreventDefault } from '../../helper/helperFunction';

export function FormEl({ children }) {
  return <div className="form_el">{children}</div>;
}

export function FormElWithHeading({ heading, children }) {
  return (
    <div className="form_el">
      <DoctTypography variant="subtitle2" className="form_el__title">
        {heading}
      </DoctTypography>
      {children}
    </div>
  );
}

export function Form({ children }) {
  return (
    <form onKeyDown={(e) => checkKeyDownAndPreventDefault(e)} autoComplete="off">
      {children}
    </form>
  );
}
