import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { DASHBOARD } from '../../../routes/constant';
import useSendRequestInfoInstituteSubscription from '../../../hooks/useSendRequestInfoInstituteSubscription';
import Toaster from '../../../shared/ui/Toaster/Toaster';
import { DoctButton, DoctTypography } from 'doct-core';

/**
 * SubscriptionSubscribePlan component allows users to subscribe to a plan to access applicant details.
 * It handles sending subscription requests, displaying success/error messages, and navigation.
 *
 * @param {Object} props - Component properties
 * @param {Function} props.setIsHovering - Function to set the hovering state
 * @returns {JSX.Element} - JSX element representing the subscription subscribe plan component
 */
const SubscriptionSubscribePlan = ({ setIsHovering }) => {
  const { sendRequest, requestSent, setRequestSent, sending, error, setError } =
    useSendRequestInfoInstituteSubscription();

  const navigate = useNavigate();
  const [isSendingMouseLeaveBlocked, setIsSendingMouseLeaveBlocked] = useState(false);

  useEffect(() => {
    if (error) {
      const errorTimer = setTimeout(() => {
        setError(false);
      }, 2000);

      return () => clearTimeout(errorTimer);
    }
  }, [error, setError]);

  /**
   * Handles mouse enter event.
   * Sets isHovering to true and unblocks the mouse leave action.
   */
  const handleMouseEnter = () => {
    setIsHovering(true);
    setIsSendingMouseLeaveBlocked(false);
  };

  /**
   * Handles mouse leave event.
   * Updates isHovering based on blocking conditions.
   */

  const handleMouseLeave = () => {
    if (!requestSent && !sending && !isSendingMouseLeaveBlocked) {
      setIsHovering(false);
    } else {
      setIsSendingMouseLeaveBlocked(true);
    }
  };

  const handleCloseMessage = () => {
    setRequestSent(false);
  };

  const handleNavigate = () => {
    navigate(`/${DASHBOARD}/billing-subscription/subscription/all-packages`, {
      state: { backFromSubscription: window?.location?.pathname },
    });
  };

  return (
    <div
      className="d-flex justify-content-between align-items-center px-3 mt-1 bg-green-shade border-radius cursor-pointer p-2 pb-2"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {requestSent && (
        <div
          style={{
            display: 'flex',
            position: 'fixed',
            zIndex: 2000,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            backgroundColor: 'rgba(1, 80, 95, 0.3)',
            width: '100vw',
            top: 0,
            left: 0,
            cursor: 'context-menu',
          }}
        >
          <div className="bg-white border-radius p-4">
            <DoctTypography variant="h6">Thank you for reaching out!</DoctTypography>
            <DoctTypography variant="subtitle2" className="text-grey-600 font-weight-regular">
              Please contact us at the +91 9090902626,
              <br /> or our team will get in touch
              <br /> with you shortly.
            </DoctTypography>
            <div className="d-flex justify-content-end mr-3">
              <DoctButton
                text="Okay, Got it"
                size="medium"
                className="cursor-pointer"
                onButtonClickHandler={handleCloseMessage}
              />
            </div>
          </div>
        </div>
      )}

      {error && (
        <div className="position-fixed tost-container-top-right">
          <Toaster variant="error" text="Error sending request" />
        </div>
      )}

      <div>
        <DoctTypography variant="subtitle2" className="text-grey-800 m-0 p-0">
          Subscribe to a plan to access applicant details.
        </DoctTypography>
        <DoctTypography variant="textLabel2" className="text-grey-800 m-0 p-0 mt-1">
          Renew or Upgrade your subscription to view this applicant details.
        </DoctTypography>
      </div>
      <div className="d-flex align-items-center">
        <DoctButton
          disabled={sending}
          text="VIEW PLANS"
          type="semantic-success"
          size="small"
          className="mr-2"
          onButtonClickHandler={() => {
            handleNavigate();
          }}
        />
        <DoctButton
          onButtonClickHandler={sendRequest}
          text="REQUEST INFO"
          size="small"
          variant="outlined"
          disabled={sending}
        />
      </div>
    </div>
  );
};

export default SubscriptionSubscribePlan;
