import { DoctAutoComplete, DoctTextField } from '@docthub.frontend/app';
import { DoctCol, DoctRow, DoctTypography } from 'doct-core';
import React from 'react';

const BasicInfoSalaryComponent = ({ control, errors, watch, setValue }) => {
  const minAmountVal = parseInt(watch('minAmount'), 10);
  const maxAmountVal = parseInt(watch('maxAmount'), 10);

  return (
    <>
      <DoctTypography variant="subtitle2">Salary</DoctTypography>
      <DoctTypography variant="body2" className="text-grey-600">
        Specify salary range between minimum to maximum amount per Annum
      </DoctTypography>
      <div className="mr-3">
        <DoctRow>
          <DoctCol sm={6}>
            <DoctAutoComplete
              control={control}
              isErrors={errors}
              name="currency"
              id="currency"
              label="Currency"
              value={watch('currency') || null}
              validationRules={{ required: 'it is required' }}
              options={[]}
              defaultValue={{ label: 'INR', value: 'INR' }}
              disabled
            />
          </DoctCol>
          <DoctCol sm={6} className="mr-sm-auto">
            <DoctAutoComplete
              control={control}
              isErrors={errors}
              name="salaryType"
              id="salaryType"
              label="Salary Type"
              value={watch('salaryType') || null}
              validationRules={{ required: 'it is required' }}
              options={[
                { label: 'Monthly', value: 'Monthly' },
                { label: 'Yearly', value: 'Yearly' },
              ]}
            />
          </DoctCol>
        </DoctRow>
      </div>
      <div className="my-3">
        <DoctRow>
          <DoctCol sm={6}>
            {/* <DoctAutoComplete
              control={control}
              isErrors={errors}
              name="minAmount"
              id="minAmount"
              label="Min Annual Salary"
              showStar={false}
              validationRules={{ required: 'it is required' }}
              options={optionsSalaryAmount}
              onChange={() => {
                setValue('maxAmount', null);
              }}
            /> */}
            <DoctTextField
              control={control}
              isErrors={errors}
              name="minAmount"
              id="minAmount"
              label="Minimum Salary"
              showStar={false}
              validationRules={{
                required: 'it is required',
                validate: (value) => {
                  if (isNaN(value)) {
                    return 'Please enter a number';
                  }
                  const minValue = parseInt(value, 10) || 0;
                  const maxValue = parseInt(maxAmountVal, 10) || 0;
                  const regex = /^(?!0{2,}$)([1-9]\d{3,7}|100000000)$/;
                  if (!regex.test(value.toString())) {
                    return 'Minimum salary should be between Rs.1000 to Rs.100000000';
                  }
                  if (minValue >= maxValue && maxValue !== 0) {
                    setValue('maxAmount', '');
                    return;
                  }
                  return true;
                },
              }}
            />
          </DoctCol>
          <DoctCol>
            <DoctTypography variant="textLabel1" className="text-grey-600 text-center">
              To
            </DoctTypography>
          </DoctCol>
          <div className="col">
            {/* <DoctAutoComplete
              control={control}
              isErrors={errors}
              name="maxAmount"
              id="maxAmount"
              label="Max Annual Salary"
              showStar={false}
              validationRules={{ required: 'it is required' }}
              options={optionsSalaryAmount.filter(({ value }) => {
                return Number(value) > (Number(minAmountVal?.value) || 0);
              })}
            /> */}
            <DoctTextField
              control={control}
              isErrors={errors}
              name="maxAmount"
              id="maxAmount"
              label="Maximum Salary"
              showStar={false}
              validationRules={{
                required: 'it is required',
                validate: (value) => {
                  if (isNaN(value)) {
                    return 'Please enter a number';
                  }
                  const minValue = parseInt(minAmountVal, 10) || 0;
                  const maxValue = parseInt(value, 10) || 0;
                  return (
                    maxValue > minValue || 'Maximum salary should be greater than minimum salary'
                  );
                },
              }}
            />
          </div>
        </DoctRow>
      </div>
    </>
  );
};

export default BasicInfoSalaryComponent;
