import React from 'react';
import { DoctTypography } from 'doct-core';
import BuildingIcon from '../../../assets/icons/business_office_company_building.svg';
import AccountSetting from '../../../assets/icons/account-setting-icon.svg';
import { useNavigate } from 'react-router-dom';
import * as ROUTE from '../../../routes/constant';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

export default function UserInfo({ userInfo, className }) {
  const { name, organizationName, userNumber } = userInfo || {};
  const navigate = useNavigate();

  return (
    <div
      className={className ? `d-flex align-items-center ${className}` : 'd-flex align-items-center'}
    >
      <span className="d-inline-flex">
        <img src={BuildingIcon} alt="office icon"></img>
      </span>
      <p className="my-0 ml-3">
        <DoctTypography variant="textLabel1" className="my-0">
          {name}
        </DoctTypography>
        <DoctTypography
          variant="caption2"
          textTransform="normal"
          className="text-grey-600 my-0 d-inline-block"
        >
          {organizationName}
          <br />
          ID: {userNumber}
        </DoctTypography>
      </p>
      <span
        className="ml-auto d-inline-flex align-items-center bg-grey-300 border-radius py-12px cursor-pointer"
        onClick={() => {
          navigate(`/${ROUTE.DASHBOARD}/${ROUTE.SETTINGS}/${ROUTE.PROFILE}`);
        }}
      >
        <Tooltip title="Account Settings" arrow>
          <Button>
            <img src={AccountSetting} alt="account-setting" className="ml-1"></img>
          </Button>
        </Tooltip>
        <DoctTypography variant="textLabel2" className="text-grey-600 my-0 mr-4 ml-n2">
          Account Settings
        </DoctTypography>
      </span>
    </div>
  );
}
