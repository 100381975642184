import { DoctButton, DoctContainer, DoctTypography } from 'doct-core';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useGetEventByIdQuery } from '../ManageEvents';
import dayjs from 'dayjs';
import { DoctActionMenu } from '@docthub.frontend/app';
import { abstractFileUpload } from './services/eventAbstract.services';
import { setShowToasterWithParams } from '../../featuresSlice';
import { TOST_TOP_RIGHT } from '../../../constants/toasterPosition.constants';
import style from './Abstract.module.scss';

const options = [
  {
    title: 'Delete File',
    className: 'text-danger',
  },
];

function AbstractFileUpload({ dropdownValue }) {
  const { data } = useGetEventByIdQuery({ id: dropdownValue });
  const dispatch = useDispatch();

  const uploadFiles = () => {
    document.getElementById('selectField').click();
  };

  const handleApiCall = (file, isDeleted) => {
    const newResponse = {};
    newResponse.id = dropdownValue;
    newResponse.AbstractDocumentFile = file;
    newResponse.deleteAbstractDocumentUrl = isDeleted;
    dispatch(abstractFileUpload(newResponse));
  };

  return (
    <>
      {data?.abstractDocumentUrl ? (
        <div className="d-flex justify-content-between bg-white border-radius mt-3 p-3">
          <div className="mt-1">
            <DoctTypography variant="subtitle2" className="text-grey-800 m-0">
              {data?.abstractDocumentUrl?.substring(
                data?.abstractDocumentUrl?.lastIndexOf('/') + 1,
              )}
            </DoctTypography>
            <DoctTypography variant="textLabel2" className="text-grey-400 m-0 mt-1">
              Uploaded on : {dayjs(data?.abstractDocumentDate).format('DD MMM YYYY')}
            </DoctTypography>
          </div>
          <DoctActionMenu
            btnType="inverse"
            className="more_action_menu_organizer"
            options={options}
            onClick={(item) => {
              if (item.title == 'Delete File') {
                handleApiCall(null, true);
              }
            }}
          />
        </div>
      ) : (
        <div className="bg-grey-100 mt-3 py-5">
          <div className={`text-center ${style.abstract_text_hight}`}>
            <DoctContainer>
              <DoctTypography variant="subtitle1" className="text-grey-800">
                Abstracts Submission Guideline & Form
              </DoctTypography>
              <DoctTypography variant="textLabel1" className="text-grey-600">
                Upload PDF or Word file for the selected event (upto 10MB).
              </DoctTypography>
              <div className="d-flex justify-content-center align-items-center ">
                <DoctButton
                  text="Upload PDF"
                  variant="outlined"
                  size="medium"
                  className="bg-info"
                  onButtonClickHandler={uploadFiles}
                />
                <input
                  type="file"
                  id="selectField"
                  name="profileFile"
                  accept=".pdf, .doc, .docx"
                  hidden
                  onChange={(e) => {
                    e.preventDefault();
                    if (
                      e.target.files[0]?.name?.toString()?.split('.')[1] != 'pdf' &&
                      e.target.files[0]?.name?.toString()?.split('.')[1] != 'doc' &&
                      e.target.files[0]?.name?.toString()?.split('.')[1] != 'docx'
                    ) {
                      dispatch(
                        setShowToasterWithParams({
                          variant: 'danger',
                          position: TOST_TOP_RIGHT,
                          message: 'Invalid File Type!',
                        }),
                      );
                      return;
                    }
                    if (e.target.files[0].size >= 10485760) {
                      dispatch(
                        setShowToasterWithParams({
                          variant: 'danger',
                          position: TOST_TOP_RIGHT,
                          message: 'File must be upto 10MB !',
                        }),
                      );
                    } else {
                      handleApiCall(e.target.files[0], false);
                    }
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />
              </div>
            </DoctContainer>
          </div>
        </div>
      )}
    </>
  );
}

export default AbstractFileUpload;
