import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { DoctButton, DoctTypography } from 'doct-core';
import { DASHBOARD } from '../../../routes/constant';

/**
 * SubscriptionUpgradeCard component displays a card informing users about exceeding limits in their subscription.
 * It provides an option to upgrade the subscription plan.
 *
 * @param {Object} props - Component properties
 * @param {Function} props.setIsHovering - Function to set the hovering state
 * @param {number} props.limitExceededCount - Number of times the limit has been exceeded
 * @returns {JSX.Element} - JSX element representing the subscription upgrade card
 */
const SubscriptionUpgradeCard = ({ setIsHovering, limitExceededCount }) => {
  const [isSendingMouseLeaveBlocked, setIsSendingMouseLeaveBlocked] = useState(false);
  const navigate = useNavigate();

  /**
   * Handles mouse enter event.
   * Sets isHovering to true and unblocks the mouse leave action.
   */
  const handleMouseEnter = () => {
    setIsHovering(true);
    setIsSendingMouseLeaveBlocked(false);
  };

  /**
   * Handles mouse leave event.
   * Updates isHovering based on blocking conditions.
   */
  const handleMouseLeave = () => {
    if (!isSendingMouseLeaveBlocked) {
      setIsHovering(false);
    } else {
      setIsSendingMouseLeaveBlocked(true);
    }
  };

  return (
    <div
      className={`d-flex justify-content-between align-items-center px-3 bg-light-info border-radius cursor-pointer p-2 pb-2`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div>
        <DoctTypography variant="subtitle2" className={`text-grey-800 m-0 p-0`}>
          You have exceeded the limit of {limitExceededCount} applicant views allowed in your
          subscription.
        </DoctTypography>
        <DoctTypography variant="textLabel2" className={`text-grey-800 m-0 p-0 mt-1`}>
          Renew or Upgrade your subscription to view this applicant details.
        </DoctTypography>
      </div>
      <DoctButton
        text="UPGRADE PLAN"
        type="semantic-info"
        size="small"
        onButtonClickHandler={() => {
          navigate(`/${DASHBOARD}/billing-subscription/subscription/all-packages`, {
            state: { backFromSubscription: window?.location?.pathname },
          });
        }}
      />
    </div>
  );
};

export default SubscriptionUpgradeCard;
