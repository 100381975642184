import React, { useEffect } from 'react';
import { DoctTextField } from '@docthub.frontend/app';
import { DoctCol, DoctRow, DoctTypography } from 'doct-core';
import { useSelector } from 'react-redux';
import { useGetUserQuery } from '../../../components';

const AccountDetail = ({ control, errors, touched, billingDetails, setValue, watch }) => {
  const organizationName = useSelector(
    (state) => state?.subscriptionData?.billingDetails?.organizationName,
  );

  const watchOrganization = watch('organization');

  const { data: userData } = useGetUserQuery('');

  useEffect(() => {
    if (!organizationName) {
      setValue('organization', userData?.tenant?.organizationName);
    } else {
      setValue('organization', organizationName);
    }
  }, [organizationName, watchOrganization]);

  useEffect(() => {
    const billingNameValue = billingDetails?.billingName
      ? billingDetails.billingName
      : userData?.tenant?.organizationName || organizationName;

    if (billingNameValue !== undefined) {
      setValue('billingName', billingNameValue, { shouldDirty: true });
    }
  }, [
    billingDetails?.billingName,
    userData?.tenant?.organizationName,
    organizationName,
    setValue,
    watchOrganization,
  ]);

  return (
    <>
      <div className="input-column">
        <div className="d-flex align-items-center justify-content-between">
          <DoctTypography variant="subtitle2">Organization Name</DoctTypography>
        </div>
        <DoctRow>
          <DoctCol sm={12}>
            <DoctTextField
              className="mb-2"
              name="organization"
              label="Enter organization name"
              id="organization"
              control={control}
              isErrors={errors}
              defaultValue={organizationName}
              validationRules={{
                required: true,
                pattern: {
                  value: /^(?!\s*$).{1,200}$/i,
                  message: 'Organization name is too long or contains only blank spaces',
                },
              }}
              touched={touched}
              showStar={false}
              disabled
            />
          </DoctCol>
        </DoctRow>
      </div>
      <div className="input-column">
        <div className="d-flex align-items-center justify-content-between">
          <DoctTypography variant="subtitle2">Billing Name</DoctTypography>
        </div>
        <DoctRow>
          <DoctCol sm={12}>
            <DoctTextField
              className="mb-2"
              name="billingName"
              label="Billing Name"
              id="BillingName"
              control={control}
              isErrors={errors}
              defaultValue={
                billingDetails?.billingName?.length
                  ? billingDetails?.billingName
                  : userData?.tenant?.organizationName || organizationName
              }
              validationRules={{
                required: true,
                pattern: {
                  value: /^(?!\s*$).{1,200}$/i,
                  message: 'Billing name is too long or contains only blank spaces',
                },
              }}
              touched={touched}
              showStar={false}
            />
          </DoctCol>
        </DoctRow>
      </div>
      {/* {isIndia ? ( */}
      <div className="input-column">
        <div className="d-flex align-items-center justify-content-between">
          <DoctTypography variant="subtitle2">GSTIN (optional)</DoctTypography>
        </div>
        <DoctRow>
          <DoctCol sm={12}>
            <DoctTextField
              className="mb-2 gst-number-capitalized"
              name="gstNo"
              label="Enter GSTIN"
              id="gstNo"
              control={control}
              isErrors={errors}
              defaultValue=""
              validationRules={{
                required: false,
                pattern: {
                  value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][0-9][A-Z][0-9A-Z]$/,
                  message: 'Invalid GSTIN. Please enter a valid GSTIN.',
                },
                maxLength: {
                  value: 15,
                  message: 'GSTIN must be exactly 15 characters long.',
                },
                minLength: {
                  value: 15,
                  message: 'GSTIN must be exactly 15 characters long.',
                },
              }}
              touched={touched}
              showStar={false}
            />
          </DoctCol>
        </DoctRow>
      </div>

      <div className="input-column">
        {/* <DoctCheckbox
          onChange={(e) => {}}
          name={`unregisetraddress`}
          label={`Unregistered business`}
          id={`unregisetraddress`}
          control={control}
          //   checkboxProps={{ value: status.value }}
          isErrors={errors}
          validationRules={{}}
          className="d-block"
          //   isChecked={false}
        /> */}
      </div>
      <div className="input-column">
        <div className="d-flex align-items-center justify-content-between">
          <DoctTypography variant="subtitle2" className="my-0">
            {/* {isIndia ? 'PAN Number' : 'Taxation ID'} */}
            Taxation ID (optional)
          </DoctTypography>
        </div>
        <div className="d-flex align-items-center justify-content-between">
          <DoctTypography variant="textLabel2" className="text-grey-600">
            {/* {isIndia ? 'PAN Number' : 'Taxation ID'} */}
            PAN, VAT or other ID number
          </DoctTypography>
        </div>
        <DoctRow>
          <DoctCol sm={12}>
            <DoctTextField
              className={`mb-2`}
              // name={isIndia ? 'pan' : 'taxationId'}
              name="taxId"
              label="Enter number"
              // id={isIndia ? 'pan' : 'taxationId'}
              id="taxId"
              control={control}
              isErrors={errors}
              defaultValue=""
              validationRules={{
                // required: {
                //   value: true,
                //   message: "It's Required Field",
                // },
                pattern: {
                  value: /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$/,
                  message: 'Please enter a valid PAN card number',
                },
              }}
              touched={touched}
              showStar={false}
            />
          </DoctCol>
        </DoctRow>
      </div>
    </>
  );
};

export default AccountDetail;
