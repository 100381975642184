import { DoctButton } from 'doct-core';
import React from 'react';

const LoadMore = ({ allJobsData, jobsAllData, setJobsAllData, tableRowData }) => {
  return (
    <>
      {allJobsData?.length > 7 ? (
        <>
          {jobsAllData?.length > 7 ? (
            ''
          ) : (
            <div className="d-flex justify-content-center">
              {jobsAllData >= allJobsData?.length ? (
                ''
              ) : (
                <DoctButton
                  onButtonClickHandler={() => {
                    setJobsAllData((prevData) => prevData + 7);
                  }}
                  type="inverse"
                  size="large"
                  className="my-4"
                  variant="contained"
                  text="Load more"
                  icon="down"
                  iconPosition="right"
                />
              )}
            </div>
          )}
        </>
      ) : (
        tableRowData?.length > 1 && (
          <DoctButton
            onButtonClickHandler={() => {
              setJobsAllData((prevData) => prevData + 20);
            }}
            type="inverse"
            size="large"
            className="my-4"
            variant="contained"
            text="Load more"
            icon="down"
            iconPosition="right"
          />
        )
      )}
    </>
  );
};

export default LoadMore;
