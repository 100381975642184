import React from 'react';
import { DoctTextField, DoctTimePicker } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import dayjs from 'dayjs';
import { TextEditor } from '../../../../../../../../shared';

function ManageScheduleSession({
  handleFormSubmit,
  control,
  touched,
  errors,
  defaultTimeValue,
  selectedTimeValue,
}) {
  const validateName = (value) => {
    return dayjs(value).isValid() || 'Please enter a valid format (hh:ss)';
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <DoctTypography variant="subtitle2" className="text-grey-800">
          Session Duration
        </DoctTypography>
        <div className="d-flex">
          <DoctTimePicker
            name="from"
            label="Start Time"
            id="From"
            className="mr-2"
            control={control}
            touched={touched}
            isErrors={errors}
            defaultValue={selectedTimeValue == true ? defaultTimeValue.from : ''}
            validationRules={{
              required: "It's Required Field",
              validate: {
                validateName,
              },
            }}
            ampm={true}
          />
          <DoctTimePicker
            name="to"
            label="End Time"
            id="To"
            control={control}
            touched={touched}
            isErrors={errors}
            defaultValue={selectedTimeValue == true ? defaultTimeValue.to : ''}
            validationRules={{
              required: "It's Required Field",
              validate: {
                validateName,
              },
            }}
            ampm={true}
          />
        </div>
        <DoctTypography variant="subtitle2" className="text-grey-800">
          Title & Description
        </DoctTypography>
        <DoctTextField
          showStar
          className="mb-2"
          name="title"
          label="Session title"
          id="Title"
          control={control}
          isErrors={errors}
          defaultValue=""
          validationRules={{
            required: "It's Required Field",
          }}
          touched={touched}
        />
        <span className="text_overview_description">
          <TextEditor
            name="description"
            control={control}
            placeholder="Add short description about session (optional)"
          />
        </span>
        <DoctTypography variant="subtitle2" className="text-grey-800">
          Speaker/ Presenter (optional)
        </DoctTypography>
        <DoctTypography variant="subtitle2" className="text-grey-600">
          Add speakers info for this session.
        </DoctTypography>
        <DoctTextField
          showStar
          className="mb-2"
          name="speakers"
          label="Add names here"
          id="speakers"
          control={control}
          isErrors={errors}
          defaultValue=""
          validationRules={{}}
          touched={touched}
        />
      </form>
    </>
  );
}

export default ManageScheduleSession;
