import React, { useEffect, useState } from 'react';
import { DoctDatePickerV2, DoctFormCheckbox } from '@docthub.frontend/app';
import { DoctCol, DoctRow, DoctTypography } from 'doct-core';
import dayjs from 'dayjs';

function DateRangePicker({ control, errors, fromDate, setValue }) {
  const [fDate, setFDate] = useState(null);
  const [tDate, setTDate] = useState(null);
  const today = new Date();

  useEffect(() => {
    if (tDate !== null && tDate < fDate) {
      setValue('fromDate', null);
    }
  }, [tDate]);

  return (
    <DoctRow>
      <DoctCol xs={6}>
        <DoctDatePickerV2
          inputProps={{
            label: 'From date',
            id: 'fromDate',
            dateFormat: 'dd MMM yyyy',
            autoComplete: 'off',
            maxDate: today,
          }}
          showStar={true}
          control={control}
          isErrors={errors}
          name="fromDate"
          onChange={(selectedDate) => {
            setFDate(dayjs(selectedDate).toDate());
            setValue('toDate', null);
          }}
          withController={true}
        />
      </DoctCol>
      <DoctCol xs={6}>
        <DoctDatePickerV2
          inputProps={{
            label: 'To date',
            id: 'toDate',
            dateFormat: 'dd MMM yyyy',
            minDate: fromDate ? dayjs(fromDate).toDate() : undefined, // set minDate only if fromDate is available
            autoComplete: 'off',
            maxDate: today,
          }}
          control={control}
          isErrors={errors}
          showStar={true}
          name="toDate"
          withController={true}
          onChange={(selectedDate) => setTDate(dayjs(selectedDate).toDate())}
        />
      </DoctCol>
    </DoctRow>
  );
}

export default function AwardeesListingFilterPopUp({
  control,
  handleSubmit,
  errors,
  setValue,
  watch,
  touched,
}) {
  const isEmailPending = watch('emailPending');
  const isEmailSent = watch('emailSent');

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        <DoctTypography variant="subtitle2" className="mt-0 form-heading-mb">
          Certificate issue Date Range
        </DoctTypography>
        <div className="d-flex">
          <DateRangePicker
            touched={touched}
            control={control}
            errors={errors}
            setValue={setValue}
          />
        </div>
        <div className="line-divider bg-grey-300 my-3 mx-n3"></div>
        <DoctTypography variant="subtitle2" className="mt-0 form-heading-mb">
          Email Status
        </DoctTypography>
        <DoctFormCheckbox
          name="emailPending"
          label="Email Pending"
          id="checkbox"
          control={control}
          isErrors={errors.emailPending}
          validationRules={{}}
          className="d-block"
          disabled={isEmailSent}
        />
        <DoctFormCheckbox
          name="emailSent"
          label="Email Sent Already"
          id="checkbox"
          control={control}
          isErrors={errors.emailSent}
          validationRules={{}}
          className="d-block"
          disabled={isEmailPending}
        />
      </form>
    </>
  );
}
