// import { DoctButton, DoctContainer, DoctTypography } from 'doct-core';
// import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { postJobInquiry } from '../../services/manageJob.services';
// import OfferCard from './OfferCard';
// import { docthubJobsData, talentAcquisitionData } from './talentAcquisitionCtaData';
// import { userSelector } from '../../../../../components';
// import {
//   selectIsLoadingPostJobInquiry,
//   selectIsButtonTextChange,
// } from '../../slice/manageJobSlice';

// const TalentAcquisitionCTA = () => {
//   const dispatch = useDispatch();
//   const user = useSelector(userSelector);
//   const isButtonTextChange = useSelector(selectIsButtonTextChange);
//   const isLoadingPostJobInquiry = useSelector(selectIsLoadingPostJobInquiry);

//   const imInterestedHandler = () => {
//     const {
//       name,
//       mobileNumber,
//       email,
//       tenant: { organizationName },
//     } = user;
//     const mobileNumberWithoutCode = () => {
//       if (!mobileNumber) return '';
//       const splitNumber = mobileNumber.split('-');
//       return splitNumber?.length > 1 ? splitNumber[1] : splitNumber[0];
//     };
//     dispatch(
//       postJobInquiry({ name, mobileNo: mobileNumberWithoutCode(), email, organizationName }),
//     );
//   };

//   return (
//     <>
//       <DoctContainer>
//         <div className="bg-primary-100 border-radius p-5 mb-4">
//           <div className="d-flex align-items-center justify-content-center">
//             <OfferCard
//               cardBg="card_bg_low_opacity"
//               offerTitle={'Docthub Jobs'}
//               offerSpecifications={docthubJobsData}
//               ctaElement={
//                 <div className="d-flex align-items-center justify-content-between mt-4">
//                   <DoctTypography variant="subtitle1" className="text-success">
//                     FREE PLAN
//                   </DoctTypography>
//                   <DoctTypography variant="subtitle1" className="text-grey-400">
//                     Already in use
//                   </DoctTypography>
//                 </div>
//               }
//             />
//             <OfferCard
//               offerTitleColor="text-black"
//               offerTitle={'Recruiter Dashboard Plus '}
//               offerSpecifications={talentAcquisitionData}
//               ctaElement={
//                 <div className="my-3">
//                   <DoctButton
//                     type={isButtonTextChange ? 'semantic-success' : 'primary'}
//                     className={
//                       isButtonTextChange ? 'semantic-success-btn-with-low-opacity w-100' : 'w-100'
//                     }
//                     text={isButtonTextChange ? 'Your interest registered!' : 'I am Interested!'}
//                     onButtonClickHandler={isButtonTextChange ? null : imInterestedHandler}
//                     disabled={isLoadingPostJobInquiry}
//                   />
//                   {isButtonTextChange && (
//                     <DoctTypography
//                       variant="textLabel2"
//                       className="text-grey-400 text-center mt-6px mb-0"
//                     >
//                       Docthub team will contact you shortly.
//                     </DoctTypography>
//                   )}
//                 </div>
//               }
//             />
//           </div>
//         </div>
//       </DoctContainer>
//     </>
//   );
// };

// export default TalentAcquisitionCTA;

import React from 'react';
import rocketIllustration from '../../../../../assets/icons/boost-img.png';
import { DoctContainer, DoctTypography } from 'doct-core';

const TalentAcquisitionCTA = () => {
  return (
    <DoctContainer>
      <div className="w-100 my-4">
        <div className="product_objective_text border-radius d-flex align-items-center p-2">
          <img
            src={rocketIllustration}
            alt="rocket_illustration"
            className="px-3"
            width={80}
            height={50}
          />

          <span className="mx-2">
            <DoctTypography variant="body1">
              <span className="font-weight-bold">
                For further assistance or information, you can contact us at{' '}
                <a href="tel:9510403130">(+91) 95104 03130</a> or Email at{' '}
                <a href="mailto:jobs@docthub.com">jobs@docthub.com</a>
              </span>
            </DoctTypography>
          </span>
        </div>
      </div>
    </DoctContainer>
  );
};

export default TalentAcquisitionCTA;
