import { DoctTextField } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React, { useEffect } from 'react';

export default function CertificateFileNamePopUp({
  searchText,
  control,
  errors,
  touched,
  handleFormSubmit,
  setValue,
  editCertificateName,
}) {
  useEffect(() => {
    if (searchText) {
      setValue('searchText', searchText);
    }
    if (!searchText && editCertificateName) {
      setValue('searchText', editCertificateName);
    }
  }, [searchText]);

  return (
    <>
      <form onSubmit={handleFormSubmit} autoComplete="off">
        <DoctTypography variant="subtitle3" className="m-0 form-heading-mb">
          CERTIFICATE FILE NAME
        </DoctTypography>
        <DoctTextField
          className="mt-12px pb-3"
          name="searchText"
          label="Certificate File Name"
          showStar={true}
          id="certificateName"
          control={control}
          isErrors={errors}
          value={editCertificateName}
          validationRules={{
            required: "It's required",
            maxLength: {
              value: 100,
              message: 'Maximum 100 characters allowed.',
            },
            pattern: {
              value:
                /^(?! +$)(?:[a-zA-Z]*[a-zA-Z0-9]+(?:[a-zA-Z0-9@_-]*[a-zA-Z])?(?:[a-zA-Z0-9@_-]+\s*|\s*)*)+$/,
              message: 'Certificate File Name is not valid',
            },
          }}
          touched={touched}
        />
      </form>
    </>
  );
}
