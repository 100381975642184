import React from 'react';
import { DoctButton, DoctTypography } from 'doct-core';

import * as ROUTE from '../../../../routes/constant';

function ECertificateEmptyQuickAccess({ navigate }) {
  return (
    <>
      <DoctTypography variant="textLabel1" className="font-weight-medium text-grey-600 text-center">
        {/* A cloud office of your association. */}
        No Certificated Added
      </DoctTypography>
      <div className="d-flex justify-content-center">
        <DoctButton
          icon="plus"
          variant="contained"
          size="medium"
          text="Create Now"
          onButtonClickHandler={() => {
            navigate(`/${ROUTE.DASHBOARD}/${ROUTE.E_CERTIFICATE}/${ROUTE.ALL_E_CERTIFICATES}`);
          }}
        />
      </div>
    </>
  );
}

export default ECertificateEmptyQuickAccess;
