import React, { useState, useEffect } from 'react';
import { DoctForm } from '@docthub.frontend/app';
import BillingInfo from '../Form/BillingInfo';
import AccountDetail from '../Form/AccountDetail';
import * as ROUTE from '../../../routes/constant';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { selectFormData, setFormData } from '../SubsciptionServicesSlice';
import { prepObjectForFormData } from '../utility/prepObjectForFormSubmit';
import { useGetCountryQuery } from '../../../hooks/useLocation/useLocations.services';
import {
  setAmountDetails,
  setBillingDetails,
  setPrepData,
  setSuccessFormDetails,
} from '../API/subscriptionApiSlice';

/**
 * useSendSubscription Hook
 *
 * This custom hook manages the state and logic for sending subscription data.
 * It handles form control, submission, and communication with the server.
 *
 * @param {string} id - The ID of the subscription.
 * @returns {Object} An object containing various properties and functions for managing the subscription.
 *
 * @property {Function} handleSubmit - A function to handle form submission.
 * @property {Object} control - React Hook Form control object.
 * @property {Object} errors - Form errors object.
 * @property {Object} formState - React Hook Form form state.
 * @property {Function} reset - Function to reset the form.
 * @property {Object} touched - Form touched object.
 * @property {Function} watch - React Hook Form watch function.
 * @property {Function} setValue - React Hook Form setValue function.
 * @property {Array} formParts - An array of form sections with child components.
 * @property {Function} navigate - Function for navigating between routes.
 * @property {boolean} isSubmitting - A flag indicating whether the form is currently submitting.
 * @property {null|string} submitError - A message indicating any submission errors.
 * @property {Array} states - An array of available states for a country.
 * @property {Object} billingDetails - Billing details for the subscription.
 */

const useSendSubscription = (id) => {
  const organizationName = useSelector((state) => state?.app?.user?.tenant?.organizationName);
  const billingDetails = useSelector((state) => state?.subscriptionData?.billingDetails);
  const userEmail = useSelector((state) => state?.app?.user?.email);
  const userMobile = useSelector((state) => state?.app?.user?.mobile);
  const { token } = useSelector((state) => state.app);
  const selectedSubscriptionFormData = useSelector(selectFormData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [states, setStates] = useState([]);
  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  const { id: billingInfoId } = params;
  const { state } = location;

  const { data: countryData } = useGetCountryQuery();
  useEffect(() => {
    if (countryData?.length > 0) {
      setStates(countryData[0]?.states);
    }
  }, [countryData?.length]);

  const formParts = [
    {
      formSectionHeading: 'Billing Address',
      childElement: (
        <BillingInfo
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          touched={touched}
        />
      ),
    },
    {
      formSectionHeading: 'Account Details',
      childElement: (
        <AccountDetail
          control={control}
          errors={errors}
          watch={watch}
          setValue={setValue}
          touched={touched}
          billingDetails={billingDetails}
        />
      ),
    },
  ];

  useEffect(() => {
    const prepFormData = {
      ...(Object.keys(selectedSubscriptionFormData.subscriptionForm || {}).length
        ? selectedSubscriptionFormData.subscriptionForm
        : billingDetails),
      state: {
        value:
          selectedSubscriptionFormData?.subscriptionForm?.state?.value || billingDetails?.state,
        label:
          selectedSubscriptionFormData?.subscriptionForm?.state?.value || billingDetails?.state,
      },
      city: {
        value: selectedSubscriptionFormData?.subscriptionForm?.city?.value
          ? selectedSubscriptionFormData?.subscriptionForm?.city?.value
          : billingDetails?.city,
        label: selectedSubscriptionFormData?.subscriptionForm?.city?.value
          ? selectedSubscriptionFormData?.subscriptionForm?.city?.value
          : billingDetails?.city,
      },
    };
    reset(prepFormData);
  }, [selectedSubscriptionFormData.subscriptionForm, billingDetails?.id]);

  const onSubmit = async (data) => {
    const prepFormData = {
      ...data,
      state: data?.state?.value,
      city: data?.city?.value,
      country: 'India',
      organization: data?.organization || organizationName,
      organizationName: data?.organization || organizationName,
      mobileNumber: userMobile,
      emailId: userEmail,
      gstNo: data.gstNo ? data.gstNo : null,
      taxId: data.taxId ? data.taxId : null,
      pincode: data.pincode ? data.pincode : null,
    };

    prepObjectForFormData.subscriberOrder.subscriberBillingInformation = prepFormData;

    const newObjForBilling = { ...billingDetails, ...prepFormData };
    dispatch(setBillingDetails(newObjForBilling));

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      let response;

      if (Number(billingInfoId)) {
        response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/subscriber/addition-information/${billingInfoId}`,
          {
            method: 'PUT',
            body: JSON.stringify(prepFormData),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response?.ok) {
          const billingDetailsUrl = `${window.location.origin}/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`;
          window.location.replace(billingDetailsUrl);
          return;
        }
      } else if (billingInfoId === ':id') {
        response = await fetch(
          `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/subscriber/addition-information`,
          {
            method: 'POST',
            body: JSON.stringify(prepFormData),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          },
        );

        dispatch(setFormData(data));

        if (!response.ok) {
          setSubmitError('Failed to validate form.');
          throw new Error('Failed to submit the subscription.');
        }

        if (response?.ok) {
          const billingDetailsUrl = `${window.location.origin}/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_DETAILS}`;
          window.location.replace(billingDetailsUrl);
          return;
        }
      } else {
        if (state?.planId) {
          const validateRecruiterSubscription = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/plan/${state.planId}/bundle/${state.bundleId}/validate-recruiter-subscription`,
            {
              method: 'POST',
              body: JSON.stringify(prepFormData),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          );
          if (!validateRecruiterSubscription.ok) {
            setSubmitError('Failed to validate form.');
            return;
          }
          response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/subscriber/addition-information`,
            {
              method: 'POST',
              body: JSON.stringify(prepFormData),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          );

          // response = await fetch(
          //   `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${state.planId}/bundle/${state.bundleId}/subscription`,
          //   {
          //     method: 'POST',
          //     body: JSON.stringify({
          //       subscriberOrder: {
          //         subscriberBillingInformation: {
          //           ...prepFormData,
          //         },
          //       },
          //     }),
          //     headers: {
          //       'Content-Type': 'application/json',
          //       Authorization: `Bearer ${token}`,
          //     },
          //   },
          // ).then((res) => res.json());

          prepObjectForFormData.subscriberBillingInformation = prepFormData;

          dispatch(setPrepData(prepFormData));

          dispatch(setFormData(data));

          const successResponse = await validateRecruiterSubscription.clone().json();

          dispatch(setAmountDetails(successResponse));

          dispatch(setSuccessFormDetails(successResponse));

          navigate(
            `/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/${ROUTE.ORDER_SUMMARY}?=recruiter-dashbord`,
          );
          // navigate(redirectUrl);

          // if (!response.ok) {
          //   setSubmitError('Failed to validate form.');
          //   throw new Error('Failed to submit the subscription.');
          // }
        } else {
          const validateResponse = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/${id}/validatesubscription`,
            {
              method: 'POST',
              body: JSON.stringify(prepFormData),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          );

          if (!validateResponse.ok) {
            setSubmitError('Failed to validate form.');
            return;
          }
          response = await fetch(
            `${process.env.REACT_APP_BASE_API_URL}/coursecenter/tenant/subscriber/addition-information`,
            {
              method: 'POST',
              body: JSON.stringify(prepFormData),
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            },
          );

          dispatch(setFormData(data));

          if (!response.ok) {
            setSubmitError('Failed to validate form.');
            throw new Error('Failed to submit the subscription.');
          }

          prepObjectForFormData.subscriberBillingInformation = prepFormData;

          dispatch(setPrepData(prepFormData));

          dispatch(setFormData(data));

          const successResponse = await validateResponse.clone().json();

          dispatch(setAmountDetails(successResponse));

          dispatch(setSuccessFormDetails(successResponse));

          navigate(
            `/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.ALL_PACKAGES}/${ROUTE.BILLING_INFO}/${ROUTE.ORDER_SUMMARY}`,
          );
        }
      }
    } catch (error) {
      setSubmitError('Failed to submit the subscription. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit: handleSubmit(onSubmit),
    control,
    errors,
    formState,
    reset,
    touched,
    watch,
    setValue,
    formParts,
    navigate,
    isSubmitting,
    submitError,
    states,
    billingDetails,
  };
};

export default useSendSubscription;
