import React from 'react';
import { DoctChip, DoctActionMenu } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import dayjs from 'dayjs';

import defaultImage from '../../../../../assets/images/activityLog/default-activity-log.png';
import { CommonBox } from '../../../../../shared';

import style from './Card.ActivityLog.module.scss';

const MAX_LENGHT_TO_SHOW_CHIPS = 4;

const options = [
  {
    title: 'View',
  },
  {
    title: 'Edit',
  },
  {
    title: 'Delete',
    className: 'text-danger',
  },
];

function ChipsItem({ memberName }) {
  return (
    <li className="mr-6px">
      <DoctChip title={memberName} showCloseIcon={false} small />
    </li>
  );
}

function ChipsList({ array }) {
  if (array.length > MAX_LENGHT_TO_SHOW_CHIPS) {
    return (
      <>
        {array?.slice(0, MAX_LENGHT_TO_SHOW_CHIPS).map(({ memberName }, i) => {
          return <ChipsItem key={i} memberName={memberName} />;
        })}
        <DoctTypography variant="body2" className="my-0 ml-2">
          + {array.length - MAX_LENGHT_TO_SHOW_CHIPS}
        </DoctTypography>
      </>
    );
  }
  return array.map(({ memberName }, i) => {
    return <ChipsItem key={i} memberName={memberName} />;
  });
}

export default function CardActivityLog({ activity, cardMenuOptions }) {
  const { id, members, title, startDate, coverImageUrl } = activity || {};

  return (
    <CommonBox className="d-flex card-vertical-spacer">
      <div className={`${style['card-activity-log-thumbnail']} border-radius overflow-hidden`}>
        <img src={coverImageUrl || defaultImage} alt="activity log" className="object-fit-cover" />
      </div>
      <div className="p-3 position-relative w-100">
        <DoctTypography variant="subtitle1" className="mt-0 mb-1">
          {title}
        </DoctTypography>
        <DoctTypography variant="textLabel2" className="mt-0 mb-1 text-grey-600">
          {dayjs(startDate).format('DD MMM YYYY')}
        </DoctTypography>
        <div className="d-flex align-items-center">
          <DoctTypography variant="textLabel2" className="my-0 text-grey-600">
            Project Team:
          </DoctTypography>
          <ul className="d-flex ml-6px">{members?.length ? <ChipsList array={members} /> : '-'}</ul>
        </div>
        <DoctActionMenu
          btnType="inverse"
          className={`${style['card-activity-log-action-menu']} position-absolute rotate90`}
          options={options}
          onClick={(item) => {
            cardMenuOptions(item, id, activity);
          }}
        />
      </div>
    </CommonBox>
  );
}
