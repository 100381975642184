import { DoctIcon, DoctTypography } from 'doct-core';
import React from 'react';
import * as ROUTE from '../../../routes/constant';
import style from './CommonSubscriptionCard.module.scss';
import SubscriptionChip from '../CommonChip/SubscriptionChip';
import useSendSubscription from '../hooks/useSendSubscription';
import { useGetOrderDetailsQuery } from '../API/subscriptionApi';

export default function CommonSubscriptionCard({
  cardTitle,
  planName,
  yearValidity,
  date,
  status,
  orderId,
  packageName,
}) {
  const { navigate } = useSendSubscription();
  const { data } = useGetOrderDetailsQuery(orderId);

  return (
    <div
      className={`${style['mySubscription-card-body']} bg-white d-flex justify-content-between align-items-center mt-3 mb-n1`}
    >
      <div className="d-flex">
        <div className={`${style['instituteIcon-bg']} p-3 mr-12px`}>
          {packageName === 'Institute Dashboard' ? (
            <DoctIcon name="courseEnterpriseFilled" width="28" height="28" className="mb-n1" />
          ) : (
            <DoctIcon name="jobsEnterpriseFilled" width="28" height="28" className="mb-n1" />
          )}
        </div>
        <div className="align-self-center">
          <DoctTypography variant="subtitle3" className={`${style['text-mb']} text-grey-600 m-0`}>
            {yearValidity === '-1 Lifetime' ? date.split(' to ')[0] : date}
          </DoctTypography>
          <DoctTypography
            variant="textLabel1"
            className={`${style['text-mb']} text-grey-800 m-0 font-weight-bold`}
          >
            {cardTitle} Subscription
          </DoctTypography>
          <div className="d-flex align-items-center text-grey-600">
            <DoctTypography variant="subtitle3" className="m-0">
              {planName}
            </DoctTypography>
            <DoctTypography variant="subtitle3" className="m-0 mt-sm-n2">
              &nbsp; . &nbsp;
            </DoctTypography>
            <DoctTypography variant="subtitle3" className="m-0">
              {parseInt(yearValidity) < 0
                ? 'Lifetime'
                : yearValidity === '12 Month'
                ? '1 Year Access'
                : `${yearValidity}`}
            </DoctTypography>

            <DoctTypography variant="subtitle3" className="m-0 mt-sm-n2">
              &nbsp; . &nbsp;
            </DoctTypography>
            <DoctTypography variant="subtitle3" className="m-0">
              Amount Paid: {data?.amount?.totalAmount ?? 'null'}
            </DoctTypography>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className="mt-sm-n1">
          <SubscriptionChip subscriptionChipText={status} isExpired={status === 'Expired'} />
        </div>
        <div
          onClick={() =>
            navigate(
              `/${ROUTE.DASHBOARD}/${ROUTE.BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.SUBSCRIPTION_DETAILS}/${orderId}`,
            )
          }
        >
          <DoctIcon
            width="24"
            height="24"
            name="right"
            className="ml-3 pl-1 cursor-pointer"
            fill="#ABABAB"
          />
        </div>
      </div>
    </div>
  );
}
