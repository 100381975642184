/* eslint-disable prettier/prettier */
import { DoctContainer } from 'doct-core';
import React from 'react';
import PageSEO from '../shared/SEO/PageSEO';
import { CommonContent, CommonHeading, CommonTitle } from './CommonContent';
import { paymentPolicyEnterprise } from './PaymentPolicyEnterprise';
import LandingPageHeader from '../layout/dashboardBrandingCommonLayout/LandingPageHeader/LandingPageHeader';
import FooterForPolicy from './DoctFooter';

const generateListItems = (startIndex, endIndex, marginTop = 0) => {
  const listItems = [];
  for (let i = startIndex; i <= endIndex; i++) {
    const content = paymentPolicyEnterprise[i]?.content;
    listItems.push(
      <li
        key={i}
        dangerouslySetInnerHTML={{ __html: content }}
        className={`text-grey-500 lh-35${marginTop > 0 ? ' mt-' + marginTop : ''}`}
      />,
    );
  }
  return listItems;
};

const PaymentPolicyEnterpriseFooter = () => {
  return (
    <>
      <PageSEO
        title={'Payment Policy | Docthub Enterprise'}
        description={
          ' Docthub.com is a digital platform for healthcare professionals, offering healthcare career and professional upgrade website as well as Android and iOS Mobile app for Users.  '
        }
      />
      <LandingPageHeader enterpriseHeader />
      <div className="privacy-policy-modal static-modal">
        <CommonHeading heading={paymentPolicyEnterprise[0]?.heading} />
        <DoctContainer>
          <CommonContent
            content={paymentPolicyEnterprise[1]?.content}
            className={'mt-3 text-grey-500'}
          />
          <CommonContent
            content={paymentPolicyEnterprise[2]?.content}
            className={'text-grey-500 lh-35 m-0'}
          />
          <CommonTitle title={paymentPolicyEnterprise[3]?.title} />
          <CommonContent
            content={paymentPolicyEnterprise[4]?.content}
            className={'text-underline text-grey-500 lh-35'}
          />
          <CommonTitle title={paymentPolicyEnterprise[5]?.title} />
          <CommonContent
            content={paymentPolicyEnterprise[6]?.content}
            className={'text-grey-500 lh-35'}
          />
          <CommonTitle title={paymentPolicyEnterprise[7]?.title} />
          <CommonContent
            content={paymentPolicyEnterprise[8]?.content}
            className={'text-grey-500 lh-35'}
          />
          <ul className="static-list static-list-item">{generateListItems(9, 32)}</ul>
          <CommonTitle title={paymentPolicyEnterprise[33]?.title} />
          <ul className="static-list static-list-item">{generateListItems(34, 56, 3)}</ul>
          <CommonTitle title={paymentPolicyEnterprise[57]?.title} />
          <CommonContent
            content={paymentPolicyEnterprise[58]?.content}
            className={'text-grey-600 lh-35'}
          />
          <ul className="mt-2 static-list static-list-item">{generateListItems(59, 60)}</ul>
        </DoctContainer>
      </div>
      <FooterForPolicy />
    </>
  );
};

export default PaymentPolicyEnterpriseFooter;
