import { DoctIcon } from 'doct-core';
import { Tooltip } from '@mui/material';
import React from 'react';

const ApplicantActionTypes = ({ column }) => {
  return (
    <>
      <div className="custom-tabel-row-el my-0 px-2 d-flex">
        {column?.actionTypes?.isSendInquiry && (
          <Tooltip title="Applied Directly" arrow>
            <div>
              <DoctIcon name="appliedDirectly" height="24" width="24" />
            </div>
          </Tooltip>
        )}
        {column?.actionTypes?.isInstituteWebsite && (
          <Tooltip title="Apply To Website" arrow>
            <div>
              <DoctIcon name="applyOnWebsite" height="24" width="24" />
            </div>
          </Tooltip>
        )}
        {(column?.actionTypes?.actionName === 'Viewed Contact Detail' ||
          column?.actionTypes?.isViewContactDetail) && (
          <Tooltip title="Viewed Contact" arrow>
            <div>
              <DoctIcon name="viewedContact" height="24" width="24" />
            </div>
          </Tooltip>
        )}
        {column?.actionTypes?.isBrochureDownloaded && (
          <Tooltip title="Brochure Downloaded" arrow>
            <div>
              <DoctIcon name="downloadedBrochure" height="24" width="24" />
            </div>
          </Tooltip>
        )}
        {column?.isBookmarked && (
          <Tooltip title="Bookmarked" arrow>
            <div>
              <DoctIcon name="bookmarkOutline" fill="#8E8E8E" height="20" width="20" />
            </div>
          </Tooltip>
        )}
      </div>
    </>
  );
};

export default ApplicantActionTypes;
