import { DoctForm } from '@docthub.frontend/app';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveApiData,
  saveCapacityUsed,
  saveMaxAttendees,
  saveSaveAsDraft,
  saveTicketsDetails,
  selectAttendeeValue,
  selectBasicInfoDetails,
  selectCapacityUsed,
  selectCreateEventResponse,
  selectCurrentStep,
  selectNewlyAddedCategory,
  selectResponseData,
  selectSaveAsDraftClicked,
  selectTicketCategory,
  selectUpdateEventLoader,
  setCurrentStep,
  setDefaultState,
  setStepsFormData,
} from '../../../createEvent.slice';
import stepsName from '../stepsName';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { updateCreateEvent } from '../../../services/CreateEventServices';

const formNameWithDefaultProps = () => {
  return {};
};

export default function useFormTickets() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const ticketCategory = useSelector(selectTicketCategory);
  const createdEventResponse = useSelector(selectResponseData);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const BasicInfoDetails = useSelector(selectBasicInfoDetails);
  const draftBtnClicked = useSelector(selectSaveAsDraftClicked);
  const loading = useSelector(selectUpdateEventLoader);
  const navigate = useNavigate();
  const maxAttendeesForEvent = useSelector(selectAttendeeValue);
  const newlyAddedCategory = useSelector(selectNewlyAddedCategory);
  const capacity = useSelector(selectCapacityUsed);

  const [inrTickets, setInrTickets] = useState([]);
  const [usdTickets, setUsdTickets] = useState([]);
  const [editAttendeeValue, setEditAttendeeValue] = useState(maxAttendeesForEvent);
  const [isAttendeevalueChange, setIsAttendeevalueChange] = useState(false);
  const [registrationType, setRegistrationType] = useState('No');
  const [gstRegistration, setGSTRegistration] = useState('false');
  const [tarrif, setTarrif] = useState('');
  const [emptyCategories, setEmptyCategories] = useState(false);
  const [successfullHandleSubmit, setSuccessfullHandleSubmit] = useState(false);
  const [isFreeEventTicketEmpty, setIsFreeEventTicketEmpty] = useState(false);
  const [tarrifValidation, setTarrifValidation] = useState(false);
  const [inrTotalSeatCapacity, setInrTotalSeatCapacity] = useState(0);
  const [usdTotalSeatCapacity, setUsdTotalSeatCapacity] = useState(0);
  const [checkAmountIsZero, setCheckAmountIsZero] = useState(false);
  const [firstTimeNotRender, setFirstTimeNotRender] = useState(false);
  const [isCapacityValidation, setIsCapacityValidation] = useState(false);

  useEffect(() => {
    const totalCapacity = inrTotalSeatCapacity + usdTotalSeatCapacity;
    dispatch(saveCapacityUsed(totalCapacity));
  }, [inrTotalSeatCapacity, usdTotalSeatCapacity]);

  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  useEffect(() => {
    if (draftBtnClicked == true) {
      if (successfullHandleSubmit) {
        if (!loading) {
          dispatch(saveSaveAsDraft(false));
          dispatch(setDefaultState());
          navigate(-1);
        }
      } else {
        handleFormSubmit();
      }
    }
  }, [draftBtnClicked, successfullHandleSubmit, loading]);

  useMemo(() => {
    if (Object.keys(errors)?.length) {
      dispatch(saveSaveAsDraft(false));
    }
  }, [Object.keys(errors)?.length]);

  useMemo(() => {
    if (firstTimeNotRender) {
      if (Object.keys(ticketCategory).length === 0) return;
      const name = ticketCategory?.ticketCategories?.map((item) => {
        return item?.name;
      });

      if (inrTickets?.length !== 0) {
        const newArrayForInrReg = [];
        inrTickets?.map((item) => {
          let newObj = {};
          if (item.id) {
            newObj.id = item.id;
          }
          newObj.attendeeType = item.attendeeType;
          newObj.notDefined = item.notDefined;
          newObj.numberOfSeats = item.numberOfSeats;
          newObj.ticketInUse = item.ticketInUse;
          newObj.category = {};
          const oldINRCategory = name.filter((nameItem) =>
            Object.keys(item?.category).includes(nameItem),
          );
          if (oldINRCategory.length > 0) {
            oldINRCategory?.map((oldCategoryName) => {
              const categoryName = {};
              newObj.category[oldCategoryName] = categoryName;
              Object?.entries(item?.category)?.map(([key, value]) => {
                if (key === oldCategoryName) {
                  categoryName.code = value.code;
                  categoryName.amount = value.amount;
                }
              });
            });
          }
          if (newlyAddedCategory?.length > 0) {
            newlyAddedCategory?.map(({ name }) => {
              const categoryName = {};
              newObj.category[name] = categoryName;
              categoryName.code = 'INR';
              categoryName.amount = 0;
            });
          }
          newArrayForInrReg.push(newObj);
        });
        setInrTickets(newArrayForInrReg);
      }
      if (usdTickets?.length !== 0) {
        const newArrayForUsdReg = [];
        usdTickets?.map((item) => {
          let newObj = {};
          if (item.id) {
            newObj.id = item.id;
          }
          newObj.attendeeType = item.attendeeType;
          newObj.notDefined = item.notDefined;
          newObj.numberOfSeats = item.numberOfSeats;
          newObj.ticketInUse = item.ticketInUse;
          newObj.category = {};
          const oldUSDCategory = name.filter((nameItem) =>
            Object.keys(item?.category).includes(nameItem),
          );
          if (oldUSDCategory.length > 0) {
            oldUSDCategory?.map((oldCategoryName) => {
              const categoryName = {};
              newObj.category[oldCategoryName] = categoryName;
              Object?.entries(item?.category)?.map(([key, value]) => {
                if (key === oldCategoryName) {
                  categoryName.code = value.code;
                  categoryName.amount = value.amount;
                }
              });
            });
          }
          if (newlyAddedCategory?.length > 0) {
            newlyAddedCategory?.map(({ name }) => {
              const categoryName = {};
              newObj.category[name] = categoryName;
              categoryName.code = 'USD';
              categoryName.amount = 0;
            });
          }
          newArrayForUsdReg.push(newObj);
        });
        setUsdTickets(newArrayForUsdReg);
      }
      setFirstTimeNotRender(false);
    }
  }, [ticketCategory]);

  const handleFormSubmit = handleSubmit((values) => {
    if (Object.keys(ticketCategory).length === 0) {
      setEmptyCategories(true);
    } else if (editAttendeeValue < capacity) {
      setIsCapacityValidation(true);
    } else {
      if (BasicInfoDetails?.registrationType == 'Paid') {
        if (Object.keys(apiResponseData)?.length) {
          values.invoiceDetail = { ...apiResponseData?.invoiceDetail };
          values.invoiceDetail.isGSTRegistration = gstRegistration;
        } else {
          values.invoiceDetail = {};
          values.invoiceDetail.isGSTRegistration = gstRegistration;
        }
      }
      values.tariffTaxation = tarrif ? tarrif : '';
      if (
        values?.invoiceDetail?.isGSTRegistration == 'true' &&
        BasicInfoDetails?.registrationType == 'Paid' &&
        values.tariffTaxation == ''
      ) {
        setTarrifValidation(true);
      } else {
        values.tickets = [];
        if (usdTickets?.length >= 1) {
          usdTickets.map((item) => {
            const newObj = {};
            if (item.id) {
              newObj.id = item.id;
            }
            newObj.attendeeType = item.attendeeType;
            newObj.notDefined = item.notDefined;
            newObj.numberOfSeats = item.numberOfSeats;
            newObj.ticketInUse = item.ticketInUse;
            newObj.isDisable = item.isDisable;
            newObj.Currency = 'USD';
            newObj.prices = [];
            Object.entries(item.category).map(([key, { amount }]) => {
              const prices = {};
              prices.category = key;
              prices.amount = amount ? amount : 0;
              newObj.prices.push(prices);
            });
            values.tickets.push(newObj);
          });
          values.foreignRegistrationRequired = true;
        }
        if (inrTickets?.length >= 1) {
          inrTickets.map((item) => {
            const newObj = {};
            if (item.id) {
              newObj.id = item.id;
            }
            newObj.attendeeType = item.attendeeType;
            newObj.notDefined = item.notDefined;
            newObj.numberOfSeats = item.numberOfSeats;
            newObj.ticketInUse = item.ticketInUse;
            newObj.isDisable = item.isDisable;
            newObj.Currency = 'INR';
            newObj.prices = [];
            Object.entries(item.category).map(([key, { amount }]) => {
              const prices = {};
              prices.category = key;
              prices.amount = amount ? amount : 0;
              newObj.prices.push(prices);
            });
            values.tickets.push(newObj);
          });
        }
        values.maxAttendees = editAttendeeValue ? editAttendeeValue : BasicInfoDetails.maxAttendees;

        if (values.tickets?.length <= 0) {
          setIsFreeEventTicketEmpty(true);
        } else {
          const check = values.tickets?.some((item) => {
            const checkAmount = item?.prices?.some(({ amount }) => amount === 0);
            return checkAmount;
          });
          if (check && BasicInfoDetails?.registrationType == 'Paid') {
            setCheckAmountIsZero(true);
          } else {
            if (createdEventResponse?.id) {
              values.Id = createdEventResponse.id;
            }
            if (id) {
              values.Id = id;
              dispatch(saveApiData({ ...apiResponseData, ...values }));
            }
            dispatch(saveMaxAttendees(values.maxAttendees));
            dispatch(saveTicketsDetails(values));
            dispatch(updateCreateEvent(values.Id)); // PUT API Call
            setSuccessfullHandleSubmit(true);
            if (draftBtnClicked == false) {
              if (loading == false) {
                dispatch(setCurrentStep(currentStep + 1));
                dispatch(setStepsFormData({ [stepsName.tickets.name]: values }));
              }
            }
          }
        }
      }
    }
  });

  return {
    formName: formNameWithDefaultProps(control, errors),
    handleFormSubmit,
    control,
    errors,
    formState,
    reset,
    touched,
    watch,
    setValue,
    setInrTickets,
    inrTickets,
    usdTickets,
    setUsdTickets,
    setEditAttendeeValue,
    editAttendeeValue,
    isAttendeevalueChange,
    setIsAttendeevalueChange,
    registrationType,
    setRegistrationType,
    gstRegistration,
    setGSTRegistration,
    tarrif,
    setTarrif,
    emptyCategories,
    setEmptyCategories,
    isFreeEventTicketEmpty,
    setIsFreeEventTicketEmpty,
    tarrifValidation,
    setTarrifValidation,
    setInrTotalSeatCapacity,
    setUsdTotalSeatCapacity,
    setCheckAmountIsZero,
    checkAmountIsZero,
    setFirstTimeNotRender,
    setIsCapacityValidation,
    isCapacityValidation,
  };
}
