import React from 'react';
import { DoctTypography } from 'doct-core';
import { DoctModal } from '@docthub.frontend/app';

export const GuidelineModal = ({ isOpen, onClose }) => {
  return (
    <div>
      <DoctModal
        title="Job Posting Note"
        showFooter={false}
        open={isOpen}
        handleClose={onClose}
        width={600}
        className="disabled_modal_outside_click"
      >
        <ul className="static-list-item ml-3">
          <li>Job Title</li>
          <li>Job Location</li>
          <li>Job Description Overview (Minimum 200 Characters)</li>
          <li>Salary</li>
          <li>Preferred Education</li>
        </ul>
        <DoctTypography variant="subtitle1" className="font-weight-bold">
          Few Guidelines
        </DoctTypography>
        <ul className="static-list-item ml-3">
          <li>Provide a proper and specific job title. Multiple job titles will not be allowed.</li>
          <li>
            Add only one location for one job posting. If you wish to hire for multiple locations,
            create a duplicate job post.
          </li>
          <li>Job description should include clear and crisp job roles and responsibilities.</li>
          <li>Salary should be included as asked i.e. Monthly or Yearly.</li>
          <li>Education should be correct and according to the job title.</li>
        </ul>
        <DoctTypography variant="subtitle1">
          For any clarifications/queries/doubts, please contact - +91 9090902626
        </DoctTypography>
      </DoctModal>
    </div>
  );
};
