import { DoctTextField } from '@docthub.frontend/app';
import React from 'react';
import { FieldWrapper } from '../../../../../shared';
import fieldProfileSetting from '../field.profileSettings';

export default function FormNameDesignation({ hookFormProps }) {
  return (
    <form>
      <FieldWrapper>
        <DoctTextField
          {...hookFormProps}
          name={fieldProfileSetting.fullName.name}
          label={fieldProfileSetting.fullName.label}
          id={fieldProfileSetting.fullName.name}
          validationRules={fieldProfileSetting.fullName.validationRules}
        />
      </FieldWrapper>
      <FieldWrapper>
        <DoctTextField
          {...hookFormProps}
          name={fieldProfileSetting.designation.name}
          label={fieldProfileSetting.designation.label}
          id={fieldProfileSetting.designation.name}
          validationRules={fieldProfileSetting.designation.validationRules}
        />
      </FieldWrapper>
    </form>
  );
}
