import React from 'react';
import { DoctFileUpload, DoctModal } from '@docthub.frontend/app';
import { DoctButton, DoctCol } from 'doct-core';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentStep,
  selectRegistartionDetails,
  selectShowError,
  selectUpdateEventLoader,
  setCurrentStep,
  setShowError,
} from '../../../createEvent.slice';
import { StepTitle } from '../../UiHelper';
import stepsName from '../stepsName';
import { FixedPanel, Toaster } from '../../../../../../shared/ui';
import CurrentAndTotalSteps from '../../CurrentAndTotalSteps';
import useFormRegistration from './Form.Registration';
import RegistrationSection from './RegistrationFormSections/RegistrationSection';
import ContactInfoSection from './RegistrationFormSections/ContactInfoSection';
import { useEffect, useState } from 'react';
import FormGroup from '../../../../../../shared/FormGroup';
import OrganiserSection from './RegistrationFormSections/OrganiserSection';
import {
  checkKeyDownAndPreventDefault,
  prepareUploadedFile,
} from '../../../../../../helper/helperFunction';
import { TOST_TOP_RIGHT } from '../../../../../../constants/toasterPosition.constants';
import { setShowToasterWithParams } from '../../../../../featuresSlice';
import './RegistrationStyle.scss';
import AdditionalContactInfoSection from './RegistrationFormSections/AdditionalContactInfoSection';

export default function Registration() {
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  const [erorFormSubmit, setErrorFormSubmit] = useState(null);
  const [showTost, setShowTost] = useState(false);

  const {
    handleFormSubmit,
    control,
    errors,
    touched,
    setValue,
    specialtyDataOption,
    setSpecialtySearchText,
    setSpecialityArray,
    specialityArray,
    setSubjectTagsSearchText,
    setSubjectTagsArray,
    subjectTagsArray,
    subjectTagsDataOption,
    register,
    userMember,
    setUploadedFiles,
    uploadedFiles,
    setMembers,
    members,
    setPhotoSrc,
    photoSrc,
    profileUrlOnEdit,
    findSpecialityByName,
    supporters,
    setSupporters,
    watch,
    findSubjectTagsByName,
    defaultCountryValue,
    defaultStateValue,
    setResetBrochureUrl,
    resetBrochureUrl,
    setDeleteBrochureUrl,
    isSpecialtyEmpty,
    setDeleteCoverImageUrl,
    setProfileUrlOnEdit,
  } = useFormRegistration();

  const loading = useSelector(selectUpdateEventLoader);
  const showError = useSelector(selectShowError);
  const [countryCode, setCountryCode] = useState('+91');
  const [additionalDetails, setAdditionalDetails] = useState([]);
  const [isOpenModalOfFileLimit, setIsOpenModalOfFileLimit] = useState(false);

  const registartionDetails = useSelector(selectRegistartionDetails);

  useEffect(() => {
    if (Object.keys(registartionDetails).length) {
      setCountryCode(registartionDetails?.mobileCountryCode);
      setAdditionalDetails(registartionDetails?.additionalDetails || []);
    }
  }, [Object.keys(registartionDetails).length]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setShowError(false));
    }, 2000);
  }, [showError]);

  useEffect(() => {
    if (uploadedFiles?.length) {
      setValue('hiddenFileUpload', 'file');
    } else {
      setValue('hiddenFileUpload', null);
    }
  }, [uploadedFiles]);

  useEffect(() => {
    if (showTost) {
      setTimeout(() => {
        onTostCloseHandler();
      }, 2000);
    }
  }, [showTost]);

  const onTostCloseHandler = () => {
    setShowTost(false);
    setErrorFormSubmit(null);
  };

  return (
    <>
      <div className="mb-5">
        {showError && (
          <div className="position-fixed tost-container">
            <Toaster
              variant={'error'}
              text={erorFormSubmit?.Title || 'Oops! something went wrong'}
              onPressedClose={onTostCloseHandler}
            />
          </div>
        )}
        <form onSubmit={handleFormSubmit} onKeyDown={(e) => checkKeyDownAndPreventDefault(e)}>
          <DoctCol xs={8} className="mx-auto">
            <StepTitle>{stepsName.registration.label}</StepTitle>
            <RegistrationSection
              findSubjectTagsByName={findSubjectTagsByName}
              findSpecialityByName={findSpecialityByName}
              control={control}
              errors={errors}
              touched={touched}
              setSpecialtySearchText={setSpecialtySearchText}
              setSpecialityArray={setSpecialityArray}
              specialityArray={specialityArray}
              specialtyDataOption={specialtyDataOption}
              setSubjectTagsSearchText={setSubjectTagsSearchText}
              setSubjectTagsArray={setSubjectTagsArray}
              subjectTagsArray={subjectTagsArray}
              subjectTagsDataOption={subjectTagsDataOption}
              register={register}
              photoSrc={photoSrc}
              setPhotoSrc={setPhotoSrc}
              profileUrlOnEdit={profileUrlOnEdit}
              isSpecialtyEmpty={isSpecialtyEmpty}
              setDeleteCoverImageUrl={setDeleteCoverImageUrl}
              setValue={setValue}
              setProfileUrlOnEdit={setProfileUrlOnEdit}
            />
            <FormGroup className="mt-3" title="Upload Brochure (optional)">
              <DoctFileUpload
                uploadTitle="You can upload event details brochure for download."
                uploadMaxFilesMessage="Upload document in JPG, JPEG, PNG, PDF, DOC formats up to 5 MB size."
                maxFiles={1}
                uploadedFiles={prepareUploadedFile(
                  resetBrochureUrl && resetBrochureUrl,
                  uploadedFiles && uploadedFiles,
                )}
                setUploadedFiles={(uploadedFile) => {
                  if (!uploadedFile?.length) {
                    setResetBrochureUrl(null);
                    setDeleteBrochureUrl(true);
                    setUploadedFiles(null);
                  } else {
                    const fileExtension = uploadedFile[0]?.name?.toString()?.split('.')[1];
                    if (
                      !['jpeg', 'jpg', 'png', 'pdf', 'docx'].includes(fileExtension.toLowerCase())
                    ) {
                      dispatch(
                        setShowToasterWithParams({
                          variant: 'danger',
                          position: TOST_TOP_RIGHT,
                          message: 'Invalid File Type!',
                        }),
                      );
                      return;
                    }
                    setUploadedFiles(uploadedFile);
                  }
                }}
                accept=".pdf, .png, .jpg, .jpeg, .docx"
                maxFileSizeInMb={5}
              />
            </FormGroup>
            <div className="horizontal_line_grey position-relative mt-5 mb-4"></div>
            <OrganiserSection
              userMember={userMember}
              setMembers={setMembers}
              members={members}
              setSupporters={setSupporters}
              supporters={supporters}
              control={control}
              errors={errors}
              touched={touched}
              setValue={setValue}
              watch={watch}
              defaultCountryValue={defaultCountryValue}
              defaultStateValue={defaultStateValue}
              setCountryCode={setCountryCode}
            />
            <div className="horizontal_line_grey position-relative mt-5 mb-3"></div>
            <ContactInfoSection
              control={control}
              errors={errors}
              touched={touched}
              countryCode={countryCode}
              setValue={setValue}
            />
            <AdditionalContactInfoSection
              control={control}
              errors={errors}
              touched={touched}
              countryCode={countryCode}
              additionalDetails={additionalDetails}
              setAdditionalDetails={setAdditionalDetails}
              setValue={setValue}
              watch={watch}
            />
          </DoctCol>
        </form>
      </div>
      <FixedPanel
        container
        className="backdrop-filter"
        contentClassName="d-flex align-items-center py-12px"
      >
        <CurrentAndTotalSteps />
        <DoctButton
          text="Back"
          variant="outline"
          className="mr-2"
          onButtonClickHandler={() => {
            dispatch(setCurrentStep(currentStep - 1));
          }}
        />
        <DoctButton
          disabled={loading}
          text="Save & Next"
          className=""
          onButtonClickHandler={() => {
            handleFormSubmit();
          }}
        />
        <DoctModal
          showFooter={false}
          open={isOpenModalOfFileLimit}
          handleClose={() => setIsOpenModalOfFileLimit(false)}
        >
          <div>You can upload 1 document only</div>
        </DoctModal>
      </FixedPanel>
    </>
  );
}
