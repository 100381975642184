import { DoctForm } from '@docthub.frontend/app';

export default function useFormCommon({ defaultValues = {} }) {
  const {
    handleSubmit,
    control,
    errors,
    register,
    watch,
    setValue,
    touched,
    clearErrors,
    reset,
    formState,
    getValues,
  } = DoctForm({
    mode: 'onChange',
    defaultValues,
  });

  return {
    formProps: {
      handleSubmit,
      control,
      errors,
      register,
      watch,
      setValue,
      touched,
      clearErrors,
      reset,
      formState,
      getValues,
    },
  };
}
