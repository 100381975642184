import React from 'react';
import { DoctModal } from '@docthub.frontend/app';
import { useSelector } from 'react-redux';
import { selectModalTitle } from '../slice/profile.slice';

export default function EditModal({ children, isLoading, onSubmitForm, handleModalClose }) {
  const modalTitle = useSelector(selectModalTitle);

  return (
    <DoctModal
      open
      iconName={''}
      disableBackdropClick
      isPrimaryBtnDisable={isLoading}
      primaryBtnText="Save Changes"
      secondaryBtnText="Cancel"
      handlePrimaryButtonClick={onSubmitForm}
      handleClose={handleModalClose}
      title={modalTitle}
      width={360}
      className="disable_modal_outside_click"
    >
      {children}
    </DoctModal>
  );
}
