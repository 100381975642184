import { DoctTypography } from 'doct-core';
import React from 'react';

export const CommonHeading = ({ heading }) => {
  return (
    <>
      <DoctTypography variant="h2" className="text-center py-5 m-5 text-black bg-grey-100 lh-2">
        <h2>{heading}</h2>
      </DoctTypography>
    </>
  );
};

export const CommonContent = ({ content, className }) => {
  return (
    <>
      <DoctTypography variant="subtitle1" className={`lh-35 mb-0 font-weight-normal ${className}`}>
        {content}
      </DoctTypography>
    </>
  );
};

export const CommonTitle = ({ title, className }) => {
  return (
    <>
      <DoctTypography variant="h5" className={`font-weight-bold mt-5 text-black ${className}`}>
        {title}
      </DoctTypography>
    </>
  );
};
