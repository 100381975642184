import React from 'react';
import { DoctButton, DoctContainer, DoctTypography } from 'doct-core';
import useSendSubscription from '../hooks/useSendSubscription';
import * as ROUTE from '../../../routes/constant';
import style from '../SubscriptionModal.module.scss';
import NeedAssistance from './NeedAssistance';
import FooterCopyRight from '../../../layout/components/Footer.CopyRight';
import PackageBoxRecruiter from './PakageBoxRecruiter';

const AllPackagesRecruiter = () => {
  const { navigate } = useSendSubscription();
  return (
    <div>
      <DoctContainer>
        <DoctButton
          onButtonClickHandler={() => {
            if (!location.pathname?.includes('all-packages/')) {
              navigate(-1);
            }
            navigate(
              `/${ROUTE.DASHBOARD}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}/${ROUTE.MANAGE_BILLING_SUBSCRIPTION}`,
            );
          }}
          icon="close"
          variant="text"
          width="28"
          className={`${style['close-icon-bg']} mt-2 d-flex`}
        />
        <DoctTypography variant="subtitle2" className="btn-text-positive text-center mb-1 mt-0">
          Recruiter Dashboard Subscription
        </DoctTypography>
        <DoctTypography variant="h3" className="text-center mb-4 pb-2">
          Choose a Plan
        </DoctTypography>

        <PackageBoxRecruiter />

        <div className={`${style['need-assistance-position']} w-100 container`}>
          <NeedAssistance Email="courses@docthub.com" Phone="+91 9510403130" />
        </div>
      </DoctContainer>
      <div className={`${style['subscription-modal-footer']} w-100`}>
        <FooterCopyRight />
      </div>
    </div>
  );
};

export default AllPackagesRecruiter;
