import { DoctButton, DoctContainer, DoctIconButton, DoctTypography } from 'doct-core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MANAGE_EVENTS } from '../../../../routes/constant';
import {
  fetchEventById,
  saveSaveAsDraft,
  selectCreateEventResponse,
  selectCurrentStep,
  selectOnSubmitDraftBtnDisable,
  selectRegistartionDetails,
  selectUpdateEventLoader,
  setDefaultState,
} from '../createEvent.slice';
import { ManageCloseEvent } from './ManageCloseEvent';
import { useNavigate } from 'react-router-dom';

function Header() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const apiResponseData = useSelector(selectCreateEventResponse);
  const registartionDetails = useSelector(selectRegistartionDetails);
  const [eventName, setEventName] = useState('');
  const [open, setOpen] = useState(false);
  const currentStep = useSelector(selectCurrentStep);
  const navigate = useNavigate();
  const saveBtnDraft = useSelector(selectOnSubmitDraftBtnDisable);
  const loading = useSelector(selectUpdateEventLoader);

  useEffect(() => {
    if (id == undefined) return;
    dispatch(fetchEventById(id));
  }, [id]);

  useEffect(() => {
    if (Object.keys(apiResponseData).length) {
      if (id == undefined) return;
      setEventName(apiResponseData.name);
    }
    if (Object.keys(registartionDetails).length) {
      setEventName(registartionDetails.name);
    }
  }, [apiResponseData, registartionDetails]);

  const DraftEvent = () => {
    dispatch(saveSaveAsDraft(true));
    if (currentStep === 8 || apiResponseData?.eventPaymentType === 'Free') {
      navigate(`${MANAGE_EVENTS}`);
      dispatch(setDefaultState());
    }
  };

  return (
    <div className="create-event-header bg-grey-100 panel-height-5x d-flex align-items-center position-sticky top-0">
      <DoctContainer>
        <DoctTypography
          variant="subtitle1"
          className="d-flex align-items-center my-0"
          fontWeight="normal"
        >
          <DoctIconButton
            variant="text"
            type="secondary"
            icon="close"
            size="medium"
            onButtonClickHandler={() => {
              setOpen(true);
            }}
          />
          {eventName ? eventName : 'Create an Event'}
          <DoctButton
            text="Save Draft"
            variant="outlined"
            size="medium"
            className="ml-lg-auto"
            onButtonClickHandler={DraftEvent}
            disabled={currentStep > 8 || saveBtnDraft || loading}
          />
        </DoctTypography>
      </DoctContainer>
      <ManageCloseEvent
        open={open}
        handleClose={() => {
          setOpen(false);
        }}
      />
    </div>
  );
}

export default React.memo(Header);
