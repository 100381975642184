import { DoctForm } from '@docthub.frontend/app';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { userSelector } from '../../../../components';
import { useGetCountryQuery } from '../../../../hooks/useLocation/useLocations.services';
import useQueryHooks from '../../../../hooks/useQueryHooks';
import {
  DASHBOARD,
  JOB_FAILED,
  JOB_SUBMITTED,
  POSTING,
  RECRUITER,
} from '../../../../routes/constant';
import { usePostJobMutation, useEditJobMutation } from '../services/manageJob.services';
import {
  selectApiData,
  selectEducationArray,
  selectJobRawFormValues,
  selectKeySkills,
  selectModifiedJobFormValues,
  setEducationArray,
  setKeySkills,
  setModifiedValues,
  setRawFormValues,
} from '../slice/manageJobSlice';
import { createFormData } from '../utils/createFormData';
import { transformArrayValues } from '../utils/transformArrayObject';
import useResetFormValues from './useResetFormValues';
import qs from 'qs';
import { setShowToasterWithParams } from '../../../featuresSlice';
import { TOST_TOP_RIGHT } from '../../../../constants/toasterPosition.constants';
import useFocus from '../../../../hooks/useFocus';

const defaultValue = {
  country: {
    label: 'India',
    value: 1,
  },
};

const useFormJobs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const [postJob, { isLoading: isJobPostLoading, isSuccess: isSuccessFullyAdded }] =
    usePostJobMutation();
  const [editJob, { isLoading: isJobEditLoading, isSuccess: isSuccessFullUpdated }] =
    useEditJobMutation();
  const rawFormValues = useSelector(selectJobRawFormValues);
  const modifiedJobFormValues = useSelector(selectModifiedJobFormValues);
  const jobExistingData = useSelector(selectApiData);
  const educationStateArray = useSelector(selectEducationArray);
  const keySkillsStateArray = useSelector(selectKeySkills);

  const [keySkillArray, setKeySkillArray] = useState(
    keySkillsStateArray?.length > 0 ? keySkillsStateArray : [],
  );
  const [qualificationArray, setQualificationArray] = useState(
    educationStateArray?.length > 0 ? educationStateArray : [],
  );
  const [isPreview, setIsPreview] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState('ActivationRequested');
  const [jobType, setJobType] = useState('');
  const jobId = pathname.replace(/[^0-9]/g, '');
  const { ...query } = useQueryHooks();
  const [workExperienceValidation, setWorkExperienceValidation] = useState(false);
  const [educationValidation, setEducationValidation] = useState(false);

  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const { data: locationData } = useGetCountryQuery('');

  const [locationValue, setLocationValue] = useState(null);
  const [formInitialValue, setFormInitialValue] = useState({});

  const { resetFormValues: resetFormValuesFromId } = useResetFormValues({
    resetData: jobExistingData,
    setQualificationArray,
    setKeySkillArray,
  });

  const { handleSubmit, control, errors, reset, watch, setValue, touched, getValues, register } =
    DoctForm({
      mode: 'onChange',
      defaultValues: {
        experience: '',
      },
    });

  const onFocus = useFocus(errors);

  useEffect(() => {
    onFocus();
  }, [errors]);

  useEffect(() => {
    if (isSuccessFullyAdded || isSuccessFullUpdated) {
      dispatch(setEducationArray(null));
      dispatch(setKeySkills(null));
      dispatch(setRawFormValues(null));
    }
  }, [isSuccessFullyAdded, isSuccessFullUpdated]);

  const flattenObject = (obj) => {
    const result = {};

    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const parts = key.split('.');
        const flattenedKey = parts.length > 1 ? parts[1] : key;
        result[flattenedKey] = obj[key];
      }
    }

    return result;
  };

  const resetValueAndSetToState = async (obj) => {
    await reset(obj);

    if (Object.keys(formInitialValue || {}).length) return;
    const flattenedObj = flattenObject(getValues());
    setFormInitialValue(flattenedObj);
  };

  const compareValue = (oldValue = {}, newValue = {}) => {
    let isDataEqual = false;

    const oldValueObj = { ...oldValue };
    const newValueObj = { ...newValue };
    const qualificationIdsValueArray = newValueObj.qualificationIds.map(({ value }) => value);

    delete oldValueObj.country;
    delete newValueObj.country;

    oldValueObj['stateId'] = oldValueObj?.state?.value;
    oldValueObj['cityId'] = oldValueObj?.city?.value;
    oldValueObj['Experience'] = jobExistingData['experience'];

    delete oldValueObj.state;
    delete oldValueObj.city;
    delete oldValueObj.organizationName;
    delete oldValueObj.qualificationId;

    delete newValueObj.state;
    delete newValueObj.status;
    delete newValueObj.city;
    delete newValueObj.countryId;
    delete newValueObj.organizationName;
    delete newValueObj.qualificationId;
    delete newValueObj.qualificationIds;
    delete newValueObj.keySkills;

    newValueObj.title = newValueObj?.title?.trim();

    if (
      qs.stringify(qualificationIdsValueArray) ==
        qs.stringify(jobExistingData['qualificationIds']) &&
      qs.stringify(newValue['keySkills']) == qs.stringify(jobExistingData['keySkills'])
    ) {
      if (
        qs.stringify(oldValueObj) == qs.stringify(newValueObj)
        // if check for - form value is equal or not
      ) {
        isDataEqual = true;
      }
    }
    return isDataEqual;
  };

  useEffect(() => {
    let prepareResetValue = {};

    if (Object.keys(rawFormValues || {})?.length) {
      prepareResetValue = {
        ...rawFormValues,
        country: {
          label: 'India',
          value: 1,
        },

        state: {
          label: rawFormValues?.state?.name,
          value: rawFormValues?.state?.id,
        },

        city: {
          label: rawFormValues?.city?.name,
          value: rawFormValues?.city?.id,
        },
      };
    }

    if ((jobId && resetFormValuesFromId) || (query?.duplicateId && resetFormValuesFromId)) {
      if (!Object.keys(jobExistingData || {})?.length) return;
      // Existing job edit
      // When form data fetch from API
      // // NOTE FIXME: fixed issue of not changing radio button by adding !jobType
      // if (resetFormValuesFromId?.experience ) {
      //   resetFormValuesFromId.experience = 'Experienced';
      //   // setJobType(resetFormValuesFromId?.experience);
      // }
      if (resetFormValuesFromId?.experience != 'Experienced') {
        resetFormValuesFromId.startYear = null;
        resetFormValuesFromId.endYear = null;
      }

      const locationObj = {
        state: resetFormValuesFromId?.state,
        city: resetFormValuesFromId?.city,
      };

      if (!Object.keys(formInitialValue || {})?.length) {
        resetValueAndSetToState({ ...resetFormValuesFromId, ...prepareResetValue });
      } else {
        reset({ ...resetFormValuesFromId, ...prepareResetValue });
      }
      setLocationValue(locationObj);
    } else if (Object.keys(rawFormValues || {})?.length) {
      // Existing job edit
      // When form data loaded from state

      if (!Object.keys(formInitialValue || {})?.length) {
        const locationObj = {
          state: { label: rawFormValues.state.name, value: rawFormValues?.stateId },
          city: { label: rawFormValues.city.name, value: rawFormValues?.cityId },
        };
        setLocationValue(locationObj);
        resetValueAndSetToState(prepareResetValue);
      } else {
        reset(prepareResetValue);
      }
    } else {
      if (!Object.keys(formInitialValue || {})?.length) {
        resetValueAndSetToState(defaultValue);
      } else {
        reset(defaultValue);
      }
    }
  }, [jobExistingData, isPreview, rawFormValues]);

  const handleJobFormSubmit = handleSubmit((values, param) => {
    try {
      if ((values.experience == '' || values.experience == undefined) && !isPreview) {
        setWorkExperienceValidation(true);
      } else if (qualificationArray?.length == 0 && !isPreview) {
        setEducationValidation(true);
      } else {
        dispatch(setEducationArray(qualificationArray));
        dispatch(setKeySkills(keySkillArray));
        values['qualificationIds'] = qualificationArray;
        values['keySkills'] = keySkillArray;
        values['status'] = applicationStatus;

        values['countryId'] = values?.country?.value;
        values['stateId'] = values?.state?.value;
        values['cityId'] = values?.city?.value;

        values['country'] = locationData?.find(({ id }) => id == values?.country?.value);
        values['state'] = values?.country?.states?.find(({ id }) => id == values?.state?.value);
        values['city'] = values?.state?.cities?.find(({ id }) => id == values?.city?.value);
        values['Experience'] = values.experience;

        if (query?.duplicateId && compareValue(formInitialValue, values)) {
          dispatch(
            setShowToasterWithParams({
              variant: 'error',
              position: TOST_TOP_RIGHT,
              message: 'Can not Duplicate Job with same data',
            }),
          );
          return;
        }
        if (param?.allowToPostADraftJob) {
          if (Object.keys(modifiedJobFormValues)?.length) {
            const status = 'Draft';
            return handleApiCall(
              jobId,
              modifiedJobFormValues,
              status,
              param.allowToPostADraftJob,
              param.state,
            );
          }
        }

        if (param) {
          if (Object.keys(modifiedJobFormValues)?.length) {
            const status = 'Draft';
            handleApiCall(jobId, modifiedJobFormValues, status);
          } else {
            const status = 'Draft';
            const transformedValues = transformArrayValues(values);
            handleApiCall(jobId, transformedValues, status);
          }
        } else {
          dispatch(setRawFormValues(values));
          dispatch(setModifiedValues(transformArrayValues(values)));
          setIsPreview(true);
        }
      }
    } catch (e) {
      return e;
    }
  });

  const handleApiCall = (jobId, values, status, allowToPostADraftJob, state) => {
    const valuesObj = { ...values, status };
    delete valuesObj.country;
    delete valuesObj.state;
    delete valuesObj.city;

    if (!jobId) {
      postJob({ formValues: createFormData(valuesObj) })
        .then((res) => {
          if (res?.error?.status) {
            navigate(`/${DASHBOARD}/${RECRUITER}/${JOB_FAILED}/${user?.tenant?.id}`);
          } else if (status === 'Draft' && allowToPostADraftJob) {
            navigate(`/dashboard/billing-subscription/subscription/all-packages/2`, {
              replace: true,
              state: { ...state },
            });
          } else if (navigate(`/${DASHBOARD}/${RECRUITER}/${POSTING}`)) {
            navigate(`/${DASHBOARD}/${RECRUITER}/${POSTING}`);
          } else {
            navigate(`/${DASHBOARD}/${RECRUITER}/${JOB_SUBMITTED}/${user?.tenant?.id}`);
          }
        })
        .catch((error) => console.info(error));
    } else {
      editJob({ formValues: createFormData(valuesObj), id: jobId })
        .then((res) => {
          if (res?.error?.status) {
            navigate(`/${DASHBOARD}/${RECRUITER}/${JOB_FAILED}/${user?.tenant?.id}`);
          } else if (status == 'Draft' && allowToPostADraftJob) {
            navigate(`/dashboard/billing-subscription/subscription/all-packages/2`, {
              replace: true,
              state: { ...state },
            });
          } else {
            navigate(`/${DASHBOARD}/${RECRUITER}/${JOB_SUBMITTED}/${user?.tenant?.id}`);
          }
          dispatch(setRawFormValues({}));
          dispatch(setModifiedValues({}));
        })
        .catch((error) => error);
    }
  };

  const handleOnPreviewApproved = handleSubmit((values, paramsObj) => {
    const { status = 'ActivationRequested' } = paramsObj || {};
    handleApiCall(jobId, modifiedJobFormValues, status);
  });

  return {
    handleJobFormSubmit,
    control,
    errors,
    watch,
    setValue,
    register,
    qualificationArray,
    setQualificationArray,
    keySkillArray,
    setKeySkillArray,
    handleOnPreviewApproved,
    setIsPreview,
    isPreview,
    modifiedJobFormValues,
    setApplicationStatus,
    // isLoading,
    isJobPostLoading,
    isJobEditLoading,
    jobType,
    setJobType,
    touched,
    locationValue,
    getValues,
    jobExistingData,
    setWorkExperienceValidation,
    workExperienceValidation,
    setEducationValidation,
    educationValidation,
  };
};

export default useFormJobs;
