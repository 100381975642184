import React from 'react';
import { DoctButton, DoctIcon } from 'doct-core';
import {
  CREATE_EVENT,
  EVENTS,
  INSTITUTE,
  INSTITUTE_ADD_COURSE,
  POST_A_JOB,
  RECRUITER,
} from '../../../routes/constant';
import { Dropdown, DropdownListEl, DropdownMenu } from '../../../shared/ui';
import { useNavigate } from 'react-router-dom';

export default function AddMenu() {
  const navigate = useNavigate();

  return (
    <Dropdown>
      <DoctButton icon="plus" text="New" />
      <DropdownMenu className="add-menu-quick-access">
        <DropdownListEl onClick={() => navigate(`/dashboard/${RECRUITER}/${POST_A_JOB}`)}>
          <DoctIcon width="20" height="20" name="plus" className="text-primary mr-2" />
          Post a Job
        </DropdownListEl>
        <DropdownListEl onClick={() => navigate(`/dashboard/${INSTITUTE}/${INSTITUTE_ADD_COURSE}`)}>
          <DoctIcon width="20" height="20" name="plus" className="text-primary mr-2" />
          List a Course
        </DropdownListEl>
        <DropdownListEl onClick={() => navigate(`/dashboard/${EVENTS}/${CREATE_EVENT}`)}>
          <DoctIcon width="20" height="20" name="plus" className="text-primary mr-2" />
          Create an Event
        </DropdownListEl>
      </DropdownMenu>
    </Dropdown>
  );
}
