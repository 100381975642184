import { DoctTextField } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';
import React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../../components';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const JobOrganizationInfo = ({ control, errors, setValue }) => {
  const user = useSelector(userSelector);

  setValue('organizationName', user?.tenant?.organizationName);

  return (
    <>
      <DoctTypography variant="subtitle2">Organization Name</DoctTypography>
      <DoctTextField
        control={control}
        isErrors={errors}
        name="organizationName"
        id="organizationName"
        label=""
        readOnly
        validationRules={{}}
        disabled
      />

      <DoctTypography variant="subtitle2">Organization Profile (optional)</DoctTypography>

      <Controller
        rules={{}}
        render={({ onChange, value }) => {
          return (
            <TextField
              variant="standard"
              onChange={onChange}
              multiline
              rows={2}
              maxRows={4}
              value={value}
              label={'About Organization'}
              className={`doct-input doct-text-area${value ? ' doct-input-focused' : ''}`}
            />
          );
        }}
        name={'aboutOrganization'}
        control={control}
      />

      {/* <DoctTextArea
        control={control}
        isErrors={errors}
        className="p-2"
        label={'About Organization'}
        name="aboutOrganization"
        id="aboutOrganization"
        validationRules={{}}
      /> */}
      <div className="my-2">
        <Controller
          rules={{}}
          render={({ onChange, value }) => {
            return (
              <TextField
                variant="standard"
                onChange={onChange}
                multiline
                rows={2}
                maxRows={4}
                value={value}
                label={`Organization's Address Details`}
                className={`doct-input doct-text-area${value ? ' doct-input-focused' : ''}`}
              />
            );
          }}
          name={'organizationContactAndAddressDetails'}
          control={control}
        />
        {/* <DoctTextArea
          control={control}
          className="p-2"
          isErrors={errors}
          label={"Organization's Address Details"}
          name="organizationContactAndAddressDetails"
          id="organizationContactAndAddressDetails"
          validationRules={{}}
        /> */}
      </div>
    </>
  );
};

export default JobOrganizationInfo;
