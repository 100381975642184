import { DoctForm } from '@docthub.frontend/app';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveApiData,
  saveIsDateChanged,
  saveSaveAsDraft,
  saveScheduleAndSpeakersDetails,
  selectCreateEventResponse,
  selectCurrentStep,
  selectResponseData,
  selectSaveAsDraftClicked,
  selectScheduleAndSpeakersDetails,
  selectScheduleDetails,
  selectUpdateEventLoader,
  setCurrentStep,
  setDefaultState,
  setStepsFormData,
} from '../../../createEvent.slice';
import stepsName from '../stepsName';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { updateCreateEvent } from '../../../services/CreateEventServices';

const formNameWithDefaultProps = () => {
  return {};
};

export default function useFormScheduleSpeakers() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector(selectCurrentStep);
  // const basicInfo = useSelector(selectBasicInfoDetails);
  const scheduleDetails = useSelector(selectScheduleDetails);
  const createdEventResponse = useSelector(selectResponseData);
  const apiResponseData = useSelector(selectCreateEventResponse);
  const loading = useSelector(selectUpdateEventLoader);
  const schedulesAndSpeakers = useSelector(selectScheduleAndSpeakersDetails);
  const draftBtnClicked = useSelector(selectSaveAsDraftClicked);
  const navigate = useNavigate();

  const { handleSubmit, control, errors, formState, reset, touched, watch, setValue } = DoctForm({
    mode: 'onChange',
    defaultValues: {},
  });

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [speakers, setSpeakers] = useState([]);

  const [successfullHandleSubmit, setSuccessfullHandleSubmit] = useState(false);

  const [resetScheduleDocumentUrl, setResetScheduleDocumentUrl] = useState();
  const [deleteScheduleDocumentUrl, setDeleteScheduleDocumentUrl] = useState(false);

  useEffect(() => {
    const values = { ...schedulesAndSpeakers };
    if (values.ScheduleDocumentFile) {
      setUploadedFiles([values.ScheduleDocumentFile]);
    }
  }, [schedulesAndSpeakers]);

  useEffect(() => {
    if (draftBtnClicked == true) {
      if (successfullHandleSubmit) {
        if (!loading) {
          dispatch(saveSaveAsDraft(false));
          dispatch(setDefaultState());
          navigate(-1);
        }
      } else {
        handleFormSubmit();
      }
    }
  }, [draftBtnClicked, successfullHandleSubmit, loading]);

  useMemo(() => {
    if (Object.keys(errors)?.length) {
      dispatch(saveSaveAsDraft(false));
    }
  }, [Object.keys(errors)?.length]);

  const handleFormSubmit = handleSubmit((values) => {
    let transformSpeakers;

    if (speakers?.length) {
      transformSpeakers = speakers.map((objOfSpeakers) => {
        return {
          ...objOfSpeakers,
          keySpeakerProfileFile:
            objOfSpeakers?.keySpeakerProfileFile?.[0] || objOfSpeakers?.keySpeakerProfileFile,
        };
      });
    }
    values.keySpeakers = transformSpeakers;

    values.ScheduleDocumentFile = uploadedFiles ? uploadedFiles[0] : null;
    values.schedules = [];
    Object.entries(scheduleDetails).map(([key, { ScheduleSlots }]) => {
      if (key != 'ScheduleSlots') {
        const newObj = {};
        newObj.date = key;
        newObj.scheduleSlots = ScheduleSlots || [];
        values.schedules.push(newObj);
      }
    });

    if (deleteScheduleDocumentUrl == true && apiResponseData?.scheduleDocumentUrl) {
      values.deleteScheduleDocumentUrl = true;
    }
    if (createdEventResponse.id) {
      values.Id = createdEventResponse.id;
    }
    dispatch(saveIsDateChanged(false));
    if (id) {
      values.Id = id;
      dispatch(saveApiData({ ...apiResponseData, ...values }));
    }
    dispatch(saveScheduleAndSpeakersDetails(values));
    dispatch(updateCreateEvent(values.Id)); // PUT API Call
    setSuccessfullHandleSubmit(true);
    if (draftBtnClicked == false) {
      if (loading == false) {
        dispatch(setCurrentStep(currentStep + 1));
      }
    }
    dispatch(setStepsFormData({ [stepsName.scheduleAndSpeakers.name]: values }));
  });

  return {
    formName: formNameWithDefaultProps(control, errors),
    handleFormSubmit,
    control,
    errors,
    formState,
    reset,
    touched,
    watch,
    setValue,
    setSpeakers,
    speakers,
    uploadedFiles,
    setUploadedFiles,
    resetScheduleDocumentUrl,
    setResetScheduleDocumentUrl,
    setDeleteScheduleDocumentUrl,
  };
}
