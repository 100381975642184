import React from 'react';
import { useDispatch } from 'react-redux';

import { DoctButton, DoctIcon, DoctTypography } from 'doct-core';
import { DoctActionMenu } from '@docthub.frontend/app';

import {
  setModalTitle,
  setSubCommitteeId,
  toggleDesignationModal,
  toggleSubCommitteeModal,
} from '../../slice/committee.slice';
import ListDesignation from '../List.Designation';

const options = [
  {
    title: 'Edit Committee Name',
  },
  {
    title: 'Delete Committee',
    className: 'text-danger',
  },
];

export default function ConferenceCommittee({
  subCommitteesRecords,
  setSubCommitteeIdAndName,
  setIsModalOpen,
}) {
  const dispatch = useDispatch();

  return (
    <div className="bg-white-low-opacity p-1 border-radius mt-3">
      {!subCommitteesRecords.archiveId && (
        <>
          <span
            aria-hidden="true"
            onClick={() => {
              dispatch(setModalTitle('Add Designation'));
              dispatch(toggleDesignationModal());
              dispatch(setSubCommitteeId(subCommitteesRecords.id));
            }}
            className="cursor-pointer line-height-0"
          >
            <DoctIcon
              name="plus"
              width="24"
              height="24"
              fill="#00A0C0"
              className="position-absolute add-committee-icon mr-5"
            />
          </span>

          <DoctActionMenu
            options={options}
            className="position-absolute add-committee-icon more_action_menu mr-12px mr-md-3 mt-n2"
            onClick={(item) => {
              if (item.title === 'Edit Committee Name') {
                dispatch(setModalTitle('Edit Sub-Committee Name'));
                dispatch(toggleSubCommitteeModal());
                dispatch(setSubCommitteeId(subCommitteesRecords.id));
              }
              if (item.title === 'Delete Committee') {
                setSubCommitteeIdAndName({
                  id: subCommitteesRecords.id,
                  name: subCommitteesRecords?.name,
                });
                setIsModalOpen(true);
              }
            }}
          />
        </>
      )}
      <DoctTypography
        variant="subtitle2"
        fontWeight="medium"
        className="m-0 text-grey-600 p-1 text-center"
      >
        {subCommitteesRecords?.name}
      </DoctTypography>
      {subCommitteesRecords?.designations?.length > 0 ? (
        <ListDesignation designations={subCommitteesRecords?.designations} />
      ) : (
        <>
          {!subCommitteesRecords.archiveId && (
            <div className="bg-grey-200 p-3 border-radius d-flex justify-content-center">
              <DoctButton
                text="Add Designation"
                type="primary"
                iconPosition="left"
                variant="contained"
                className=""
                icon="plus"
                size="medium"
                onButtonClickHandler={() => {
                  dispatch(setModalTitle('Add Designation'));
                  dispatch(toggleDesignationModal());
                  dispatch(setSubCommitteeId(subCommitteesRecords.id));
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
