import React from 'react';
import { useDispatch } from 'react-redux';

import { DoctButton, DoctIcon } from 'doct-core';

import { Dropdown, DropdownListEl, DropdownMenu } from '../../../../shared';

import {
  setModalTitle,
  toggleDesignationModal,
  toggleSubCommitteeModal,
} from '../slice/committee.slice';

const ActionButton = React.memo(function RegistrationAction() {
  const dispatch = useDispatch();

  return (
    <Dropdown className="ml-auto">
      <DoctButton variant="contained" text={`New`} size="medium" icon={`plus`} />
      <DropdownMenu style={{ right: 0, top: '24px' }}>
        <DropdownListEl
          onClick={() => {
            dispatch(setModalTitle('Add Designation'));
            dispatch(toggleDesignationModal());
          }}
        >
          <DoctIcon width="20" height="20" name="plus" className="text-primary mr-2" />
          Add Designation
        </DropdownListEl>
        <DropdownListEl
          onClick={() => {
            dispatch(setModalTitle('Create Sub-Committee'));
            dispatch(toggleSubCommitteeModal());
          }}
        >
          <DoctIcon width="20" height="20" name="plus" className="text-primary mr-2" />
          Create Sub-Committee
        </DropdownListEl>
      </DropdownMenu>
    </Dropdown>
  );
});

export default ActionButton;
