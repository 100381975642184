import { DoctButton, DoctContainer, DoctTypography } from 'doct-core';
import React from 'react';
import { useLocation } from 'react-router';

const FormFooter = ({
  formDisclaimer,
  isFooterShow = true,
  formPrimaryBtnText,
  btnType = 'semantic-info',
  formFooterSecondaryBtnText,
  showFormFooterSecondaryBtn = false,
  formFooterSecondaryBtnType = 'primary',
  formFooterSecondaryBtnClickHandler = () => {},
  isAdding,
  isConfirmationScreen,
}) => {
  const location = useLocation();
  return (
    <>
      {isFooterShow && (
        <div
          className={`pt-3 bg-white ${
            isConfirmationScreen && 'position-absolute w-100 isConfirmationScreen'
          }  ${
            location?.pathname ===
              '/dashboard/subscription/all-packages/billing-info/order-summary' &&
            'footer-bottom-none position-fixed'
          }`}
        >
          <DoctContainer>
            <div className="d-flex align-items-center justify-content-between">
              <DoctTypography variant="textLabel2">{formDisclaimer}</DoctTypography>
              <div className="d-flex">
                {showFormFooterSecondaryBtn && (
                  <DoctButton
                    onButtonClickHandler={formFooterSecondaryBtnClickHandler}
                    className="mx-2"
                    type={formFooterSecondaryBtnType}
                    size="large"
                    text={formFooterSecondaryBtnText}
                    variant="outlined"
                  />
                )}
                <DoctButton
                  type={btnType}
                  size="large"
                  text={formPrimaryBtnText}
                  className={
                    formPrimaryBtnText === 'Payment Failed' ? 'text-white bg-active-green' : 'mb-3'
                  }
                  disabled={isAdding}
                />
              </div>
            </div>
          </DoctContainer>
        </div>
      )}
    </>
  );
};

export default FormFooter;
