import React, { useState } from 'react';
import { DoctTabContent, DoctTabWrapper } from '@docthub.frontend/app';

import ArchivesCommittee from '../Archives.Committee';

import './CurrentCommittee.scss';
import DesignationCommittee from '../Designation.Committee';

const options = [
  {
    label: 'Current Committee',
  },
  {
    label: 'Archives',
  },
];

export default function DefaultStateCommittee() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <DoctTabWrapper tabOptions={options} value={value} handleChange={handleChange} />

      {value === 0 && (
        <DoctTabContent value={value} index={0}>
          <DesignationCommittee />
        </DoctTabContent>
      )}
      {value === 1 && (
        <DoctTabContent value={value} index={1}>
          <ArchivesCommittee />
        </DoctTabContent>
      )}
    </>
  );
}
