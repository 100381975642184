import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { DoctAutoCompleteWithChips } from '@docthub.frontend/app';
import { DoctTypography } from 'doct-core';

import { FormElWithHeading } from '../../../../shared';
import fieldCommittee from '../components/Common/field.Committee';

import {
  selectCommitteeMemberOptions,
  selectCommitteeMembers,
  setCommitteeMembers,
} from '../slice/committee.slice';

function CommitteeMembers({ isMembersEmpty }) {
  const dispatch = useDispatch();

  const selectedCommitteeMembersOptions = useSelector(selectCommitteeMemberOptions);
  const selectedCommitteeMembers = useSelector(selectCommitteeMembers);

  const handleOnChange = (val) => {
    dispatch(setCommitteeMembers(val));
  };
  return (
    <>
      <FormElWithHeading heading={fieldCommittee.assignMembers.label}>
        <DoctTypography variant="body2" className="text-grey-600">
          Search members or select from the list.
        </DoctTypography>
        <DoctAutoCompleteWithChips
          textFieldProps={{ label: 'Search or Select' }}
          options={selectedCommitteeMembersOptions || []}
          value={
            selectedCommitteeMembersOptions?.filter(({ id }) =>
              selectedCommitteeMembers?.find(({ id: valueId }) => id == valueId),
            ) || []
          }
          autoCompleteProps={{
            disableCloseOnSelect: true,
          }}
          onChange={handleOnChange}
        />
        {isMembersEmpty && <p className="custom-validation-error">Its required</p>}
      </FormElWithHeading>
    </>
  );
}

export default CommitteeMembers;
