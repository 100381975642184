import { DoctActionMenu } from '@docthub.frontend/app';
import React, { useEffect, useRef, useState } from 'react';
import CustomTable from '../../../shared/ui/CustomTable/CustomTable';
import { courseApplicantTableColumn } from './components/data/tableColumn.courseApplicants';
import ApplicantCollpsable from './components/ApplicantCollpsable';
import useQueryHooks from '../../../hooks/useQueryHooks';
import { useGetCoursesQuery } from '../Courses/services/course.service';
import {
  useGetApplicantDataQuery,
  useUpdateCourseApplicantStatusMutation,
} from './services/courseApplicant.services';
import NoApplicantStage from './components/noApplicantsState/NoApplicantState';
import APICourseApplicantReport from './services/APICourseApplicantReport.service';
import CourseApplicantFilter from './CourseApplicantFilter';
import OnNoCourseAdded from '../Courses/components/OnNoCourseAdded/OnNoCourseAdded';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllApplicants, setAllApplicants } from '../Courses/ManageCourse/ManageCourseSlice';
import { useNavigate } from 'react-router';

const InstituteCourseApplicants = () => {
  const {
    data: allCourseData,
    // isFetching: tenetCoursesFetching,
    isLoading: isAllCourseLoading,
  } = useGetCoursesQuery({ Status: ['Published', 'UnPublished'] });
  const [dropdownMenuDefaultSelectedItem, setDropdownMenuDefaultSelectedItem] = useState();
  const { id, ...query } = useQueryHooks(); // reading id from query
  const [tabStatus, setTabStatus] = useState('');

  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const [updateStatus] = useUpdateCourseApplicantStatusMutation();
  const [isBlur, setIsBlur] = useState(false);
  const [defaultID] = useState(0);
  const isViewedApplicants = useRef(null);

  const navigate = useNavigate();

  const allApllicants = useSelector(selectAllApplicants);

  const {
    data: allApplicantData,
    isFetching: isApplicantsLoading,
    refetch: allApplicantDataRefetch,
  } = useGetApplicantDataQuery({
    id: id || dropdownMenuDefaultSelectedItem?.id || defaultID,
    searchText: query?.searchText,
    status: tabStatus,
    isBlur: isBlur,
  });

  const dropDownMenuItemsArrayFromApi = [];

  // eslint-disable-next-line no-unused-vars
  const [selectedItemId, setSelectedItemId] = useState(0);
  const { tenantCourse } = allCourseData || {};
  const [dropDownMenuItemsArray, setDropDownMenuItemsArray] = useState([]);
  const [applicantTableRowData, setApplicantTableRowData] = useState([]);
  const [applicantsCounts, setApplicantsCounts] = useState(0);
  const [tabChangeValue, setTabChangeValue] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [isOpen, setIsOpen] = useState(true);
  const [status, setStatus] = useState('');
  const [excelClicked, setExcelClicked] = useState(false);
  const [allApplicantsTab, setAllApplicantsTab] = useState(true);

  const selectAllApplicantsData = useSelector(selectAllApplicants);
  const tabStatusOption = [
    { label: 'All Applicants' },
    { label: 'UnRead' },
    { label: 'Reviewed' },
    { label: 'Saved' },
    { label: 'Archived' },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    if (+id) {
      setDropdownMenuDefaultSelectedItem(dropDownMenuItemsArray.find((course) => course?.id == id));
    } else {
      setDropdownMenuDefaultSelectedItem(
        dropDownMenuItemsArray[0] || dropDownMenuItemsArrayFromApi[0] || {},
      );
    }
  }, [id, dropDownMenuItemsArray, dropDownMenuItemsArrayFromApi?.length]);

  useEffect(() => {
    tenantCourse?.map((course) => {
      dropDownMenuItemsArrayFromApi?.push({
        title: course.courseTitle.name,
        id: course.id,
        value: course.id,
      });
    });
    dropDownMenuItemsArrayFromApi.unshift({ title: 'All Courses', id: 0, value: 'All Courses' });
    setDropDownMenuItemsArray(dropDownMenuItemsArrayFromApi);
  }, [tenantCourse]);

  const CourseApplicantCollapsible = ({ column }) => {
    return <ApplicantCollpsable column={column} courseId={id || dropDownMenuItemsArray[0]?.id} />;
  };

  useEffect(() => {
    if (allApllicants?.courseApplicants) {
      setApplicantTableRowData(allApllicants?.courseApplicant);
      setApplicantsCounts(allApllicants?.totalRecords);
    } else {
      setApplicantTableRowData(allApplicantData?.courseApplicant);
      setApplicantsCounts(allApplicantData?.totalRecords);
    }
  }, [allApplicantData, allApllicants?.courseApplicant?.length]);

  useEffect(() => {
    if (!id) {
      navigate('?id=0');
    }
  }, []);

  useEffect(() => {
    // Check if searchText is present and its length meets your condition
    if (query?.searchText?.length > 0) {
      // Filter out items where isBlur is true
      const filteredData = allApplicantData?.courseApplicant.filter(
        (applicant) => !applicant.isBlur,
      );

      setApplicantTableRowData(filteredData);
    }
  }, [query?.searchText]); // Dependency array ensures this effect runs whenever searchText changes

  useEffect(() => {
    if (
      applicantTableRowData !== undefined &&
      Array.isArray(applicantTableRowData) &&
      applicantTableRowData.length > 0
    ) {
      setIsBlur(applicantTableRowData[0].isBlur);
    }
  }, [applicantTableRowData]);

  const downloadCourseApplicantDetails = async () => {
    setExcelClicked(true);
    if (allApllicants?.totalRecords > 0) {
      isViewedApplicants.current = true;
    }
    const response = APICourseApplicantReport(
      dropdownMenuDefaultSelectedItem?.id,
      query?.searchText,
      allApplicantsTab ? '' : status,
      isViewedApplicants?.current,
    );

    response.then((res) => {
      if (res?.status) {
        setExcelClicked(false);
      }
    });
  };

  const handleTabChange = (_, value) => {
    if (value !== 0) {
      setAllApplicantsTab(false);
    } else {
      setAllApplicantsTab(true);
    }

    const selectedTabLabel =
      value === 2
        ? 'Reviewed'
        : value === 1
        ? 'UnRead'
        : value === 3
        ? 'Saved'
        : value === 4
        ? 'Archived'
        : '';
    setTabStatus(selectedTabLabel === 'Reviewed' ? 'Read' : selectedTabLabel);
    setTabChangeValue(value);
  };

  useEffect(() => {
    allApplicantDataRefetch();
  }, [tabStatus, window.location.href]);

  useEffect(() => {
    dispatch(setAllApplicants({}));
  }, [window.location.href]);

  const menuItems = [
    { title: 'Reviewed', value: 'Read' },
    { title: 'UnRead', value: 'UnRead' },
    { title: 'Save for later', value: 'Saved' },
    { title: 'Archive', value: 'Archived' },
  ];

  const returnStatusClassName = (value) => {
    if (value == 'UnRead' || value == 'Archived') {
      return 'applicant_status_select_status_default';
    } else if (value == 'Saved') {
      return 'applicant_status_select_status_save';
    } else if (value == 'Read') {
      return 'applicant_status_select_status_default';
    }
  };

  const handleButtonStatusChange = (value, column) => {
    setApplicantTableRowData((prev) =>
      prev.map((applicant) => {
        if (applicant?.userId === column?.userId && column?.status === 'UnRead') {
          return { ...applicant, ['status']: value };
        }
        if (applicant?.userId === column?.userId && column?.status === 'Archived') {
          return { ...applicant, ['status']: value };
        }
        if (applicant?.userId === column?.userId && column?.status === 'Saved') {
          return { ...applicant, ['status']: value };
        }
        return { ...applicant };
      }),
    );
    updateStatus({
      courseId: column?.courseId || dropDownMenuItemsArray[0]?.id || 0,
      status: value,
      userId: column.userId,
    });
  };

  const ActionMenu = ({ column, index, toggleCollepsibleOnActionClick }) => {
    return (
      <DoctActionMenu
        btnType="inverse"
        options={[
          {
            title: 'View Applicant Detail',
          },
        ]}
        onClick={(item) => {
          if (item.title == 'View Applicant Detail') {
            setSelectedItemId(column?.id);
            toggleCollepsibleOnActionClick(index);
          }
        }}
        className="custom-tabel-row-action-menu"
      />
    );
  };

  const handleRowClickCallback = (expanded, column) => {
    if (expanded && column?.status === 'UnRead') {
      handleButtonStatusChange('Read', column);
      // updateStatus({
      //   courseId: id || dropDownMenuItemsArray[0]?.id,
      //   status: 'Read',
      //   userId: column.userId,
      // });
    }
  };
  const allAreBlur = allApplicantData?.courseApplicant.every((applicant) => applicant.isBlur);

  return (
    <>
      {allCourseData?.totalRecords === 0 ? (
        <>
          <OnNoCourseAdded />
        </>
      ) : (
        <div className="institute-applications">
          <CustomTable
            isBlur={isBlur}
            column={courseApplicantTableColumn}
            tableRowData={
              (selectAllApplicantsData?.courseApplicant?.length &&
                selectAllApplicantsData?.courseApplicant) ||
              applicantTableRowData ||
              {}
            }
            tableCollapsible={CourseApplicantCollapsible}
            dropdownMenuItems={dropDownMenuItemsArray}
            dropdownMenuDefaultSelected={dropdownMenuDefaultSelectedItem?.value || {}}
            searchPlaceholder={'Applicant name, Location'}
            resultCount={allApllicants?.totalRecords || applicantsCounts}
            // resultCount={isApplicantsLoading ? 0 : allApplicantData?.totalRecords}
            actionMenu={ActionMenu}
            contentLoading={isApplicantsLoading || isAllCourseLoading}
            searchOptions={[]}
            onSearchChangeHandler={() => {}}
            onSearchInputChangeHandler={() => {}}
            setSelectedItemId={setSelectedItemId}
            countHeading="APPLICANTS"
            onNoDataFound={<NoApplicantStage />}
            showFilterIcon={false}
            filterData={<CourseApplicantFilter defaultValue={query} />}
            showExportButton={
              allApplicantData?.courseApplicant?.length > 0 &&
              !allApplicantData?.courseApplicant[0]?.isFreePlan
                ? true
                : false
            }
            onExportButtonClickHandler={downloadCourseApplicantDetails}
            excelClicked={excelClicked}
            excelBlur={allAreBlur ? true : false}
            tabOptionsArray={tabStatusOption}
            handleTabChange={handleTabChange}
            currentTabValue={tabChangeValue}
            setIsOpen={setIsOpen}
            activeRowIndex={activeRowIndex}
            setActiveRowIndex={setActiveRowIndex}
            handleRowClickCallback={handleRowClickCallback}
            dropdownMenuItemInStatusWithButton={menuItems}
            dropdownStatusButtonClassesHandler={returnStatusClassName}
            handleButtonStatusChange={handleButtonStatusChange}
            setStatus={setStatus}
          />
        </div>
      )}
    </>
  );
};

export default InstituteCourseApplicants;
